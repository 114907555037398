import React, { useMemo } from 'react';

import dayjs from 'dayjs';

import Assets from 'assets';
import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import CRTab from 'components/base/CRTab';
import { Toast } from 'components/base/CRToast';
import OwnExpenseUploadHistoryDetailDialog from 'components/domain/dialog/OwnExpenseUploadHistoryDetailDialog';
import OwnExpenseUploadHistoryDialog from 'components/domain/dialog/OwnExpenseUploadHistoryDialog';
import { automationUploadAdapter } from 'lib/adapter/own-expense';
import {
	useAutomationUploadList,
	useMyAccountInfo,
	useUploadMonthlyBurdenCharges,
} from 'lib/hook/react-query';
import useDateFilter from 'lib/hook/util/useDateFilter';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { endpoint } from 'lib/service/Api/endpoint';
import { ResponseCode } from 'types/api';
import { OwnExpenseUploadHistory } from 'types/view/ownExpense';

import OnwExpenseFileUploadButton from './OwnExpenseFileUploadButton';
import OwnExpenseMonthlyTab from './OwnExpenseMonthlyTab';
import * as S from './styles';

function OwnExpenseMonthlyPage(): React.ReactElement {
	const hasUploadOwnExpenseFunc = useHasFunc(['own_expense:upload_own_expense']);
	const [monthDate, setMonthDate] = useDateFilter(dayjs().subtract(1, 'month').toDate());
	const ownExpenseTab = useMemo(() => RouterPath.ownExpenseTab(), []);
	const { showDialog } = useGlobalLayout();

	const { data: myAccountInfo } = useMyAccountInfo();

	const uploadMonthlyBurdenCharges = useUploadMonthlyBurdenCharges((client) => {
		client.invalidateQueries([
			endpoint.getAutomationUploadList.key,
			{
				centerId: myAccountInfo?.centerId,
				uploadTypeCds: [
					'CMN107.BURDEN_VISITCARE',
					'CMN107.BURDEN_VISITBATH',
					'CMN107.BURDEN_VISITNURSING',
				],
			},
		]);
		client.invalidateQueries([
			endpoint.getMonthlyBurdenChargesSummary.key,
			{
				burdenAmtChargeYm: dayjs(monthDate).format('YYYYMM'),
				centerId: myAccountInfo?.centerId,
			},
		]);
		client.invalidateQueries([
			endpoint.getMonthlyBurdenCharges.key,
			{
				burdenAmtChargeYm: dayjs(monthDate).format('YYYYMM'),
				centerId: myAccountInfo?.centerId,
			},
		]);
	});

	const { data: uploadHistories = [] } = useAutomationUploadList(
		{
			centerId: myAccountInfo?.centerId,
			uploadTypeCds: [
				'CMN107.BURDEN_VISITCARE',
				'CMN107.BURDEN_VISITBATH',
				'CMN107.BURDEN_VISITNURSING',
			],
		},
		automationUploadAdapter,
	);

	const handleClickUploadHistoryDetail = (item: OwnExpenseUploadHistory) => {
		showDialog(({ hideDialog }) => (
			<OwnExpenseUploadHistoryDetailDialog item={item} onClickClose={hideDialog} />
		));
	};

	const handleClickUploadHistory = () => {
		showDialog(({ hideDialog }) => (
			<OwnExpenseUploadHistoryDialog
				items={uploadHistories}
				onClickClose={hideDialog}
				onClickItem={handleClickUploadHistoryDetail}
			/>
		));
	};

	const handleChangeFile = async (file: File) => {
		try {
			if (!file) return;
			const result = await uploadMonthlyBurdenCharges.mutateAsync({
				file,
				centerId: myAccountInfo?.centerId,
				burdenAmtChargeYm: dayjs(monthDate).format('YYYYMM'),
			});

			if (result?.code === ResponseCode.SUCCESS) {
				Toast.success('파일 업로드를 완료하였습니다.');
			} else {
				Toast.error('파일 업로드를 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		} catch (error) {
			Toast.error('파일 업로드를 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
	};

	const onChangeMonthDate = (date: Date) => setMonthDate(date);

	return (
		<CRTab.Default
			defaultActiveKey={ownExpenseTab.ownExpenseMonthly.key}
			renderRightButton={
				<S.ButtonContainer>
					<OnwExpenseFileUploadButton
						onChange={handleChangeFile}
						disabled={!hasUploadOwnExpenseFunc}
					/>
					<CRButton.IconButton
						disabled={!hasUploadOwnExpenseFunc}
						iconLeft={Assets.icon.accessTimeBlack}
						palette='gray'
						type='outlined'
						onClick={handleClickUploadHistory}
					/>
				</S.ButtonContainer>
			}
			items={[
				{
					label: ownExpenseTab.ownExpenseMonthly.label,
					key: ownExpenseTab.ownExpenseMonthly.key,
					children: (
						<OwnExpenseMonthlyTab monthDate={monthDate} onChangeMonthDate={onChangeMonthDate} />
					),
				},
			]}
			breadCrumb='월별 본인부담금'
		/>
	);
}

export default OwnExpenseMonthlyPage;
