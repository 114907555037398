import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex: 1;
	padding: 2.4rem;
`;

export const EmptyContainer = styled.div`
	display: flex;
	flex: 1;
	height: 100%;
	padding: 2.4rem;
`;

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 2.4rem;
	gap: 1.6rem;
	background-color: ${(props) => props.theme.colors.white};
	border-radius: 1.6rem;
`;

export const RateContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1.6rem;
`;

export const SkeletonContainer = styled.div`
	width: 30rem;
	height: 20rem;
	background: ${({ theme }) => theme.colors.gray99};
	border-radius: 1.6rem;
	position: relative;
	overflow: hidden;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: -100%;
		width: 100%;
		height: 100%;
		background: linear-gradient(
			90deg,
			transparent,
			${({ theme }) => theme.colors.gray95},
			transparent
		);
		animation: skeleton-loading 1.5s infinite linear;
	}

	@keyframes skeleton-loading {
		0% {
			left: -100%;
		}
		100% {
			left: 100%;
		}
	}
`;

export const SkeletonRateContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1.6rem;
`;

export const ErrorContainer = styled.div<{ width: number }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: ${({ width }) => width}rem;
	min-height: 20.4rem;
	gap: 0.8rem;
	background-color: ${({ theme }) => theme.colors.gray99};
`;
