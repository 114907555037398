import React from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import CRRsdNumber from 'components/base/CRRsdNumber';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CheckOption } from 'components/base/Selections/type';
import HealthCheckupHistoryDialog from 'components/domain/dialog/HealthCheckupHistoryDialog';
import InsuranceHistoryDialog from 'components/domain/dialog/InsuranceHistoryDialog';
import InsuranceInfoColumn from 'components/ui/InsuranceInfoColumn/Index';
import { displayBirthDay, displayPhoneNumber } from 'lib';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import { useMyAccountInfo } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { loadApplication } from 'lib/service/util/loader';
import { EmployeeInsuranceDTO } from 'types/api/insurance';
import { EmployeeBasicInfoFormViewType, WorkStateCd } from 'types/view/employee';

import * as S from './styles';

interface Props {
	item?: EmployeeBasicInfoFormViewType;
	options: {
		workState: CheckOption[];
		duty: CheckOption[];
		day: CheckOption[];
		disabilityLevel: CheckOption[];
		healthCheckKind: CheckOption[];
		docReceiveMethod: CheckOption[];
	};
	onChangeMode: () => void;
}

export default function EmployeeBasicInfoView({
	item,
	options,
	onChangeMode,
}: Props): React.ReactElement {
	const navigate = useNavigate();
	const { queryClient } = loadApplication();
	const { currentEmployee, employeeDetailData, editable } = useEmployeePage();
	const { showDialog } = useDialog();
	const { data: myAccountInfo } = useMyAccountInfo();

	const handleClickRow = (item: EmployeeInsuranceDTO) => () => {
		const popup = window.open(
			`${RouterPath.insurance().tab.key}/${currentEmployee?.employeeId}/history/detail/${
				item.insuranceRequestId
			}`,
		);
		const timer = setInterval(() => {
			if (popup && popup.closed) {
				clearInterval(timer);
				queryClient.invalidateQueries([
					endpoint.getEmployeeInsurance.key,
					{
						centerId: myAccountInfo?.centerId,
						employeeId: currentEmployee?.employeeId,
					},
				]);
			}
		}, 500);
	};

	const handleClickInsuranceHistory = () => {
		showDialog(({ hideDialog }) => (
			<InsuranceHistoryDialog
				onClickRow={handleClickRow}
				currentEmployee={currentEmployee}
				hideDialog={hideDialog}
				onClickAddInsurance={() => {
					hideDialog();
					navigate(
						`${RouterPath.insurance().tab.key}/${currentEmployee?.employeeId}/history/request`,
					);
				}}
			/>
		));
	};

	const handleClickCheckupHistory = () => {
		showDialog(({ hideDialog }) => (
			<HealthCheckupHistoryDialog
				currentEmployee={currentEmployee}
				hideDialog={hideDialog}
				options={options}
			/>
		));
	};

	const renderAddress = () => {
		const address = [
			item?.basAddr ?? '',
			item?.detailAddr ?? '',
			item?.zipCode ? `(${item?.zipCode})` : '',
		].join(' ');
		return address.trim() ? address : '-';
	};

	const renderOffHourCnt = () => {
		const result = [];
		if (item?.visitCareOffHourCnt && item?.visitCareOffHourCnt !== '0') {
			result.push(`방문요양 ${item.visitCareOffHourCnt}분`);
		}
		if (item?.visitBathOffHourCnt && item?.visitBathOffHourCnt !== '0') {
			result.push(`방문목욕 ${item.visitBathOffHourCnt}분`);
		}
		if (item?.visitNursingOffHourCnt && item?.visitNursingOffHourCnt !== '0') {
			result.push(`방문간호 ${item.visitNursingOffHourCnt}분`);
		}
		return result.length > 0 ? result.join(', ') : '-';
	};

	return (
		<S.Container>
			<S.Label>
				기본 정보
				<S.ButtonContainer>
					<CRButton.Default
						disabled={!editable}
						size='xSmall'
						type='outlined'
						palette='gray'
						onClick={onChangeMode}>
						편집
					</CRButton.Default>
				</S.ButtonContainer>
			</S.Label>
			<S.Table $isDisabled={!editable}>
				<S.TableRow>
					<S.TableLabelColumn>이름</S.TableLabelColumn>
					<S.TableValueColumn>{item?.korMemberNm || '-'}</S.TableValueColumn>
					<S.TableLabelColumn
						rowSpan={2}
						style={{
							verticalAlign: 'top',
						}}>
						주소
					</S.TableLabelColumn>
					<S.TableValueColumn
						rowSpan={2}
						style={{
							verticalAlign: 'top',
						}}>
						{renderAddress()}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>주민등록번호</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRRsdNumber disabled={!editable} rdsNumber={item?.rsdnNo} key={item?.rsdnNo} />
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>생년월일</S.TableLabelColumn>
					<S.TableValueColumn>
						{item?.birthDt ? displayBirthDay(item?.birthDt) : '-'}
					</S.TableValueColumn>
					<S.TableLabelColumn>직무</S.TableLabelColumn>
					<S.TableValueColumn
						style={{
							verticalAlign: 'middle',
						}}>
						<CRCheckBoxGroup
							checkType='single'
							type='checkbox'
							gap={0.8}
							value={item?.dutyCds}
							options={options.duty.map((option) => ({
								...option,
								disabled: true,
							}))}
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>입사일</S.TableLabelColumn>
					<S.TableValueColumn>
						{item?.joinDate ? dayjs(item.joinDate).format('YYYY.MM.DD') : '-'}
					</S.TableValueColumn>
					<S.TableLabelColumn>
						월소정
						<br />
						근로시간
					</S.TableLabelColumn>
					<S.TableValueColumn>
						{item?.monthPerWorkHourCnt ? `${item?.monthPerWorkHourCnt}시간` : '-'}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>전화번호</S.TableLabelColumn>
					<S.TableValueColumn>
						{item?.mobilePhoneNo ? displayPhoneNumber(item?.mobilePhoneNo) : '-'}
					</S.TableValueColumn>
					<S.TableLabelColumn>치매교육</S.TableLabelColumn>
					<S.TableValueColumn
						style={{
							verticalAlign: 'middle',
						}}>
						<CRCheckBoxGroup
							checkType='single'
							type='radio'
							gap={0.8}
							value={item?.dementiaEduCompleteYn}
							options={[
								{
									label: '미수료',
									value: false,
									disabled: true,
								},
								{
									label: '수료',
									value: true,
									disabled: true,
								},
							]}
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>투폰/투번호</S.TableLabelColumn>
					<S.TableValueColumn
						style={{
							verticalAlign: 'middle',
						}}>
						<CRCheckBoxGroup
							checkType='single'
							type='radio'
							gap={0.8}
							value={item?.twoPhoneYn}
							options={[
								{
									label: '없음',
									value: false,
									disabled: true,
								},
								{
									label: '있음',
									value: true,
									disabled: true,
								},
							]}
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>장애여부</S.TableLabelColumn>
					<S.TableValueColumn>
						{item?.disabilityGradeCd
							? options.disabilityLevel.find((option) => option.value === item?.disabilityGradeCd)
									?.label
							: '없음'}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>투폰 전화번호</S.TableLabelColumn>
					<S.TableValueColumn>
						{item?.subMobilePhoneNo ? displayPhoneNumber(item?.subMobilePhoneNo) : '-'}
					</S.TableValueColumn>
					<S.TableLabelColumn
						rowSpan={2}
						style={{
							verticalAlign: 'top',
						}}>
						비고
					</S.TableLabelColumn>
					<S.TableValueColumn rowSpan={2}>{item?.employeeSpecialDesc || '-'}</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>태그콜</S.TableLabelColumn>
					<S.TableValueColumn style={{ verticalAlign: 'middle' }}>
						<CRCheckBoxGroup
							checkType='single'
							type='radio'
							gap={0.8}
							value={item?.tagCallRequestYn}
							options={[
								{
									label: '미진행',
									value: false,
									disabled: true,
								},
								{
									label: '진행',
									value: true,
									disabled: true,
								},
							]}
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>태그콜 요일</S.TableLabelColumn>
					<S.TableValueColumn
						style={{
							verticalAlign: 'middle',
						}}>
						<CRCheckBoxGroup
							checkType='multiple'
							type='checkbox'
							gap={0.8}
							value={item?.tagCallDayCds}
							options={options.day.map((option) => ({
								...option,
								disabled: true,
							}))}
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>서류 수신 방법</S.TableLabelColumn>
					<S.TableValueColumn>
						{options.docReceiveMethod?.find((option) => option?.value === item?.docReceiveMethodCd)
							?.label || '-'}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>
						근로자의 날<br />
						수당시간
					</S.TableLabelColumn>
					<S.TableValueColumn>{renderOffHourCnt()}</S.TableValueColumn>
					<S.TableLabelColumn colSpan={2} />
				</S.TableRow>
			</S.Table>
			<S.Table $isDisabled={!editable}>
				<S.TableLabelColumn>건강 검진일</S.TableLabelColumn>
				<S.TableValueColumn>
					{item?.healthCheckDate ? dayjs(item.healthCheckDate).format('YYYY.MM.DD') : '-'}
					<S.RightButtonContainer>
						<CRButton.Default
							disabled={!editable}
							size='xSmall'
							palette='gray'
							type='outlined'
							onClick={handleClickCheckupHistory}>
							변경/이력
						</CRButton.Default>
					</S.RightButtonContainer>
				</S.TableValueColumn>
				<S.TableLabelColumn>4대보험</S.TableLabelColumn>
				<S.InsuranceInfoTd>
					<InsuranceInfoColumn height='4.8rem' {...item} />
				</S.InsuranceInfoTd>
				<S.ButtonTd>
					<CRButton.Default
						disabled={!editable}
						size='xSmall'
						palette='gray'
						type='outlined'
						onClick={handleClickInsuranceHistory}>
						변경/이력
					</CRButton.Default>
				</S.ButtonTd>
			</S.Table>
		</S.Container>
	);
}
