import React, { useCallback } from 'react';

import dayjs from 'dayjs';

import Assets from 'assets';
import Colors from 'common/colors';
import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRStatus from 'components/base/CRStatus';
import CRTable from 'components/base/CRTable';
import { Toast } from 'components/base/CRToast';
import ListDialogEmptyDescription from 'components/ui/ListDialogEmptyDescription';
import { useDeleteFullTimeEmployeeDuty, useFullTimeEmployeeDuties } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { FullTimeEmployeeDTO, FullTimeEmployeeDutyDTO } from 'types/dto/full-time-employee';

import { useHasPermission } from '../../../../lib/hook/util/useHasPermission';
import { endpoint } from '../../../../lib/service/Api/endpoint';
import AddFullTimeEmployeeHistoryOfDutyDialog from '../AddFullTimeEmployeeHistoryOfDutyDialog';
import DeleteDialog from '../DeleteDialog';
import { FULL_TIME_EMPLOYEE_HISTORY_OF_DUTY_LIST_TABLE_HEADER_CONFIG } from './constants';
import * as S from './styles';

interface Props {
	hideDialog: () => void;
	currentFullTimeEmployee?: FullTimeEmployeeDTO;
	isResign?: boolean;
}

export default function FullTimeEmployeeHistoryOfDutyDialog({
	currentFullTimeEmployee,
	hideDialog,
	isResign,
}: Props): React.ReactElement {
	const hasUpdateFullTimeEmployeeDutyFunc = useHasFunc([
		'full_time_employee:update_full_time_employee_career',
	]);
	const { data: fullTimeEmployeeDuties = [], refetch } = useFullTimeEmployeeDuties({
		employeeId: currentFullTimeEmployee?.employeeId,
		memberId: currentFullTimeEmployee?.memberId,
		centerId: currentFullTimeEmployee?.centerId,
	});

	const deleteFullTimeEmployeeDuty = useDeleteFullTimeEmployeeDuty((client, returnData) => {
		if (returnData?.employeeId) {
			client.invalidateQueries([
				endpoint.getFullTimeEmployees.key,
				{
					centerIds: [currentFullTimeEmployee?.centerId],
				},
			]);

			client.invalidateQueries([
				endpoint.getFullTimeEmployeeBaseInfo.key,
				{
					centerId: currentFullTimeEmployee?.centerId,
					employeeId: currentFullTimeEmployee?.employeeId,
				},
			]);
			client.invalidateQueries([
				endpoint.getFullTimeEmployeeDetailInfo.key,
				{
					centerId: currentFullTimeEmployee?.centerId,
					employeeId: currentFullTimeEmployee?.employeeId,
					memberId: currentFullTimeEmployee?.memberId,
				},
			]);
			client.invalidateQueries([
				endpoint.getFullTimeEmployeeWorkStates.key,
				{
					centerId: currentFullTimeEmployee?.centerId,
					employeeId: currentFullTimeEmployee?.employeeId,
					memberId: currentFullTimeEmployee?.memberId,
				},
			]);

			refetch();
			Toast.success('직무 이력이 삭제되었습니다.');
			hideDialog();
		}
	});
	const { showDialog } = useDialog();

	const handleEditDuty = async (item?: FullTimeEmployeeDutyDTO) => {
		showDialog(({ hideDialog }) => (
			<AddFullTimeEmployeeHistoryOfDutyDialog
				hideDialog={hideDialog}
				currentFullTimeEmployee={currentFullTimeEmployee}
				targetCurrentDuty={item}
			/>
		));
	};

	const handleDeleteDuty = async (item?: FullTimeEmployeeDutyDTO) => {
		if (!item) return;
		const previousTarget = fullTimeEmployeeDuties?.find(
			(duty) => duty.ftimeEmployeeDutyHistId !== item.ftimeEmployeeDutyHistId,
		);
		showDialog(({ hideDialog }) => (
			<DeleteDialog
				title='직무 변경 이력을 삭제 하시겠습니까?'
				content={`현재 이력 삭제 시, 이전 이력을 현재 직무로 되돌립니다.\n(${item?.dutyNm} -> ${previousTarget?.dutyNm})`}
				hideDialog={hideDialog}
				cancelOption={{
					text: '취소',
				}}
				successOption={{
					text: '삭제',
					successCallback: async () => {
						if (!item?.ftimeEmployeeDutyHistId) return;

						await deleteFullTimeEmployeeDuty.mutateAsync({
							ftimeEmployeeDutyHistId: item?.ftimeEmployeeDutyHistId,
						});
					},
				}}
			/>
		));
	};

	const handleClickAddDuty = () => {
		showDialog(({ hideDialog }) => (
			<AddFullTimeEmployeeHistoryOfDutyDialog
				hideDialog={hideDialog}
				currentFullTimeEmployee={currentFullTimeEmployee}
			/>
		));
	};

	const renderOption = (value?: number, item?: FullTimeEmployeeDutyDTO & { index: number }) => (
		<S.OptionContainer>
			<CRButton.Default
				disabled={!hasUpdateFullTimeEmployeeDutyFunc}
				size='xSmall'
				type='outlined'
				palette='gray'
				onClick={() => handleEditDuty(item)}>
				수정
			</CRButton.Default>
			<CRButton.Default
				disabled={
					Boolean(item?.index || fullTimeEmployeeDuties?.length === 1) ||
					!hasUpdateFullTimeEmployeeDutyFunc
				}
				size='xSmall'
				type='outlined'
				palette='primary'
				onClick={() => handleDeleteDuty(item)}>
				삭제
			</CRButton.Default>
		</S.OptionContainer>
	);

	const StateButtonComponent = useCallback(
		(state: string) => (
			<CRStatus
				options={[
					{ key: '현재', color: 'green' },
					{ key: '이전 이력', color: 'gray' },
					{ key: '변경 예정', color: 'yellow' },
				]}>
				{state}
			</CRStatus>
		),
		[],
	);

	return (
		<CRDialog
			onClickClose={hideDialog}
			type='L'
			title='직무 변경 이력'
			bottomContainerStyle={{ borderTop: `0.1rem solid ${Colors.gray90}` }}
			body={
				fullTimeEmployeeDuties?.length ? (
					<CRTable.Root>
						<CRTable.Head
							offset={-77}
							heads={FULL_TIME_EMPLOYEE_HISTORY_OF_DUTY_LIST_TABLE_HEADER_CONFIG}
						/>
						<CRTable.Body>
							{fullTimeEmployeeDuties?.map((item, index) => (
								<CRTable.Row
									key={item.ftimeEmployeeDutyHistId}
									item={{ ...item, index }}
									customRender={{
										dutyStateNm: StateButtonComponent,
										dutyStartDate: (_, item) =>
											`${dayjs(item?.dutyStartDate).format('YYYY.MM.DD')}~${dayjs(
												item?.dutyEndDate,
											).format('YYYY.MM.DD')}`,
										createdAt: (value) => dayjs(value).format('YYYY.MM.DD'),
										ftimeEmployeeDutyHistId: renderOption,
									}}
									renderKeys={[
										'dutyStateNm',
										'dutyStartDate',
										'dutyNm',
										'remark',
										'createdAt',
										'ftimeEmployeeDutyHistId',
									]}
								/>
							))}
						</CRTable.Body>
					</CRTable.Root>
				) : (
					<ListDialogEmptyDescription
						title='직무를 추가해 주십시오.'
						description={'아직 등록된 직무가 없습니다.\n직무 변경을 클릭해 직무를 추가해 주십시오.'}
						button={
							!isResign ? (
								<CRButton.IconButton
									disabled={!hasUpdateFullTimeEmployeeDutyFunc}
									palette='gray'
									type='outlined'
									iconLeft={Assets.icon.add}
									onClick={handleClickAddDuty}>
									직무 변경
								</CRButton.IconButton>
							) : undefined
						}
					/>
				)
			}
			footer={
				<S.ButtonContainer>
					<S.LeftButtonContainer>
						{!isResign && (
							<CRButton.IconButton
								disabled={!hasUpdateFullTimeEmployeeDutyFunc}
								palette='gray'
								type='outlined'
								iconLeft={Assets.icon.add}
								onClick={handleClickAddDuty}>
								직무 변경
							</CRButton.IconButton>
						)}
					</S.LeftButtonContainer>
				</S.ButtonContainer>
			}
		/>
	);
}
