import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRIcon from 'components/base/CRIcon';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { CRText } from 'components/base/CRText';
import { IntegratedAssessmentScoreDTO } from 'types/dto';
import { IntegratedAssessmentDesireForms } from 'types/view/recipient';

import InformationTable from '../../../ui/InformationTable';
import * as S from './styles';

interface Props {
	form: UseFormReturn<IntegratedAssessmentDesireForms>;
	recipientIntegratedAssessmentScore?: IntegratedAssessmentScoreDTO | null;
	onClickGenerateOpinion?: () => void;
	isOpinionGenerated?: boolean;
	enabled?: boolean;
}

function IntegratedDesireTotalOpinionForm({
	form,
	recipientIntegratedAssessmentScore,
	onClickGenerateOpinion,
	isOpinionGenerated,
	enabled,
}: Props): React.ReactElement {
	return (
		<FlexContainer gap='2.4rem' align='flex-end'>
			<S.Container>
				<FlexContainer gap='1.2rem'>
					<CRButton.Default
						disabled={!enabled}
						palette='gray'
						type='outlined'
						style={{
							padding: '0 2.4rem 0 1.6rem',
							display: 'flex',
							gap: '0.8rem',
						}}
						onClick={onClickGenerateOpinion}>
						<CRIcon src={Assets.icon.autoAwesome} />
						샘플 불러오기
					</CRButton.Default>
					<S.GenerateDescriptionContainer>
						<img src={Assets.icon.tooltipArrow} alt='arrow' />
						{!isOpinionGenerated ? (
							<>
								<CRText typography='label' text='위에서 입력한 내용 바탕으로 ' />
								<CRText typography='label' color='primary60' text='종합 의견 샘플을 생성' />
								<CRText typography='label' text='합니다.' />
							</>
						) : (
							<>
								<CRText typography='label' text='반드시 ' />
								<CRText typography='label' color='primary60' text='샘플 검수 후 저장' />
								<CRText typography='label' text='해야 합니다.' />
							</>
						)}
					</S.GenerateDescriptionContainer>
				</FlexContainer>

				<Controller
					render={({ field: { ref, onChange, value }, formState: { errors } }) => (
						<CRInput.TextArea
							disabled={!enabled}
							placeholder='종합 의견 입력'
							value={value}
							onChange={onChange}
						/>
					)}
					name='memo'
					control={form.control}
				/>
			</S.Container>
			<S.TableContainer>
				<InformationTable
					items={[
						[
							{
								type: 'label',
								label: '낙상위험도',
								labelStyle: {
									textAlign: 'center',
								},
							},
							{
								type: 'label',
								label: '욕창위험도',
								labelStyle: {
									textAlign: 'center',
								},
							},
							{
								type: 'label',
								label: '인지기능',
								labelStyle: {
									textAlign: 'center',
								},
							},
						],
						[
							{
								type: 'value',
								value:
									recipientIntegratedAssessmentScore?.fallScore !== undefined
										? `${recipientIntegratedAssessmentScore?.fallScore ?? 0}점`
										: '-',
								valueStyle: {
									textAlign: 'center',
								},
							},
							{
								type: 'value',
								value:
									recipientIntegratedAssessmentScore?.bedsoreScore !== undefined
										? `${recipientIntegratedAssessmentScore?.bedsoreScore ?? 0}점`
										: '-',
								valueStyle: {
									textAlign: 'center',
								},
							},
							{
								type: 'value',
								value:
									recipientIntegratedAssessmentScore?.kmmseScore !== undefined
										? `${recipientIntegratedAssessmentScore?.kmmseScore ?? 0}점`
										: '-',
								valueStyle: {
									textAlign: 'center',
								},
							},
						],
					]}
				/>
			</S.TableContainer>
		</FlexContainer>
	);
}

export default IntegratedDesireTotalOpinionForm;
