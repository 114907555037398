import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { v4 } from 'uuid';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import CenterAccountRecordDetailForm, {
	CenterAccountRecordFormData,
} from 'components/domain/form/CenterAccountRecordDetailForm';
import InformationTable from 'components/ui/InformationTable';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import { displayComma, displayCommaToNumber, removeFalsyValues } from 'lib';
import { useUpdateCenterAccountRecordDetail } from 'lib/hook/react-query/mutation/centerAccount';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { ResponseCode } from 'types/api';
import { CenterAccountRecordData, CenterAccountRecordDetail } from 'types/api/centerAccount';
import { PageResponse } from 'types/api/common';

import * as S from './styles';

interface Props {
	accountDetail: CenterAccountRecordData;
	onClose: () => void;
}

const defaultRecordDetail = {
	centerAccountRecordDetailId: undefined,
	centerAccountRecordId: undefined,
	centerId: undefined,
	recordTargetDivCd: undefined,
	recordTargetId: undefined,
	recordTargetNm: undefined,
	recordTypeValue: undefined,
	recordTypeCd: undefined,
	recordTypeNm: undefined,
	recordDetailCd: undefined,
	recordDetailNm: undefined,
	recordDesc: undefined,
	depositAmt: undefined,
	withdrawAmt: undefined,
	centerAccountAliasNm: undefined,
	recordDt: undefined,
	recordTime: undefined,
	recordKindNm: undefined,
	balanceAmt: undefined,
	record: undefined,
	rowType: undefined,
	recordDate: undefined,
};

const getDefaultCenterAccountRecordDetails = (accountDetail: CenterAccountRecordData) =>
	accountDetail.centerAccountRecordDetails?.length
		? accountDetail.centerAccountRecordDetails.map((val) => ({
				...val,
				...(val?.recordTypeNm && val?.recordTypeCd
					? {
							recordTypeValue: {
								label: val.recordTypeNm,
								value: val.recordTypeCd,
							},
						}
					: {}),
				...(val?.recordDetailNm && val?.recordDetailCd
					? {
							recordDetailValue: {
								label: val.recordDetailNm,
								value: val.recordDetailCd,
							},
						}
					: {}),
				...(val?.recordTargetNm && val?.recordTargetId
					? {
							recordTargetDivValue: {
								label: val.recordTargetNm,
								name: val.recordTargetNm,
								value: { id: val.recordTargetId },
							},
						}
					: {}),
				depositAmt: displayComma(val?.depositAmt),
				withdrawAmt: displayComma(val.withdrawAmt),
			}))
		: [
				{
					...defaultRecordDetail,
					tempId: v4(),
					centerAccountRecordId: accountDetail.centerAccountRecordId,
					centerId: accountDetail.centerId,
				},
			];

function CenterAccountDetailDialog({ accountDetail, onClose }: Props) {
	console.log(accountDetail);
	const hasUpdateBankAccountDetailFunc = useHasFunc(['center:update_bank_account_detail']);
	const formMethods = useForm<CenterAccountRecordFormData>({
		defaultValues: {
			...accountDetail,
			centerAccountRecordDetails: getDefaultCenterAccountRecordDetails(accountDetail),
		},
	});

	const { getValues, handleSubmit } = formMethods;
	const updateCenterRecodeDetail = useUpdateCenterAccountRecordDetail();
	const client = useQueryClient();

	const centerAccountRecordDetails = formMethods.watch('centerAccountRecordDetails');
	const currentDetail = centerAccountRecordDetails[0];

	const isDeposit = accountDetail.recordKindNm === '입금';

	const detailTotalAmt = displayComma(
		isDeposit ? getValues('depositAmt') : getValues('withdrawAmt'),
	);

	const currentAmt = isDeposit
		? `+${currentDetail?.depositAmt || 0}`
		: `-${currentDetail?.withdrawAmt || 0}`;

	const detailInfoItem: InformationTableItemType[][] = [
		[
			{
				labelStyle: { width: '43rem' },
				label: '금액',
				value: (
					<FlexContainer gap='0.2rem'>
						<CRText typography='label' text={currentAmt} />
						<CRText typography='label' color='gray60' text={`(총 금액 ${detailTotalAmt})`} />
					</FlexContainer>
				),
			},
		],
		[
			{
				label: '입금처/출금처',
				value: getValues('record'),
			},
		],
		[
			{
				label: '계좌명',
				value: getValues('centerAccountAliasNm'),
			},
		],
		[
			{
				label: '이체 일시',
				value: dayjs(getValues('recordDate')).format('YYYY.MM.DD HH:mm'),
			},
		],
	];

	const isRecordDataValid = (formData: CenterAccountRecordFormData) =>
		formData.centerAccountRecordDetails.every((detail, idx) => {
			const renderAbleRecordType = ['CMN035.1000', 'CMN035.2010', 'CMN035.2150', 'CMN035.2160'];
			const isOnlyNeedRecordType = !renderAbleRecordType.includes(detail.recordTypeValue?.value);

			if (isOnlyNeedRecordType) return true;
			return !!(detail?.recordDetailValue?.value && detail?.recordTargetDivValue?.value);
		});

	const isAmtValid = (formData: CenterAccountRecordFormData) =>
		formData.centerAccountRecordDetails.every(
			(detail, idx) =>
				Number(detail?.depositAmt?.replace(/,/g, '') || 0) +
					Number(detail?.withdrawAmt?.replace(/,/g, '') || 0) >
				0,
		);

	const onClickUpdateRecordDetail = async (formData: CenterAccountRecordFormData) => {
		if (updateCenterRecodeDetail.isLoading) return;
		if (!isRecordDataValid(formData)) {
			Toast.error('유형의 내용을 모두 입력해주세요.');
			return;
		}
		if (!isAmtValid(formData)) {
			Toast.error('상세 금액을 확인해주세요.');
			return;
		}
		const targetYmRecordType = ['CMN035.1000', 'CMN035.2010'];

		const formattedData = formData.centerAccountRecordDetails.map((detail) =>
			removeFalsyValues({
				...detail,
				centerAccountAliasNm: formData.centerAccountAliasNm,
				centerAccountRecordDetails: undefined,
				verifyStateValue: undefined,
				tempId: undefined,
				recordTypeValue: undefined,
				recordTypeNm: detail?.recordTypeValue?.label,
				recordTypeCd: detail?.recordTypeValue?.value,
				recordDetailValue: undefined,
				recordDetailNm: detail?.recordDetailValue?.label,
				recordDetailCd: detail?.recordDetailValue?.value,
				recordTargetDivValue: undefined,
				recordTargetNm: detail?.recordTargetDivValue?.label,
				recordTargetId: detail?.recordTargetDivValue?.value.id,
				recordTargetDivCd: detail?.recordTargetDivValue?.value?.cd,
				depositAmt: Number(detail?.depositAmt?.replace(/,/g, '') || 0),
				withdrawAmt: Number(detail?.withdrawAmt?.replace(/,/g, '') || 0),
				recordTargetYm:
					targetYmRecordType.includes(detail.recordTypeCd || '') && detail.recordTargetYm
						? dayjs(detail.recordTargetYm).format('YYYYMM')
						: '',
			}),
		);
		const res = await updateCenterRecodeDetail.mutateAsync(
			formattedData as CenterAccountRecordDetail[],
		);
		if (res?.code !== ResponseCode.SUCCESS) {
			Toast.success('저장을 실패했습니다.');
			return;
		}
		Toast.success('정상적으로 내용이 저장 되었습니다.');
		client.setQueriesData(
			['getCenterAccountRecords'],
			(data?: PageResponse<CenterAccountRecordData[]>) => {
				if (!data?.content?.map) return data;
				const test = {
					...data,
					content: data?.content?.map((content) => {
						if (content.centerAccountRecordId !== res.data?.centerAccountRecordId) return content;
						return res.data;
					}),
				};
				return test;
			},
		);
		onClose();
	};

	const totalAmt = centerAccountRecordDetails.reduce(
		(acc, cur) =>
			acc + (displayCommaToNumber(cur.depositAmt) + displayCommaToNumber(cur.withdrawAmt)),
		0,
	);

	const isSubmittable = useMemo(() => {
		const renderAbleRecordType = ['CMN035.1000', 'CMN035.2010', 'CMN035.2150', 'CMN035.2160'];
		const renderDateRecordType = ['CMN035.1000', 'CMN035.2010'];

		const hasDivValue = getValues('centerAccountRecordDetails').every((item) => {
			if (!renderAbleRecordType.includes(item.recordTypeValue?.value)) return true;
			const validRecordTargetYm = renderDateRecordType.includes(item.recordTypeValue?.value)
				? item.recordTargetYm
				: true;

			return item.recordDetailValue?.value && validRecordTargetYm;
		});

		return hasDivValue && hasUpdateBankAccountDetailFunc;
	}, [
		totalAmt,
		isDeposit,
		getValues('centerAccountRecordDetails'),
		getValues('depositAmt'),
		getValues('withdrawAmt'),
		hasUpdateBankAccountDetailFunc,
	]);

	return (
		<CRDialog
			title='계좌 내역 상세'
			onClickClose={onClose}
			height='80rem'
			body={
				<FormProvider {...formMethods}>
					<S.ContentContainer>
						<S.ScrollContainer>
							<FlexContainer direction='column' margin='0 0 1.6rem 0' gap='0.8rem'>
								<CRText text='거래정보' />
								<InformationTable items={detailInfoItem} />
							</FlexContainer>
							<CenterAccountRecordDetailForm disabledInputs={['금액']} />
						</S.ScrollContainer>
					</S.ContentContainer>
					<S.TotalPriceContainer>
						<CRText text='총 금액' />
						<FlexContainer gap='0.4rem'>
							<CRText typography='bodyB' text={displayComma(totalAmt)} />
							<CRText text='원' color='gray60' />
						</FlexContainer>
					</S.TotalPriceContainer>
				</FormProvider>
			}
			footer={
				<FlexContainer gap='0.8rem'>
					<CRButton.Default type='text' palette='gray' onClick={onClose}>
						취소
					</CRButton.Default>
					<CRButton.Default
						disabled={!isSubmittable}
						onClick={handleSubmit(onClickUpdateRecordDetail)}>
						저장
					</CRButton.Default>
				</FlexContainer>
			}
		/>
	);
}

export default CenterAccountDetailDialog;
