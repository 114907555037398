import React, { useMemo } from 'react';

import Assets from 'assets';
import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import CRTab from 'components/base/CRTab';
import InsuranceExcelDownloadDialog from 'components/domain/dialog/InsuranceExcelDownloadDialog';
import useDialog from 'lib/hook/util/useDialog';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { useHasPermission } from 'lib/hook/util/useHasPermission';

import InsuranceTab from './InsuranceTab';

export default function InsurancePage() {
	const hasDownloadSocialInsuranceFunc = useHasFunc(['employee:download_social_insurance']);
	const insuranceTab = useMemo(() => RouterPath.insurance().tab, []);
	const { showDialog } = useDialog();

	const handleClickDownloadExcel = () => {
		showDialog(() => <InsuranceExcelDownloadDialog />);
	};

	return (
		<CRTab.Default
			defaultActiveKey={insuranceTab.key}
			breadCrumb='4대보험'
			items={[
				{
					label: insuranceTab.label,
					key: insuranceTab.key,
					children: <InsuranceTab hasPermission={hasDownloadSocialInsuranceFunc} />,
				},
			]}
			renderRightButton={
				hasDownloadSocialInsuranceFunc ? (
					<CRButton.IconButton
						disabled={!hasDownloadSocialInsuranceFunc}
						palette='gray'
						type='outlined'
						iconLeft={Assets.icon.fileDownload}
						onClick={handleClickDownloadExcel}>
						엑셀 다운로드
					</CRButton.IconButton>
				) : undefined
			}
		/>
	);
}
