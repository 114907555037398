import React, { Fragment, useCallback } from 'react';

import dayjs from 'dayjs';
import { v4 } from 'uuid';

import Colors from 'common/colors';
import CRButton from 'components/base/CRButton';
import CRStatus from 'components/base/CRStatus';
import CRTable from 'components/base/CRTable';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import { useSyncMonitoringIndividual } from 'lib/hook/react-query/mutation/monitoring';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import { MonitoringDTO, ResponseCode, SyncMonitoringIndividualRequest } from 'types/api';

import { MONITORING_COMPLETE_STATUS_CONFIG } from '../MonitoringDetailTable/constant';
import { ServiceTypeCd } from '../RecordingSheetTable/serviceEdocParam';
import { COMPLETE_STATUS_CONFIG, MONITORING_HEADER_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	items?: MonitoringDTO[];
	onClickRow?: (task: MonitoringDTO) => void;
}

function MonitoringTable({ items = [], onClickRow }: Props): React.ReactElement {
	const hasCreateMonitoringListFunc = useHasFunc(['monitoring:create_monitoring_list']);
	const { mutate: syncMonitoringIndividual, isLoading: syncLoading } = useSyncMonitoringIndividual(
		(client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				Toast.success(
					'데이터 갱신 요청을 하였습니다. 잠시 뒤 자동화 업데이트 후 반영될 예정입니다.',
				);
			} else {
				Toast.error(
					returnData?.message ||
						'데이터 갱신 요청에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.',
				);
			}
		},
	);
	const renderCompleteStatus = useCallback((completeYn?: boolean) => {
		if (completeYn === undefined) return '';
		return <CRStatus options={COMPLETE_STATUS_CONFIG}>{completeYn ? '완료' : '미완료'}</CRStatus>;
	}, []);

	const renderMonitoringCompleteStatus = useCallback((scheduleMonitoringCompleteYn?: boolean) => {
		if (scheduleMonitoringCompleteYn === undefined) return '';
		return (
			<CRStatus options={MONITORING_COMPLETE_STATUS_CONFIG}>
				{scheduleMonitoringCompleteYn === undefined
					? '-'
					: scheduleMonitoringCompleteYn
						? '완료'
						: '대기'}
			</CRStatus>
		);
	}, []);

	const renderWorkYn = useCallback(
		(value?: boolean) => (
			<CRText
				text={value === undefined ? '-' : value ? '확인 완료' : '미확인'}
				typography='label'
				color='gray00'
			/>
		),
		[],
	);

	const renderOption = (id: number, item?: MonitoringDTO) => (
		<S.ButtonContainer>
			<CRButton.Default
				disabled={!hasCreateMonitoringListFunc}
				size='xSmall'
				type='outlined'
				palette='gray'
				onClick={(e) => {
					e?.stopPropagation();
					if (
						syncLoading ||
						!item?.centerId ||
						!item.serviceYm ||
						!item.recipientId ||
						!item.employeeId1 ||
						!item.serviceTypeCd
					)
						return;
					const params: SyncMonitoringIndividualRequest = {
						centerId: item?.centerId,
						serviceYm: item.serviceYm,
						recipientId: item.recipientId,
						employeeId1: item.employeeId1,
						serviceTypeCd: item.serviceTypeCd,
					};
					if (item.employeeId2) params.employeeId2 = item.employeeId2;
					syncMonitoringIndividual(params);
				}}>
				데이터 갱신
			</CRButton.Default>
		</S.ButtonContainer>
	);

	const renderSyncCompleteDate = useCallback(
		(value: string) => (
			<S.TextContainer>
				<CRText
					text={value ? dayjs(value).format('YYYY.MM.DD HH:mm') : '-'}
					typography='label'
					color='gray60'
				/>
			</S.TextContainer>
		),
		[],
	);

	const renderEmployeeNm = useCallback(
		(value: string, item?: MonitoringDTO) => (
			<S.TextContainer>
				<S.EmployeeNmContainer>{item?.employeeNm1}</S.EmployeeNmContainer>
				{item?.serviceTypeCd === ServiceTypeCd.방문목욕 && (
					<S.EmployeeNmContainer
						style={{
							borderTop: '0.1rem solid rgb(225, 227, 227)',
						}}>
						{item?.employeeNm2 || '확인필요'}
					</S.EmployeeNmContainer>
				)}
			</S.TextContainer>
		),
		[],
	);

	const renderDataCreation = useCallback(
		(value: string, item?: MonitoringDTO) => (
			<S.TextContainer>
				<S.EmployeeNmContainer>
					<CRText text={item?.employeeDataCreation1 ?? '-'} color='gray00' typography='label' />
				</S.EmployeeNmContainer>
				{item?.serviceTypeCd === ServiceTypeCd.방문목욕 && (
					<S.EmployeeNmContainer
						style={{
							borderTop: '0.1rem solid rgb(225, 227, 227)',
						}}>
						<CRText text={item?.employeeDataCreation2 ?? '-'} color='gray00' typography='label' />
					</S.EmployeeNmContainer>
				)}
			</S.TextContainer>
		),
		[],
	);

	return (
		<S.Container>
			<CRTable.Root
				style={{
					borderLeft: `0.1rem solid ${Colors.gray90}`,
					borderRight: `0.1rem solid ${Colors.gray90}`,
				}}>
				<CRTable.Head offset={1} heads={MONITORING_HEADER_CONFIG} />
				<CRTable.Body>
					{items.map((item, index) => (
						// eslint-disable-next-line
						<Fragment key={v4()}>
							<CRTable.Row
								style={
									item.state === false
										? {
												background: Colors.primary95,
											}
										: {}
								}
								item={{
									...item,
									index: index + 1,
								}}
								renderKeys={[
									'index',
									'state',
									'scheduleMonitoringCompleteYn',
									'recipientNm',
									'longTermNo',
									'serviceKindNm',
									'employeeNm1',
									'socialWorkerNm',
									'workConfirmYn',
									'workDuplConfirmYn',
									'employeeDataCreation1',
									'syncCompleteDate',
									'recipientId',
								]}
								customRender={{
									state: renderCompleteStatus,
									syncCompleteDate: renderSyncCompleteDate,
									employeeNm1: renderEmployeeNm,
									recipientId: renderOption,
									workConfirmYn: renderWorkYn,
									employeeDataCreation1: renderDataCreation,
									workDuplConfirmYn: renderWorkYn,
									scheduleMonitoringCompleteYn: renderMonitoringCompleteStatus,
								}}
								customStyle={{
									index: {
										verticalAlign: item.employeeNm2 ? 'top' : 'middle',
										textAlign: 'right',
										padding: '0.4rem 0.8rem',
									},
									state: {
										verticalAlign: item.employeeNm2 ? 'top' : 'middle',
										padding: '0.4rem 0.8rem',
									},
									scheduleMonitoringCompleteYn: {
										verticalAlign: item.employeeNm2 ? 'top' : 'middle',
										padding: '0.4rem 0.8rem',
									},
									confirmYn: {
										verticalAlign: item.employeeNm2 ? 'top' : 'middle',
										padding: '0.4rem 0.8rem',
									},
									recipientNm: {
										verticalAlign: item.employeeNm2 ? 'top' : 'middle',
										padding: '0.4rem 0.8rem',
									},
									recipientBirthDt: {
										verticalAlign: item.employeeNm2 ? 'top' : 'middle',
										padding: '0.4rem 0.8rem',
									},
									serviceKindNm: {
										verticalAlign: item.employeeNm2 ? 'top' : 'middle',
										padding: '0.4rem 0.8rem',
									},
									socialWorkerNm: {
										verticalAlign: item.employeeNm2 ? 'top' : 'middle',
										padding: '0.4rem 0.8rem',
									},
									workConfirmYn: {
										verticalAlign: item.employeeNm2 ? 'top' : 'middle',
										padding: '0.4rem 0.8rem',
									},
									workDuplConfirmYn: {
										verticalAlign: item.employeeNm2 ? 'top' : 'middle',
										padding: '0.4rem 0.8rem',
									},
									syncCompleteDate: {
										verticalAlign: item.employeeNm2 ? 'top' : 'middle',
										padding: '0.4rem 0.8rem',
									},
									employeeNm1: {
										verticalAlign: item.employeeNm2 ? 'top' : 'middle',
										padding: 0,
									},
									employeeDataCreation1: {
										verticalAlign: item.employeeNm2 ? 'top' : 'middle',
										padding: 0,
									},
									recipientId: {
										verticalAlign: item.employeeNm2 ? 'top' : 'middle',
										padding: '0.4rem 0.8rem',
									},
									longTermNo: {
										verticalAlign: item.employeeNm2 ? 'top' : 'middle',
										padding: '0.4rem 0.8rem',
									},
								}}
								onClick={onClickRow}
							/>
						</Fragment>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default MonitoringTable;
