import useCRQuery from 'lib/hook/react-query/base/useCRQuery';
import { endpoint } from 'lib/service/Api/endpoint';

import useCRMutation from '../base/useCRMutation';

// Query
// 직원 -> 서류 관리 탭 리스트
export const useEmployeeEDocList = useCRQuery(
	endpoint.getEmployeeEDocList.key,
	'getEmployeeEDocList',
);

// 수급자 -> 서류 관리 탭 리스트
export const useRecipientEDocList = useCRQuery(
	endpoint.getRecipientEDocList.key,
	'getRecipientEDocList',
);

// 발급가능 서류종류
export const useEDocPaperTypes = useCRQuery(endpoint.getEDocPaperTypes.key, 'getEDocPaperTypes');

// 발급 서류 종류
export const useIssuableEDocPaperTypes = useCRQuery(
	endpoint.getIssuableEDocPaperTypes.key,
	'getIssuableEDocPaperTypes',
);

// 등록 서류 종류
export const useRegisterEDocPaperTypes = useCRQuery(
	endpoint.getRegisterEDocPaperTypes.key,
	'getRegisterEDocPaperTypes',
);

// 서류 발급 페이지 접근시 필요 데이터 호출
export const useEDocIssueInfoBefore = useCRQuery(
	endpoint.getEDocIssueInfoBefore.key,
	'getEDocIssueInfoBefore',
);

// 발급된 전자문서 미리보기시 필요한 데이터
export const useEDocPreviewInfo = useCRQuery(endpoint.getEDocPreviewInfo.key, 'getEDocPreviewInfo');

// 입소이용신청서 조회(검수용)
export const useRecipientAdmissionUseEDoc = useCRQuery(
	endpoint.getRecipientEdocAdmissionUse.key,
	'getRecipientAdmissionUseEDoc',
	{
		cacheTime: 0,
	},
);

// Mutation
// 서류 발급
export const useIssueEDocument = useCRMutation(endpoint.issueEDocument.key, 'issueEDocument');

// 서류 등록
export const useCreateEDocPaper = useCRMutation(endpoint.createEDocPaper.key, 'createEDocPaper');

// 방문간호지시서 등록
export const useCreateHomeCarePaper = useCRMutation(
	endpoint.createHomeCarePaper.key,
	'createHomeCarePaper',
);

// 문서 검수

export const useConfirmEDoc = useCRMutation(endpoint.confirmEDoc.key, 'confirmEDoc');

// 문서 오픈
export const useOpenEDoc = useCRMutation(endpoint.openEDoc.key, 'openEDoc');
