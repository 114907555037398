import React, { useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import * as Accordion from '@radix-ui/react-accordion';
import dayjs from 'dayjs';

import { IButtonProps } from 'components/base/CRButton/CRDefaultButton';
import CRPageFooter from 'components/base/CRPageFooter';
import CRPageHeader from 'components/base/CRPageHeader';
import { Toast } from 'components/base/CRToast';
import { salaryFeeCdKor } from 'components/domain/dialog/SalaryDetailDialog/types';
import MyCenterFinanceDurationForm from 'components/domain/form/MyCenterFinanceDurationForm';
import MyCenterFinanceEtcFeeForm from 'components/domain/form/MyCenterFinanceEtcFeeForm';
import MyCenterFinanceVisitBathFeeForm from 'components/domain/form/MyCenterFinanceVisitBathFeeForm';
import MyCenterFinanceVisitFamilyFeeForm from 'components/domain/form/MyCenterFinanceVisitFamilyFeeForm';
import MyCenterFinanceVisitNormalFeeForm from 'components/domain/form/MyCenterFinanceVisitNormalFeeForm';
import MyCenterFinanceVisitNursingFeeForm from 'components/domain/form/MyCenterFinanceVisitNursingFeeForm';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import { displayComma, queryStringToObject } from 'lib';
import useCRForm from 'lib/hook/form/useCRForm';
import { useMyAccountInfo } from 'lib/hook/react-query';
import {
	useCheckSalaryDuplicatedPeriod,
	useUpdateCenterSalaryFeeInfo,
} from 'lib/hook/react-query/mutation/centerFinance';
import { useCenterSalaryFeeDetail } from 'lib/hook/react-query/query/centerFinance';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import { ResponseCode } from 'types/api';
import {
	CenterSalaryFeeStandardDTO,
	UpdateCenterSalaryFeeInfoRequest,
} from 'types/api/centerFinanceInfo';
import { CenterFinanceDetailFormFields } from 'types/view/centerFinance';

import { errorMessages } from './constants';
import * as S from './styles';

export default function UpdateCenterFinanceInfoPage() {
	const hasUpdateCenterFinanceInformationFunc = useHasFunc(['center:update_financial_information']);
	const location = useLocation();
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const { data: myAccountInfo } = useMyAccountInfo();
	const isCenterChief = useHasPermission('센터장');
	const { start, end } = queryStringToObject<{ start: string; end: string }>(location.search);
	const navigate = useNavigate();

	const { watch, getValues, setValue, CRHandleSubmit, CRFormProvider } =
		useCRForm<CenterFinanceDetailFormFields>();

	const checkValidDate = (date: string) => dayjs(date).isValid() ?? false;

	const { mutate: checkSalaryDuplicatedPeriod, data: isPeriodDuplicate } =
		useCheckSalaryDuplicatedPeriod();

	const { data: centerSalaryFeeDetail, refetch: centerSalaryFeeDetailRefetch } =
		useCenterSalaryFeeDetail({
			centerId: myAccountInfo?.centerId,
			salaryFeeStandardStartDate: checkValidDate(start) ? start : undefined,
			salaryFeeStandardEndDate: checkValidDate(end) ? end : undefined,
		});

	const { mutate: updateCenterSalaryInfo, isLoading: updateCenterSalaryInfoLoading } =
		useUpdateCenterSalaryFeeInfo((client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				Toast.success('정상적으로 재무 정보 수정을 완료하였습니다.');
				setIsEdit(false);
				centerSalaryFeeDetailRefetch();
			} else if (returnData?.code === ResponseCode.ERROR) {
				Toast.error(returnData.message);
			} else {
				Toast.error('재무 정보 수정에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		});

	const validate = () => {
		if (!getValues('salaryFeeStandardStartDate')) {
			Toast.error('적용기간 - 적용 시작 연월을 입력해주세요.');
			return false;
		}

		if (isPeriodDuplicate) {
			Toast.error('이미 등록된 재무 정보의 시작 연월과 중복되지 않도록 설정해 주십시오.');
			return false;
		}

		const hasError = errorMessages.some(([grade, message]) => {
			if (
				!getValues('salaryFee')?.[grade]?.salaryFeeValue ||
				getValues('salaryFee')?.[grade]?.salaryFeeValue === '0'
			) {
				Toast.error(message);
				return true;
			}
			return false;
		});

		if (hasError) {
			return false;
		}

		return true;
	};

	const handleGoBack = () => {
		navigate(-1);
	};

	const applyCenterSalaryFee = (financeSalaryFeeDetailInfo: CenterSalaryFeeStandardDTO[]) => {
		const SalaryFeeObj: any = {};
		financeSalaryFeeDetailInfo?.forEach((item) => {
			const itemKor = Object.entries(salaryFeeCdKor).find(
				([name, value]) => item.salaryFeeCd.salaryFeeCd === value,
			);
			if (itemKor) {
				const convertedItem = {
					...item,
					salaryFeeValue: displayComma(item.salaryFeeValue),
				};
				const korKey = itemKor[0];
				SalaryFeeObj[korKey] = convertedItem;
			}
		});
		setValue('salaryFee', SalaryFeeObj);
	};

	const forms = useMemo(
		() => [
			{
				id: '#1',
				label: '적용 기간',
				component: (
					<MyCenterFinanceDurationForm periodDuplicate={isPeriodDuplicate || false} disabled />
				),
			},
			{
				id: '#2',
				label: '방문요양 (일반) 임금 정보',
				component: <MyCenterFinanceVisitNormalFeeForm disabled={!isEdit} />,
			},
			{
				id: '#3',
				label: '방문요양 (가족) 임금 정보',
				component: <MyCenterFinanceVisitFamilyFeeForm disabled={!isEdit} />,
			},
			{
				id: '#4',
				label: '방문목욕 임금 정보',
				component: <MyCenterFinanceVisitBathFeeForm disabled={!isEdit} />,
			},
			{
				id: '#5',
				label: '방문간호 임금 정보',
				component: <MyCenterFinanceVisitNursingFeeForm disabled={!isEdit} />,
			},
			{
				id: '#6',
				label: '기타 임금 정보',
				component: <MyCenterFinanceEtcFeeForm disabled={!isEdit} />,
			},
		],
		[isPeriodDuplicate, isEdit],
	);

	const onClickSave = async (formData: CenterFinanceDetailFormFields) => {
		if (!validate() || !myAccountInfo?.centerId || updateCenterSalaryInfoLoading) return;
		const { salaryFeeStandardStartDate, salaryFee } = formData;

		const salaryFeeStandardValues = Object.values(salaryFee).map((item) => ({
			salaryFeeCd: item.salaryFeeCd.salaryFeeCd,
			salaryFeeValue: item.salaryFeeValue.replace(/,/g, ''),
			salaryFeeStandardId: item.salaryFeeStandardId,
		}));
		const params: UpdateCenterSalaryFeeInfoRequest = {
			centerId: myAccountInfo.centerId,
			salaryFeeStandardStartDate: dayjs(salaryFeeStandardStartDate).format('YYYY-MM-DD HH:mm:ss'),
			salaryFeeStandardValues,
		};

		updateCenterSalaryInfo(params);
	};

	const handleToggleEdit = () => {
		setIsEdit((prev) => !prev);
	};

	const renderButton = (): IButtonProps[] => {
		if (isCenterChief) {
			if (isEdit) {
				return [
					{
						palette: 'gray',
						buttonType: 'button',
						type: 'text',
						onClick: handleToggleEdit,
						children: '취소',
					},
					{
						palette: 'primary',
						buttonType: 'button',
						type: 'filled',
						onClick: CRHandleSubmit(onClickSave),
						children: '저장',
					},
				];
			}
			return [
				{
					palette: 'gray',
					buttonType: 'button',
					type: 'text',
					onClick: handleGoBack,
					children: '취소',
				},
				{
					palette: 'primary',
					buttonType: 'button',
					type: 'filled',
					onClick: handleToggleEdit,
					children: '수정',
				},
			];
		}
		return [
			{
				palette: 'primary',
				buttonType: 'button',
				type: 'filled',
				onClick: handleGoBack,
				children: '확인',
			},
		];
	};

	useEffect(() => {
		if (start && dayjs(start).isValid()) {
			setValue('salaryFeeStandardStartDate', dayjs(start).toDate());
		}
	}, [start]);

	useEffect(() => {
		if (centerSalaryFeeDetail) {
			applyCenterSalaryFee(centerSalaryFeeDetail);
		}
	}, [centerSalaryFeeDetail]);

	useEffect(() => {
		if (
			isEdit &&
			start !== dayjs(watch('salaryFeeStandardStartDate')).format('YYYY-MM-DD HH:mm:ss')
		) {
			checkSalaryDuplicatedPeriod({
				centerId: myAccountInfo?.centerId,
				salaryFeeStandardStartDate: watch('salaryFeeStandardStartDate')
					? dayjs(watch('salaryFeeStandardStartDate')).format('YYYY-MM-DD HH:mm:ss')
					: undefined,
			});
		}
	}, [start, isEdit, myAccountInfo, watch('salaryFeeStandardStartDate')]);

	if (start && !dayjs(start).isValid()) {
		return <Navigate to='/' state={{ from: location }} />;
	}

	return (
		<S.MainComponentContainer $hasFooter={hasUpdateCenterFinanceInformationFunc}>
			<S.FormContainer>
				<CRPageHeader headerTitle='재무 정보 상세' />
				<CRFormProvider>
					<Accordion.Root type='multiple' defaultValue={forms.map((form) => form.id)}>
						{forms.map((form) => (
							<Accordion.Item value={form.id} asChild>
								<TaskAccordion.Item>
									<Accordion.Header asChild>
										<Accordion.Trigger asChild>
											<TaskAccordion.Trigger>{form.label}</TaskAccordion.Trigger>
										</Accordion.Trigger>
									</Accordion.Header>
									<Accordion.Content asChild>
										<TaskAccordion.Content>{form.component}</TaskAccordion.Content>
									</Accordion.Content>
								</TaskAccordion.Item>
							</Accordion.Item>
						))}
					</Accordion.Root>
				</CRFormProvider>
			</S.FormContainer>
			{hasUpdateCenterFinanceInformationFunc && (
				<S.FooterContainer>
					<S.FooterContentContainer>
						<CRPageFooter rightButtons={renderButton()} />
					</S.FooterContentContainer>
				</S.FooterContainer>
			)}
		</S.MainComponentContainer>
	);
}
