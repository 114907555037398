import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import CRSpinner from 'components/base/CRSpinner';
import { Toast } from 'components/base/CRToast';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import EmployeeDeleteDialog from 'components/domain/dialog/EmployeeDeleteDialog';
import EmployeeBasicInfoForm from 'components/domain/form/EmployeeBasicInfoForm';
import EmployeeBasicStatusFormView from 'components/domain/form/EmployeeBasicStatusForm';
import EmployeePayInfoForm from 'components/domain/form/EmployeePayInfoForm';
import OtherJobForm from 'components/domain/form/OtherJobForm';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import { useCommonCodes, useEmployeeResignInfo, useMyAccountInfo } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { ResponseCode } from 'types/api';
import { WorkStateCd } from 'types/view/employee';

import * as S from './styles';

export default function EmployeeBasicTab(): React.ReactElement {
	const hasDeleteEmployeeFunc = useHasFunc(['employee:delete_employee']);
	const hasWithdrawEmployeeFunc = useHasFunc(['employee:withdraw_employee']);
	const { currentEmployee, employeeDetailData, employeeDetailLoading } = useEmployeePage();
	const navigate = useNavigate();
	const { data: myAccountInfo } = useMyAccountInfo();

	const { showDialog } = useDialog();

	const { data: resignPreInfo, mutateAsync: getResignInfo } = useEmployeeResignInfo();

	const { data: commonCodes } = useCommonCodes({
		comCdGroupNms: ['CMN070', 'CMN020', 'CMN149', 'CMN068', 'CMN133', 'CMN037', 'CMN163', 'CMN180'],
	});

	const otherJobData = employeeDetailData?.otherCorpWorks?.length
		? employeeDetailData?.otherCorpWorks
		: [];

	const options = useMemo(() => {
		const duty =
			commonCodes
				?.filter((e) => e.comCdGroupNm === 'CMN070' && e?.comCdDesc === '1')
				?.map((option) => ({
					label: option.korComCdAliasNm,
					value: option.comCdId,
				})) ?? [];
		const day =
			commonCodes
				?.filter((e) => e.comCdGroupNm === 'CMN020')
				?.map((option) => ({
					label: option.korComCdAliasNm,
					value: option.comCdId,
				})) ?? [];
		const disabilityLevel =
			commonCodes
				?.filter((e) => e.comCdGroupNm === 'CMN149')
				?.map((option) => ({
					label: option.korComCdAliasNm,
					value: option.comCdId,
				})) ?? [];
		const workState =
			commonCodes
				?.filter((e) => e.comCdGroupNm === 'CMN068' && e.comCdDesc === '1')
				?.map((option) => ({
					label: option.korComCdAliasNm,
					value: option.comCdId,
				})) ?? [];
		const payType =
			commonCodes
				?.filter((e) => e.comCdGroupNm === 'CMN133')
				?.map((option) => ({
					label: option.korComCdAliasNm,
					value: option.comCdId,
				})) ?? [];
		const bank =
			commonCodes
				?.filter((e) => e.comCdGroupNm === 'CMN037')
				?.map((option) => ({
					label: option.korComCdAliasNm,
					value: option.comCdId,
				})) ?? [];
		const healthCheckKind =
			commonCodes
				?.filter((e) => e.comCdGroupNm === 'CMN163')
				?.map((option) => ({
					label: option.korComCdAliasNm,
					value: option.comCdId,
				})) ?? [];
		const docReceiveMethod =
			commonCodes
				?.filter((e) => e.comCdGroupNm === 'CMN180')
				?.map((option) => ({
					label: option.korComCdAliasNm,
					value: option.comCdId,
				})) ?? [];
		return {
			duty,
			day,
			disabilityLevel,
			workState,
			payType,
			bank,
			healthCheckKind,
			docReceiveMethod,
		};
	}, [commonCodes]);

	const handleNavigateResignPage = async () => {
		const resignInfo = await getResignInfo({
			centerId: myAccountInfo?.centerId,
			employeeId: currentEmployee?.employeeId ? String(currentEmployee?.employeeId) : undefined,
		});

		if (resignInfo?.code === ResponseCode.SUCCESS && resignInfo.data?.lastEsignYn) {
			navigate(`${RouterPath.employee().tab.key}/${currentEmployee?.employeeId}/resign`);
		} else {
			showDialog(({ hideDialog }) => (
				<DefaultDialog
					title='퇴사 진행'
					content={`${currentEmployee?.korMemberNm}님은 사직서가 없습니다.\n퇴사를 진행하시겠습니까?`}
					hideDialog={hideDialog}
					cancelOption={{
						text: '취소',
					}}
					successOption={{
						text: '확인',
						successCallback: () => {
							navigate(`${RouterPath.employee().tab.key}/${currentEmployee?.employeeId}/resign`);
							hideDialog();
						},
					}}
				/>
			));
		}
	};

	const handleClickDeleteEmployee = () => {
		showDialog(() => <EmployeeDeleteDialog currentEmployee={currentEmployee} />);
	};

	useEffect(() => {
		getResignInfo({
			centerId: myAccountInfo?.centerId,
			employeeId: currentEmployee?.employeeId ? String(currentEmployee?.employeeId) : undefined,
		});
	}, [employeeDetailData]);

	const isResign = employeeDetailData?.workStateCd === WorkStateCd.퇴사;

	return (
		<S.Container>
			{employeeDetailLoading ? (
				<CRSpinner />
			) : (
				<>
					<EmployeeBasicStatusFormView />
					<EmployeeBasicInfoForm options={options} item={employeeDetailData} />
					<OtherJobForm options={options} item={otherJobData} />
					<EmployeePayInfoForm options={options} item={employeeDetailData} />
					<S.ButtonContainer>
						{hasWithdrawEmployeeFunc ? (
							<S.WithdrawalButtonContainer>
								<CRButton.Default
									disabled={isResign}
									type='outlined'
									onClick={handleNavigateResignPage}>
									{`퇴사${isResign && !resignPreInfo?.data?.lastEsignYn ? '(서명필요)' : ''}`}
								</CRButton.Default>
							</S.WithdrawalButtonContainer>
						) : (
							<RDTooltip content={<>행정마스터만 직원을 퇴사할 수 있습니다.</>} side='right'>
								<CRButton.Default disabled>퇴사</CRButton.Default>
							</RDTooltip>
						)}
						{hasDeleteEmployeeFunc ? (
							<CRButton.Default type='tonal' palette='gray' onClick={handleClickDeleteEmployee}>
								삭제
							</CRButton.Default>
						) : (
							<RDTooltip content={<>행정마스터만 직원 정보를 삭제할 수 있습니다.</>} side='right'>
								<CRButton.Default disabled>삭제</CRButton.Default>
							</RDTooltip>
						)}
					</S.ButtonContainer>
				</>
			)}
		</S.Container>
	);
}
