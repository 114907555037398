import { styled } from 'styled-components';

export const Container = styled.div`
	width: 100%;
	height: 100%;
	max-width: 103.2rem;
	min-width: 80rem;
	margin: 0 auto;
`;

export const FormContainer = styled.div`
	padding: 2.4rem 0;
	box-sizing: border-box;
`;

export const EmptyContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.8rem;
	flex: 1;
	height: 100%;
`;

export const EmptyTitle = styled.h3`
	${(props) => props.theme.typography.h3};
	color: ${(props) => props.theme.colors.gray60};
	white-space: pre-wrap;
`;
