import React, { useCallback } from 'react';

import CRButton from 'components/base/CRButton';
import CRFileName from 'components/base/CRFileName';
import CRTable from 'components/base/CRTable';
import { Toast } from 'components/base/CRToast';
import DeleteDialog from 'components/domain/dialog/DeleteDialog';
import EDocNoDialog from 'components/domain/dialog/EdocNoDialog';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import { useDeletePaper, useMyAccountInfo } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { useDownload } from 'lib/hook/util/useDownload';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import { ResponseCode } from 'types/api';
import { EmployeeEDocDTO } from 'types/api/eDoc';
import { FileDTO } from 'types/dto';

import { EMPLOYEE_DOCUMENT_TABLE_HEADER_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	items?: EmployeeEDocDTO[] | null;
	onRefetch: () => void;
}

function EmployeeDocumentTable({ items = [], onRefetch }: Props): React.ReactElement {
	const hasDeleteDocumentFunc = useHasFunc(['employee:delete_document']);
	const { showDialog, hideDialog } = useDialog();
	const { currentEmployee } = useEmployeePage();
	const { mutate: deletePaper, isLoading } = useDeletePaper((client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			onRefetch();
			Toast.success(`서류가 삭제되었습니다.`);
			hideDialog();
		}
	});
	const { data: myAccountInfo } = useMyAccountInfo();

	const handleClickPreview = (eDocNo: string) => () => {
		showDialog(() => <EDocNoDialog viewerType='report' edocNo={eDocNo} />);
	};

	const handleDeletePaper = (item?: EmployeeEDocDTO) => {
		if (
			!item ||
			!item?.employeePaperId ||
			!myAccountInfo?.centerId ||
			!currentEmployee?.employeeId ||
			isLoading
		)
			return;

		const deleteEmployeePaper = async () => {
			deletePaper({
				centerId: myAccountInfo.centerId,
				paperId: item.employeePaperId,
				employeeId: currentEmployee.employeeId,
			});
		};

		showDialog(({ hideDialog }) => (
			<DeleteDialog
				title='등록서류 삭제'
				content={`${currentEmployee?.korMemberNm} 님의 ${
					item?.etcPaperNm || item.paperTypeNm
				}를 삭제하겠습니다.`}
				hideDialog={hideDialog}
				cancelOption={{
					text: '취소',
				}}
				successOption={{
					text: '삭제',
					successCallback: () => deleteEmployeePaper(),
				}}
			/>
		));
	};

	const { downloadFiles } = useDownload();

	const renderPaperFile = useCallback((item?: FileDTO) => {
		const fileNames = item?.fileDetails
			?.filter((file) => !file.fileDeleteYn)
			?.map((data) => data.originFileNm);
		return <CRFileName maxLength={25} fileNames={fileNames} />;
	}, []);

	const renderPaperTypeName = (paperTypeName: string, item?: EmployeeEDocDTO) => (
		<S.TextContainer>
			{item?.etcPaperNm ? `${paperTypeName}(${item.etcPaperNm})` : paperTypeName}
		</S.TextContainer>
	);

	const renderOption = (eDocNo: string, item?: EmployeeEDocDTO) => (
		<S.ButtonContainer>
			{item?.paperClassNm === '발급' && (
				<CRButton.Default
					size='xSmall'
					type='outlined'
					palette='gray'
					onClick={handleClickPreview(eDocNo)}>
					미리보기
				</CRButton.Default>
			)}
			{item?.paperClassNm === '등록' && (
				<>
					<CRButton.Default
						size='xSmall'
						type='outlined'
						palette='gray'
						onClick={() => {
							const files = item?.paperFile?.fileDetails.filter((file) => !file.fileDeleteYn);
							downloadFiles(files);
						}}>
						다운로드
					</CRButton.Default>
					<CRButton.Default
						disabled={!hasDeleteDocumentFunc}
						size='xSmall'
						type='outlined'
						palette='primary'
						onClick={() => handleDeletePaper(item)}>
						삭제
					</CRButton.Default>
				</>
			)}
		</S.ButtonContainer>
	);

	return (
		<S.Container>
			<CRTable.Root>
				<CRTable.Head heads={EMPLOYEE_DOCUMENT_TABLE_HEADER_CONFIG} />
				<CRTable.Body>
					{items?.map((item, index) => (
						<CRTable.Row
							style={{
								height: '3.4rem',
							}}
							toolTipKey={['paperFile']}
							key={item.edocNo}
							item={{
								...item,
								index: index + 1,
							}}
							customRender={{
								edocNo: renderOption,
								paperFile: renderPaperFile,
								paperTypeNm: renderPaperTypeName,
							}}
							renderKeys={[
								'index',
								'paperClassNm',
								'paperStandardDate',
								'paperTypeNm',
								'paperFile',
								'remark',
								'edocNo',
							]}
							customStyle={{
								index: {
									textAlign: 'right',
								},
							}}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default EmployeeDocumentTable;
