import React, { useEffect, useMemo, useState } from 'react';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRSpinner from 'components/base/CRSpinner';
import CRTable from 'components/base/CRTable';
import CRTableHeader from 'components/base/CRTableHeader';
import { CheckOption } from 'components/base/Selections/type';
import AddCenterAccountDialog from 'components/domain/dialog/AddCenterAccountDialog';
import CenterAccountManagementTable from 'components/domain/table/CenterAccountManagementTable';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes, useMyAccountInfo } from 'lib/hook/react-query';
import { useCenterAccountManagement } from 'lib/hook/react-query/query/accountManagement';
import useDialog from 'lib/hook/util/useDialog';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import usePageFilter from 'lib/hook/util/usePageFilter';
import { PageInfo } from 'types/view/base';
import { Filter } from 'types/view/filter';

import * as S from './styles';

export function CenterAccountManagementTab(): React.ReactElement {
	const { data: commonCodes } = useCommonCodes(
		{
			comCdGroupNms: ['CMN037'],
		},
		commonCodeAdapter,
	);
	const hasCreateBankAccountFunc = useHasFunc(['center:create_bank_account']);
	const hasReadBankAccountFunc = useHasFunc(['center:read_bank_account']);

	const {
		filters: currentFilter,
		setFilters: setCurrentFilter,
		pageInfo,
		setPageInfo,
	} = usePageFilter({});
	const [keyword, setKeyword] = useState('');
	const { data: myAccountInfo } = useMyAccountInfo();
	const [searchValue, setSearchValue] = useState('');

	const { showDialog, hideDialog } = useDialog();

	const {
		data: centerAccountsInfo,
		isLoading: centerAccountsInfoLoading,
		refetch,
	} = useCenterAccountManagement({
		page: pageInfo.page,
		size: pageInfo.size,
		centerId: hasReadBankAccountFunc ? myAccountInfo?.centerId : undefined,
		centerAccountBankCds: currentFilter?.bankFilter?.map((item) => item.value) || [],
		keyword,
	});

	const handleRefetch = () => {
		refetch();
	};

	const bankOptions = commonCodes?.CMN037 || [];

	const bankFilter = useMemo(
		() =>
			({
				key: 'bankFilter',
				type: 'multi',
				placeholder: '은행명',
				options: bankOptions,
			}) as Filter<CheckOption>,
		[bankOptions],
	);

	const handleSearchValue = (value: string) => setSearchValue(value);
	const handleSearch = () => {
		setPageInfo((prev) => ({
			...prev,
			page: 1,
		}));
		setKeyword(searchValue);
	};

	const handleChangePageInfo = (pageInfo: PageInfo) => {
		setPageInfo(pageInfo);
	};

	const handleClickAddAccount = () => {
		if (!bankOptions?.length) return;
		showDialog(() => (
			<AddCenterAccountDialog
				bankOptions={bankOptions}
				hideDialog={hideDialog}
				onRefresh={refetch}
			/>
		));
	};

	const renderEmpty = useMemo(
		() => (
			<S.EmptyContainer>
				<S.EmptyTitle>계좌 정보가 없습니다.</S.EmptyTitle>
			</S.EmptyContainer>
		),
		[],
	);

	const renderDenyReadBankAccount = useMemo(
		() => (
			<S.EmptyContainer>
				<S.EmptyTitle>계좌 관리 조회 권한이 없습니다.</S.EmptyTitle>
			</S.EmptyContainer>
		),
		[],
	);

	useEffect(() => {
		setPageInfo((prev) => ({
			...prev,
			totalPages: centerAccountsInfo?.totalPages || 0,
		}));
	}, [centerAccountsInfo?.totalPages]);

	return (
		<CRTable.BackBlind
			contentStyle={{
				padding: 0,
			}}>
			<S.TableContainer>
				<S.TableHeaderContainer>
					<CRTableHeader
						onChangePageInfo={handleChangePageInfo}
						pageInfo={pageInfo}
						currentFilter={currentFilter}
						stickyMode
						filters={[bankFilter]}
						searchValue={searchValue}
						placeholder='계좌 별칭으로 검색'
						onChangeSearchValue={handleSearchValue}
						setCurrentFilter={setCurrentFilter}
						onSearch={handleSearch}
						showRefresh
						showViewCount
						onRefresh={handleRefetch}
						renderRightButton={
							hasCreateBankAccountFunc ? (
								<CRButton.IconButton
									size='xSmall'
									type='filled'
									iconLeft={Assets.icon.add}
									onClick={handleClickAddAccount}>
									계좌 추가
								</CRButton.IconButton>
							) : undefined
						}
					/>
				</S.TableHeaderContainer>
				{hasReadBankAccountFunc && centerAccountsInfoLoading && <CRSpinner />}
				{hasReadBankAccountFunc &&
					centerAccountsInfo &&
					centerAccountsInfo?.content?.length > 0 && (
						<S.TableScrollContainer>
							<CenterAccountManagementTable
								bankOptions={bankOptions}
								refetch={handleRefetch}
								items={centerAccountsInfo?.content || []}
							/>
						</S.TableScrollContainer>
					)}
				{hasReadBankAccountFunc &&
					centerAccountsInfo &&
					centerAccountsInfo?.content?.length === 0 &&
					renderEmpty}
				{!hasReadBankAccountFunc && renderDenyReadBankAccount}
			</S.TableContainer>
		</CRTable.BackBlind>
	);
}
