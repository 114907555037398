import React, { ChangeEvent } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import CRInput from 'components/base/CRInput';
import CRTableInput from 'components/base/CRInput/CRTableInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRRequiredMark from 'components/base/CRRequiredMark';
import { IntegratedAssessmentDesireForms } from 'types/view/recipient';

import CRCheckBox from '../../../base/Selections/CRCheckBox';
import CRCheckBoxGroup from '../../../base/Selections/CRCheckBoxGroup';
import IntegratedAssessmentDesireFamilySupportSystemFormHeader from './IntegratedAssessmentDesireFamilySupportSystemFormHeader';
import { integratedAssessmentDesireFamilySupportSystemQuestions } from './constant';
import * as S from './styles';

interface Props {
	form: UseFormReturn<IntegratedAssessmentDesireForms>;
	showGenerateRequiredFields?: boolean;
	enabled?: boolean;
}

function IntegratedAssessmentDesireFamilySupportSystemForm({
	form,
	showGenerateRequiredFields,
	enabled,
}: Props): React.ReactElement {
	return (
		<S.Container>
			<S.Table>
				<IntegratedAssessmentDesireFamilySupportSystemFormHeader />
				<S.TableBody>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireFamilySupportSystemQuestions[0];
							return (
								<S.TableBodyRow>
									<S.TableBodyColumn style={{ textAlign: 'center' }}>
										{item.label}
									</S.TableBodyColumn>
									<S.TableBodyColumn colSpan={4}>
										<S.TableContentContainer>
											{(item.answer ?? []).map((answer) => (
												<>
													{answer.disabled ? (
														answer.label
													) : (
														<CRCheckBox
															disabled={!enabled}
															checkBoxType='checkbox'
															checked={(form.watch(item.key) as string[])?.includes(answer.value)}
															onChange={(event: ChangeEvent<HTMLInputElement>) => {
																const targetValue = value as string[];

																if (event.target.checked) {
																	onChange([...(targetValue ?? []), answer.value]);
																} else {
																	onChange(
																		(targetValue ?? []).filter((item) => item !== answer.value),
																	);
																}
															}}>
															{answer.label}
														</CRCheckBox>
													)}
													{answer.options && (
														<>
															(
															{answer.options?.map((option) => (
																<CRCheckBox
																	disabled={!enabled}
																	checkBoxType='checkbox'
																	checked={(form.watch(item.key) as string[])?.includes(
																		option.value,
																	)}
																	onChange={(event: ChangeEvent<HTMLInputElement>) => {
																		const targetValue = value as string[];

																		if (event.target.checked) {
																			onChange([...(targetValue ?? []), option.value]);
																		} else {
																			onChange(
																				(targetValue ?? []).filter((item) => item !== option.value),
																			);
																		}
																	}}>
																	{option.label}
																</CRCheckBox>
															))}
															)
														</>
													)}
												</>
											))}
										</S.TableContentContainer>
										{(form.watch(item.key) as string[])?.includes('기타') && (
											<S.TableEtcContainer>
												<Controller
													render={({ field: { ref, onChange, value }, formState: { errors } }) => (
														<CRInput.TextArea
															ref={ref}
															disabled={!enabled}
															placeholder='기타 사항 입력'
															onChange={onChange}
															value={value as string}
														/>
													)}
													name={`${item.key}기타` as keyof IntegratedAssessmentDesireForms}
													control={form.control}
												/>
											</S.TableEtcContainer>
										)}
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireFamilySupportSystemQuestions[0].key}
						control={form.control}
					/>
					<S.TableBodyRow>
						<S.TableBodyColumn style={{ textAlign: 'center' }}>
							자녀수{showGenerateRequiredFields && <CRRequiredMark />}
						</S.TableBodyColumn>
						<S.TableBodyColumn colSpan={4}>
							<S.TableContentContainer>
								<Controller
									render={({ field: { ref, onChange, value }, formState: { errors } }) => (
										<CRCheckBox
											disabled={!enabled}
											checked={value}
											onChange={(e) => {
												const { checked } = e.target;
												onChange(checked);
												if (checked) {
													form.setValue('자녀수아들', '0');
													form.setValue('자녀수딸', '0');
												} else {
													form.setValue('자녀수아들', '');
													form.setValue('자녀수딸', '');
												}
											}}>
											무
										</CRCheckBox>
									)}
									name='자녀수'
									control={form.control}
								/>
								유( 아들
								<Controller
									render={({ field, formState: { errors } }) => (
										<S.CountInputContainer $width={2.6}>
											<CRTableInput
												{...field}
												disabled={!enabled}
												maxLength={1}
												onChange={(e) => {
													field.onChange(e);
													form.setValue('자녀수', false);
												}}
												status={errors.자녀수아들?.message ? 'error' : 'default'}
												type='number'
												placeholder='0'
											/>
										</S.CountInputContainer>
									)}
									name='자녀수아들'
									control={form.control}
								/>
								명 딸
								<Controller
									render={({ field, formState: { errors } }) => (
										<S.CountInputContainer $width={2.6}>
											<CRTableInput
												{...field}
												disabled={!enabled}
												maxLength={1}
												onChange={(e) => {
													field.onChange(e);
													form.setValue('자녀수', false);
												}}
												status={errors.자녀수딸?.message ? 'error' : 'default'}
												type='number'
												placeholder='0'
											/>
										</S.CountInputContainer>
									)}
									name='자녀수딸'
									control={form.control}
								/>
								명 )
							</S.TableContentContainer>
						</S.TableBodyColumn>
					</S.TableBodyRow>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireFamilySupportSystemQuestions[1];
							return (
								<S.TableBodyRow>
									<S.TableBodyColumn style={{ textAlign: 'center' }}>
										{item.label}
									</S.TableBodyColumn>
									<S.TableBodyColumn colSpan={4}>
										<S.TableContentContainer>
											{(item.answer ?? []).map((answer) => (
												<>
													{answer.disabled ? (
														answer.label
													) : (
														<CRCheckBox
															disabled={!enabled}
															checkBoxType='checkbox'
															checked={(form.watch(item.key) as string[])?.includes(answer.value)}
															onChange={(event: ChangeEvent<HTMLInputElement>) => {
																const targetValue = value as string[];

																if (event.target.checked) {
																	onChange([...(targetValue ?? []), answer.value]);
																} else {
																	onChange(
																		(targetValue ?? []).filter((item) => item !== answer.value),
																	);
																}
															}}>
															{answer.label}
														</CRCheckBox>
													)}
													{answer.options && (
														<>
															(
															{answer.options?.map((option) => (
																<CRCheckBox
																	disabled={!enabled}
																	checkBoxType='checkbox'
																	checked={(form.watch(item.key) as string[])?.includes(
																		option.value,
																	)}
																	onChange={(event: ChangeEvent<HTMLInputElement>) => {
																		const targetValue = value as string[];

																		if (event.target.checked) {
																			onChange([...(targetValue ?? []), option.value]);
																		} else {
																			onChange(
																				(targetValue ?? []).filter((item) => item !== option.value),
																			);
																		}
																	}}>
																	{option.label}
																</CRCheckBox>
															))}
															)
														</>
													)}
												</>
											))}
										</S.TableContentContainer>
										{(form.watch(item.key) as string[])?.includes('기타') && (
											<S.TableEtcContainer>
												<Controller
													render={({ field: { ref, onChange, value }, formState: { errors } }) => (
														<CRInput.TextArea
															ref={ref}
															disabled={!enabled}
															placeholder='기타 사항 입력'
															onChange={onChange}
															value={value as string}
														/>
													)}
													name={`${item.key}기타` as keyof IntegratedAssessmentDesireForms}
													control={form.control}
												/>
											</S.TableEtcContainer>
										)}
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireFamilySupportSystemQuestions[1].key}
						control={form.control}
					/>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireFamilySupportSystemQuestions[2];
							return (
								<S.TableBodyRow>
									<S.TableBodyColumn style={{ textAlign: 'center' }}>
										{item.label}
									</S.TableBodyColumn>
									<S.TableBodyColumn colSpan={4}>
										<S.TableContentContainer>
											{(item.answer ?? []).map((answer) => (
												<>
													{answer.disabled ? (
														answer.label
													) : (
														<CRCheckBox
															disabled={!enabled}
															checkBoxType='checkbox'
															checked={(form.watch(item.key) as string[])?.includes(answer.value)}
															onChange={(event: ChangeEvent<HTMLInputElement>) => {
																const targetValue = value as string[];

																if (event.target.checked) {
																	onChange([...(targetValue ?? []), answer.value]);
																} else {
																	onChange(
																		(targetValue ?? []).filter((item) => item !== answer.value),
																	);
																}
															}}>
															{answer.label}
														</CRCheckBox>
													)}
													{answer.options && (
														<>
															(
															{answer.options?.map((option) => (
																<CRCheckBox
																	disabled={!enabled}
																	checkBoxType='checkbox'
																	checked={(form.watch(item.key) as string[])?.includes(
																		option.value,
																	)}
																	onChange={(event: ChangeEvent<HTMLInputElement>) => {
																		const targetValue = value as string[];

																		if (event.target.checked) {
																			onChange([...(targetValue ?? []), option.value]);
																		} else {
																			onChange(
																				(targetValue ?? []).filter((item) => item !== option.value),
																			);
																		}
																	}}>
																	{option.label}
																</CRCheckBox>
															))}
															)
														</>
													)}
												</>
											))}
										</S.TableContentContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireFamilySupportSystemQuestions[2].key}
						control={form.control}
					/>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireFamilySupportSystemQuestions[3];
							return (
								<S.TableBodyRow>
									<S.TableBodyColumn style={{ textAlign: 'center' }}>
										{item.label}
									</S.TableBodyColumn>
									<S.TableBodyColumn colSpan={4}>
										<S.TableContentContainer>
											{(item.answer ?? []).map((answer) => (
												<>
													{answer.disabled ? (
														answer.label
													) : (
														<CRCheckBox
															disabled={!enabled}
															checkBoxType='checkbox'
															checked={(form.watch(item.key) as string[])?.includes(answer.value)}
															onChange={(event: ChangeEvent<HTMLInputElement>) => {
																const targetValue = value as string[];

																if (event.target.checked) {
																	onChange([...(targetValue ?? []), answer.value]);
																} else {
																	onChange(
																		(targetValue ?? []).filter((item) => item !== answer.value),
																	);
																}
															}}>
															{answer.label}
														</CRCheckBox>
													)}
													{answer.options && (
														<>
															(
															{answer.options?.map((option) => (
																<CRCheckBox
																	disabled={!enabled}
																	checkBoxType='checkbox'
																	checked={(form.watch(item.key) as string[])?.includes(
																		option.value,
																	)}
																	onChange={(event: ChangeEvent<HTMLInputElement>) => {
																		const targetValue = value as string[];

																		if (event.target.checked) {
																			onChange([...(targetValue ?? []), option.value]);
																		} else {
																			onChange(
																				(targetValue ?? []).filter((item) => item !== option.value),
																			);
																		}
																	}}>
																	{option.label}
																</CRCheckBox>
															))}
															)
														</>
													)}
												</>
											))}
										</S.TableContentContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireFamilySupportSystemQuestions[3].key}
						control={form.control}
					/>
					<S.TableBodyRow>
						<S.TableBodyColumn style={{ textAlign: 'center' }} rowSpan={2}>
							진료 병원
						</S.TableBodyColumn>
						<S.TableBodyColumn>병원명(진료과)</S.TableBodyColumn>
						<S.TableBodyColumn>
							<Controller
								render={({ field, formState: { errors } }) => (
									<CRTableInput
										{...field}
										disabled={!enabled}
										status={errors.진료병원?.message ? 'error' : 'default'}
										placeholder='병원명 입력'
									/>
								)}
								name='진료병원'
								control={form.control}
							/>
						</S.TableBodyColumn>
						<S.TableBodyColumn rowSpan={2}>전화번호</S.TableBodyColumn>
						<S.TableBodyColumn rowSpan={2}>
							<Controller
								render={({ field, formState: { errors } }) => (
									<CRTableInput
										{...field}
										disabled={!enabled}
										maxLength={15}
										status={errors.진료병원번호?.message ? 'error' : 'default'}
										type='number'
										placeholder='전화번호 입력'
									/>
								)}
								name='진료병원번호'
								control={form.control}
							/>
						</S.TableBodyColumn>
					</S.TableBodyRow>
					<S.TableBodyRow>
						<S.TableBodyColumn>
							정기진료{showGenerateRequiredFields && <CRRequiredMark />}
						</S.TableBodyColumn>
						<S.TableBodyColumn>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBoxGroup
										disabled={!enabled}
										type='radio'
										checkType='single'
										options={[
											{ label: '유', value: true },
											{ label: '무', value: false },
										]}
										value={[{ label: '', value }]}
										style={{ gap: '0.8rem' }}
										onChange={(item) => onChange(item[0].value)}
									/>
								)}
								name='정기진료'
								control={form.control}
							/>
						</S.TableBodyColumn>
					</S.TableBodyRow>
					<S.TableBodyRow>
						<S.TableBodyColumn style={{ textAlign: 'center' }}>약복용</S.TableBodyColumn>
						<S.TableBodyColumn colSpan={4}>
							<S.TableContentContainer>
								횟수: (
								<Controller
									render={({ field, formState: { errors } }) => (
										<S.CountInputContainer $width={4.6}>
											<CRTableInput
												{...field}
												disabled={!enabled}
												maxLength={2}
												status={errors.약복용일간?.message ? 'error' : 'default'}
												type='number'
												placeholder='0'
											/>
										</S.CountInputContainer>
									)}
									name='약복용일간'
									control={form.control}
								/>
								/일,
								<Controller
									render={({ field, formState: { errors } }) => (
										<S.CountInputContainer $width={4.6}>
											<CRTableInput
												{...field}
												disabled={!enabled}
												maxLength={2}
												status={errors.약복용주간?.message ? 'error' : 'default'}
												type='number'
												placeholder='0'
											/>
										</S.CountInputContainer>
									)}
									name='약복용주간'
									control={form.control}
								/>
								/주 ) 1회 복용 개수
								<Controller
									render={({ field, formState: { errors } }) => (
										<S.CountInputContainer $width={4.6}>
											<CRTableInput
												{...field}
												disabled={!enabled}
												maxLength={2}
												status={errors.약복용갯수?.message ? 'error' : 'default'}
												type='number'
												placeholder='0'
											/>
										</S.CountInputContainer>
									)}
									name='약복용갯수'
									control={form.control}
								/>
								개
							</S.TableContentContainer>
						</S.TableBodyColumn>
					</S.TableBodyRow>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireFamilySupportSystemQuestions[4];
							return (
								<S.TableBodyRow>
									<S.TableBodyColumn style={{ textAlign: 'center' }}>
										{item.label}
									</S.TableBodyColumn>
									<S.TableBodyColumn colSpan={4}>
										<S.TableContentContainer>
											{(item.answer ?? []).map((answer) => (
												<>
													{answer.disabled ? (
														answer.label
													) : (
														<CRCheckBox
															disabled={!enabled}
															checkBoxType='checkbox'
															checked={(form.watch(item.key) as string[])?.includes(answer.value)}
															onChange={(event: ChangeEvent<HTMLInputElement>) => {
																const targetValue = value as string[];

																if (event.target.checked) {
																	onChange([...(targetValue ?? []), answer.value]);
																} else {
																	onChange(
																		(targetValue ?? []).filter((item) => item !== answer.value),
																	);
																}
															}}>
															{answer.label}
														</CRCheckBox>
													)}
													{answer.options && (
														<>
															(
															{answer.options?.map((option) => (
																<CRCheckBox
																	disabled={!enabled}
																	checkBoxType='checkbox'
																	checked={(form.watch(item.key) as string[])?.includes(
																		option.value,
																	)}
																	onChange={(event: ChangeEvent<HTMLInputElement>) => {
																		const targetValue = value as string[];

																		if (event.target.checked) {
																			onChange([...(targetValue ?? []), option.value]);
																		} else {
																			onChange(
																				(targetValue ?? []).filter((item) => item !== option.value),
																			);
																		}
																	}}>
																	{option.label}
																</CRCheckBox>
															))}
															)
														</>
													)}
												</>
											))}
										</S.TableContentContainer>
										{(form.watch(item.key) as string[])?.includes('기타') && (
											<S.TableEtcContainer>
												<Controller
													render={({ field: { ref, onChange, value }, formState: { errors } }) => (
														<CRInput.TextArea
															ref={ref}
															disabled={!enabled}
															placeholder='기타 사항 입력'
															onChange={onChange}
															value={value as string}
														/>
													)}
													name={`${item.key}기타` as keyof IntegratedAssessmentDesireForms}
													control={form.control}
												/>
											</S.TableEtcContainer>
										)}
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireFamilySupportSystemQuestions[4].key}
						control={form.control}
					/>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireFamilySupportSystemQuestions[5];
							return (
								<S.TableBodyRow>
									<S.TableBodyColumn style={{ textAlign: 'center' }}>
										{item.label}
									</S.TableBodyColumn>
									<S.TableBodyColumn colSpan={4}>
										<S.TableContentContainer>
											{(item.answer ?? []).map((answer) => (
												<>
													{answer.disabled ? (
														answer.label
													) : (
														<CRCheckBox
															disabled={!enabled}
															checkBoxType='checkbox'
															checked={(form.watch(item.key) as string[])?.includes(answer.value)}
															onChange={(event: ChangeEvent<HTMLInputElement>) => {
																const targetValue = value as string[];

																if (event.target.checked) {
																	onChange([...(targetValue ?? []), answer.value]);
																} else {
																	onChange(
																		(targetValue ?? []).filter((item) => item !== answer.value),
																	);
																}
															}}>
															{answer.label}
														</CRCheckBox>
													)}
													{answer.options && (
														<>
															(
															{answer.options?.map((option) => (
																<CRCheckBox
																	disabled={!enabled}
																	checkBoxType='checkbox'
																	checked={(form.watch(item.key) as string[])?.includes(
																		option.value,
																	)}
																	onChange={(event: ChangeEvent<HTMLInputElement>) => {
																		const targetValue = value as string[];

																		if (event.target.checked) {
																			onChange([...(targetValue ?? []), option.value]);
																		} else {
																			onChange(
																				(targetValue ?? []).filter((item) => item !== option.value),
																			);
																		}
																	}}>
																	{option.label}
																</CRCheckBox>
															))}
															)
														</>
													)}
												</>
											))}
										</S.TableContentContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireFamilySupportSystemQuestions[5].key}
						control={form.control}
					/>
				</S.TableBody>
			</S.Table>
			<Controller
				render={({ field: { ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='기타 내용 및 판단 근거' isRequired={showGenerateRequiredFields}>
						<CRInput.TextArea
							ref={ref}
							disabled={!enabled}
							placeholder='기타 내용 및 판단 근거 입력'
							onChange={onChange}
							value={value}
						/>
					</CRInputLabel>
				)}
				name='가족및지지체계판단근거'
				control={form.control}
			/>
		</S.Container>
	);
}

export default IntegratedAssessmentDesireFamilySupportSystemForm;
