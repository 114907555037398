import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	box-sizing: border-box;
	gap: 0.8rem;
	overflow: hidden;
`;

export const FilterGroup = styled.div``;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const RightTableContainer = styled.div``;

export const RightTabContainer = styled.div`
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
`;

export const RightTabViewContainer = styled.div`
	flex: 1;
	overflow: auto;
	border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const TabView = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	overflow: hidden;
`;

export const Title = styled.h4`
	color: ${(props) => props.theme.colors.gray10};
`;

export const TableContainer = styled.div`
	flex: 1;
	overflow: hidden;
	border-radius: 0.4rem;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const TableScrollContainer = styled.div`
	height: 100%;
	overflow-x: hidden;
	overflow-y: overlay;
`;

export const LeftLayoutBottomContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;
