import React from 'react';
import { useNavigate } from 'react-router-dom';

import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import RecipientDeleteDialog from 'components/domain/dialog/RecipientDeleteDialog';
import RecipientBasicInfoFormView from 'components/domain/form/RecipientBasicInfoFormView';
import RecipientBasicStatusFormView from 'components/domain/form/RecipientBasicStatusFormView';
import RecipientGuardianFormView from 'components/domain/form/RecipientGuardianFormView';
import RecipientLongTermFormView from 'components/domain/form/RecipientLongTermFormView';
import RecipientOtherServiceFormView from 'components/domain/form/RecipientOtherServiceFormView';
import RecipientOwnExpenseFormView from 'components/domain/form/RecipientOwnExpenseFormView';
import RecipientScheduleAutomationFormView from 'components/domain/form/RecipientScheduleAutomationFormView';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import useDialog from 'lib/hook/util/useDialog';
import { useHasFunc } from 'lib/hook/util/useHasFunc';

import * as S from './styles';

export default function RecipientBasicTab(): React.ReactElement {
	const hasDeleteRecipientFunc = useHasFunc(['recipient:delete_recipient']);
	const hasWithdrawRecipientFunc = useHasFunc(['recipient:withdraw_recipient']);
	const { currentRecipient, currentRecipientStatus } = useRecipientPage();
	const navigate = useNavigate();
	const { showDialog } = useDialog();

	const handleNavigateResignPage = () => {
		navigate(`${RouterPath.recipient().tab.key}/${currentRecipient?.recipientId}/resign`);
	};

	const handleClickDeleteRecipient = () => {
		showDialog(() => <RecipientDeleteDialog currentRecipient={currentRecipient} />);
	};

	const isResign = currentRecipientStatus?.status === 'CMN058.30';

	return (
		<S.ScrollableContainer>
			<RecipientBasicStatusFormView />
			<RecipientBasicInfoFormView />
			<RecipientLongTermFormView />
			<RecipientGuardianFormView />
			<RecipientOtherServiceFormView />
			<RecipientOwnExpenseFormView />
			<RecipientScheduleAutomationFormView />
			<S.ButtonContainer>
				{hasWithdrawRecipientFunc ? (
					<S.WithdrawalButtonContainer>
						<CRButton.Default
							disabled={isResign}
							type='outlined'
							onClick={handleNavigateResignPage}>
							퇴소
						</CRButton.Default>
					</S.WithdrawalButtonContainer>
				) : (
					<RDTooltip content={<>행정마스터만 수급를 퇴소할 수 있습니다.</>} side='right'>
						<CRButton.Default disabled>퇴소</CRButton.Default>
					</RDTooltip>
				)}
				{hasDeleteRecipientFunc ? (
					<CRButton.Default type='tonal' palette='gray' onClick={handleClickDeleteRecipient}>
						삭제
					</CRButton.Default>
				) : (
					<RDTooltip content={<>행정마스터만 수급자 정보를 삭제할 수 있습니다.</>} side='right'>
						<CRButton.Default disabled>삭제</CRButton.Default>
					</RDTooltip>
				)}
			</S.ButtonContainer>
		</S.ScrollableContainer>
	);
}
