import React, { useEffect } from 'react';
import { Controller, FieldErrors, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import Colors from 'common/colors';
import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import CRInputMessage from 'components/base/CRInputMessage';
import CRRsdNumber from 'components/base/CRRsdNumber';
import { Toast } from 'components/base/CRToast';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CheckOption } from 'components/base/Selections/type';
import CheckFullTimeEmployeeCriminalRecord from 'components/domain/dialog/CheckFullTimeEmployeeCriminalRecord';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import FullTimeEmployeeHistoryOfDutyDialog from 'components/domain/dialog/FullTimeEmployeeHistoryOfDutyDialog';
import { FullTimeEmployeeBasicInfo, displayBirthDay } from 'lib';
import useFullTimeEmployeePage from 'lib/hook/full-time-employee/useFullTimeEmployeePage';
import { useMyAccountInfo, useUpdateFullTimeEmployeeDetailInfo } from 'lib/hook/react-query';
import { DaumPostcodeData } from 'lib/hook/util/useDaumPostCode';
import useDialog from 'lib/hook/util/useDialog';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { endpoint } from 'lib/service/Api/endpoint';
import { loadApplication } from 'lib/service/util/loader';
import {
	FullTimeEmployeeDTO,
	FullTimeEmployeeDetailInfoDTO,
	FullTimeEmployeeDetailInfoFormDTO,
} from 'types/dto/full-time-employee';

import FullTimeEmployeeHealthCheckupHistoryDialog from '../../dialog/FullTimeEmployeeHealthCheckupHistoryDialog';
import * as S from './styles';

interface Props {
	item?: FullTimeEmployeeDetailInfoDTO;
	options: {
		workState: CheckOption[];
		workForm: CheckOption[];
		duty: CheckOption[];
		partType: CheckOption[];
		disability: CheckOption[];
	};
	onStartChangeSection?: () => void;
	onCancelChangeSection?: () => void;
	isEditSectionChanging?: boolean;
}

export default function FullTimeEmployeeBasicInfoForm({
	item,
	options,
	onStartChangeSection,
	onCancelChangeSection,
	isEditSectionChanging,
}: Props): React.ReactElement {
	const hasConfirmCrimeHistoryFunc = useHasFunc(['full_time_employee:confirm_crime_history']);
	const { queryClient } = loadApplication();
	const { showDialog, hideDialog } = useDialog();
	const { data: myAccountInfo } = useMyAccountInfo();
	const { currentEmployee, fullEmployees } = useFullTimeEmployeePage();

	const { control, handleSubmit, watch, setValue } = useForm<FullTimeEmployeeDetailInfoDTO>({
		resolver: yupResolver(FullTimeEmployeeBasicInfo),
		mode: 'onSubmit',
		defaultValues: item,
	});

	const { mutate } = useUpdateFullTimeEmployeeDetailInfo(async (client, returnData, variables) => {
		if (returnData?.employeeId) {
			const queryKey = [
				endpoint.getFullTimeEmployees.key,
				{
					centerIds: myAccountInfo?.centerId ? [myAccountInfo?.centerId] : [],
				},
			];

			queryClient.setQueryData(queryKey, (data) => {
				const employeesCache = data as FullTimeEmployeeDTO[];
				const newCache = employeesCache?.map((employee) => {
					if (employee?.employeeId === variables?.employeeId) {
						const newEmployeeData = {
							...employee,
							korMemberNm: variables?.korMemberNm,
							birthDt: variables?.birthDt,
							dutyNms: variables?.dutyNm,
							mobilePhoneNo: variables?.mobilePhoneNo,
						};
						return newEmployeeData;
					}
					return employee;
				});

				return newCache;
			});

			await queryClient.invalidateQueries([
				endpoint.getFullTimeEmployeeBaseInfo.key,
				{
					centerId: Number(myAccountInfo?.centerId),
					employeeId: Number(currentEmployee?.employeeId),
				},
			]);

			await queryClient.invalidateQueries([
				endpoint.getFullTimeEmployeeDetailInfo.key,
				{
					centerId: Number(myAccountInfo?.centerId),
					memberId: Number(currentEmployee?.memberId),
					employeeId: Number(currentEmployee?.employeeId),
				},
			]);

			hideDialog();
			onStartChangeSection?.();
			Toast.success('기본정보를 수정했습니다.');
		} else {
			onCancelChangeSection?.();
		}
	});

	const onSubmitFail = (errors: FieldErrors<FullTimeEmployeeDetailInfoDTO>) => {
		Toast.error(Object.values(errors)?.[0]?.message || '입력폼을 확인해주세요');
	};

	const onSubmit = async (item: FullTimeEmployeeDetailInfoDTO) => {
		if (!currentEmployee) return;

		const param: FullTimeEmployeeDetailInfoFormDTO = {
			employeeId: currentEmployee?.employeeId,
			centerId: currentEmployee.centerId,
			memberId: currentEmployee.memberId,
			workStateCd: item.workFormCd,
			workStateNm: item.workFormNm,
			userId: item.userId,
			korMemberNm: item.korMemberNm,
			zipCode: item.zipCode,
			basAddr: item.basAddr,
			detailAddr: item.detailAddr,
			regionNm1: item.regionNm1,
			regionNm2: item.regionNm2,
			regionNm3: item.regionNm3,
			roadNm: item.roadNm,
			addrLatitude: item.addrLatitude,
			addrLongitude: item.addrLongitude,
			rsdnNo: item.rsdnNo,
			birthDt: item.birthDt,
			dutyCd: item.dutyCd,
			dutyNm: item.dutyNm,
			joinDate: dayjs(item.joinDate).format('YYYY-MM-DD HH:mm:ss'),
			partDivCd: item.partDivCd,
			workFormCd: item.workFormCd,
			workFormNm: item.workFormNm,
			mobilePhoneNo: item.mobilePhoneNo,
			crimeHistSearchNm: item.crimeHistSearchNm,
			twoPhoneYn: item.twoPhoneYn,
			subMobilePhoneNo: item.subMobilePhoneNo,
			employeeSpecialDesc: item.employeeSpecialDesc,
			rejoinUpdateTargetYn: item.rejoinUpdateTargetYn,
			wageDivCd: item.wageDivCd,
			workMonthCnt: item.workMonthCnt,
			workYearCntNm: item.workYearCntNm,
			annualGenerateDayCnt: item.annualGenerateDayCnt,
			disabilityGradeCd: item.disabilityGradeCd,
			annualDeductDayCnt: item.annualDeductDayCnt,
			annualUseDayCnt: item.annualUseDayCnt,
			etcVacaUseDayCnt: item.etcVacaUseDayCnt,
			annualRemainDayCnt: item.annualRemainDayCnt,
			legalEduCompleteStateNm: item.legalEduCompleteStateNm,
			legalEduCompleteCntNm: item.legalEduCompleteCntNm,
			dementiaEduCompleteStateNm: item.dementiaEduCompleteStateNm,
			centerMoveCancelPosbYn: item.centerMoveCancelPosbYn,
			workExecuteActingEmployeeId: item.workExecuteActingEmployeeId,
			additionDivCd: item.additionDivCd,
		};

		mutate(param);
	};

	const handleClickFullTimeEmployeeCriminalRecord = () => {
		showDialog(({ hideDialog }) => (
			<CheckFullTimeEmployeeCriminalRecord
				currentFullTimeEmployee={currentEmployee}
				hideDialog={hideDialog}
			/>
		));
	};
	const handleClickFullTimeEmployeeDutyHistory = () => {
		showDialog(({ hideDialog }) => (
			<FullTimeEmployeeHistoryOfDutyDialog
				currentFullTimeEmployee={currentEmployee}
				hideDialog={hideDialog}
			/>
		));
	};

	const handleClickHealthCheckupHistory = () => {
		showDialog(({ hideDialog }) => (
			<FullTimeEmployeeHealthCheckupHistoryDialog
				hideDialog={hideDialog}
				currentEmployee={currentEmployee}
			/>
		));
	};

	const handleChangeBaseAddress = (postcodeData: DaumPostcodeData) => {
		setValue('basAddr', postcodeData.address);
		setValue('zipCode', postcodeData.zonecode);
		setValue('addrLatitude', postcodeData.latitude);
		setValue('addrLongitude', postcodeData.longitude);
		setValue('regionNm1', postcodeData.sido);
		setValue('regionNm2', postcodeData.sigungu);
		setValue('regionNm3', postcodeData.bname);
		setValue('roadNm', postcodeData.roadname);
	};

	const handleChangeDetailAddress = (detailAddress: string) => {
		setValue('detailAddr', detailAddress);
	};

	const onSubmitHandler = () => {
		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='변경된 정보 저장'
				content='기본 정보에서 변경된 정보를 저장합니다.'
				successOption={{
					text: '저장',
					successCallback: () => {
						hideDialog();
						handleSubmit(onSubmit, onSubmitFail)();
					},
				}}
				cancelOption={{
					text: '저장안함',
					callback: () => {
						hideDialog();
						onStartChangeSection?.();
					},
				}}
				hideDialog={() => {
					hideDialog();
					onCancelChangeSection?.();
				}}
			/>
		));
	};

	useEffect(() => {
		if (item?.joinDate) setValue('joinDate', dayjs(item?.joinDate).format('YYYYMMDD'));
		if (item?.resignDate) setValue('resignDate', dayjs(item?.resignDate).format('YYYYMMDD'));
	}, []);

	useEffect(() => {
		if (isEditSectionChanging) {
			onSubmitHandler();
		}
	}, [isEditSectionChanging]);

	useEffect(() => {
		if (watch('subMobilePhoneNo')?.length) {
			setValue('twoPhoneYn', true);
		}
	}, [watch('subMobilePhoneNo')]);

	return (
		<S.Container>
			<S.Form onSubmit={handleSubmit(onSubmit)}>
				<S.Label>
					기본 정보
					<S.ButtonContainer>
						<CRButton.Default
							size='xSmall'
							type='outlined'
							palette='gray'
							onClick={onSubmitHandler}>
							취소
						</CRButton.Default>
						<CRButton.Default
							size='xSmall'
							type='filled'
							palette='gray'
							onClick={handleSubmit(onSubmit, onSubmitFail)}>
							저장
						</CRButton.Default>
					</S.ButtonContainer>
				</S.Label>
				<S.Table>
					<S.TableRow>
						<S.TableLabelColumn>
							이름 <S.RequiredMark>*</S.RequiredMark>
						</S.TableLabelColumn>
						<S.TableValueColumn $isDisabled>{item?.korMemberNm || '-'}</S.TableValueColumn>
						<S.TableLabelColumn
							rowSpan={2}
							style={{
								verticalAlign: 'top',
							}}>
							주소
						</S.TableLabelColumn>
						<S.TableValueColumn style={{ verticalAlign: 'middle' }} rowSpan={2}>
							<CRInput.Address
								onChangeBaseAddress={handleChangeBaseAddress}
								onChangeDetailAddress={handleChangeDetailAddress}
								baseAddress={watch('basAddr')}
								detailAddress={watch('detailAddr')}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>주민등록번호</S.TableLabelColumn>
						<S.TableLabelColumn>
							<CRRsdNumber rdsNumber={item?.rsdnNo} textColor='gray60' key={item?.rsdnNo} />
						</S.TableLabelColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>
							생년월일 <S.RequiredMark>*</S.RequiredMark>
						</S.TableLabelColumn>
						<S.TableValueColumn $isDisabled>
							{item?.birthDt ? displayBirthDay(item?.birthDt) : '-'}
						</S.TableValueColumn>
						<S.TableLabelColumn>직무</S.TableLabelColumn>
						<S.TableValueColumn
							style={{
								verticalAlign: 'middle',
							}}>
							{item?.dutyNm}
							<S.RightButtonContainer>
								<CRButton.Default
									size='xSmall'
									palette='gray'
									type='outlined'
									onClick={handleClickFullTimeEmployeeDutyHistory}>
									변경/이력
								</CRButton.Default>
							</S.RightButtonContainer>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>
							입사일 <S.RequiredMark>*</S.RequiredMark>
						</S.TableLabelColumn>
						<S.TableValueColumn $isDisabled>
							{item?.joinDate ? dayjs(item.joinDate).format('YYYY.MM.DD') : '-'}
						</S.TableValueColumn>
						<S.TableLabelColumn>파트 구분</S.TableLabelColumn>
						<S.TableValueColumn $isDisabled>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBoxGroup
										ref={ref}
										checkType='single'
										type='radio'
										gap={0.8}
										onChange={(option) => onChange(option?.[0]?.value)}
										value={[{ label: '', value }]}
										options={options.partType}
										disabled
									/>
								)}
								name='partDivCd'
								control={control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>퇴사일</S.TableLabelColumn>
						<S.TableValueColumn $isDisabled>
							{item?.resignDate ? dayjs(item.resignDate).format('YYYY.MM.DD') : '-'}
						</S.TableValueColumn>
						<S.TableLabelColumn>근무 형태</S.TableLabelColumn>
						<S.TableValueColumn $isDisabled>{item?.workFormNm ?? '-'}</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>
							전화번호 <S.RequiredMark>*</S.RequiredMark>
						</S.TableLabelColumn>
						<S.TableValueColumn>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<>
										<CRInput.TableInput
											ref={ref}
											type='number'
											onBlur={onBlur}
											status={errors?.mobilePhoneNo?.message ? 'error' : 'default'}
											onChange={onChange}
											value={value}
											maxLength={11}
											placeholder='전화번호 입력'
										/>
										{errors.mobilePhoneNo && (
											<CRInputMessage type='error'>
												{errors.mobilePhoneNo?.message ?? ''}
											</CRInputMessage>
										)}
									</>
								)}
								name='mobilePhoneNo'
								control={control}
							/>
						</S.TableValueColumn>
						<S.TableLabelColumn>업무 대행자</S.TableLabelColumn>
						<S.TableValueColumn
							style={{
								verticalAlign: 'middle',
							}}>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRInput.Selector
										items={(fullEmployees ?? [])
											.filter((employee) => employee.employeeId !== currentEmployee?.employeeId)
											.map((manager) => ({
												label: manager.korMemberNm,
												value: manager.employeeId,
											}))}
										ref={ref}
										type='small'
										onChangeValue={(item) => onChange(item.value)}
										currentValue={{ label: '', value }}
										placeholder='업무대행자 선택'
									/>
								)}
								name='workExecuteActingEmployeeId'
								control={control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>범죄경력 조회</S.TableLabelColumn>
						<S.TableValueColumn
							style={!item?.crimeHistSearchDt ? { color: Colors.red } : undefined}>
							{item?.crimeHistSearchDt
								? dayjs(item.crimeHistSearchDt).format('YYYY.MM.DD')
								: '미조회'}
							<S.RightButtonContainer>
								<CRButton.Default
									disabled={!hasConfirmCrimeHistoryFunc}
									size='xSmall'
									palette='gray'
									type='outlined'
									onClick={handleClickFullTimeEmployeeCriminalRecord}>
									조회 확인
								</CRButton.Default>
							</S.RightButtonContainer>
						</S.TableValueColumn>
						<S.TableLabelColumn>장애여부</S.TableLabelColumn>
						<S.TableValueColumn>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRInput.Selector
										items={options.disability}
										ref={ref}
										type='small'
										status={errors?.disabilityGradeCd?.message ? 'error' : 'default'}
										onChangeValue={(item) => onChange(item.value)}
										currentValue={{ label: '', value: value ?? 'CMN149.30' }}
										placeholder='장애코드 선택'
									/>
								)}
								name='disabilityGradeCd'
								control={control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>건강 검진일</S.TableLabelColumn>
						<S.TableValueColumn>
							{item?.healthCheckDate ? dayjs(item.healthCheckDate).format('YYYY.MM.DD') : '-'}
							<S.RightButtonContainer>
								<CRButton.Default
									size='xSmall'
									palette='gray'
									type='outlined'
									onClick={handleClickHealthCheckupHistory}>
									변경/이력
								</CRButton.Default>
							</S.RightButtonContainer>
						</S.TableValueColumn>
						<S.TableLabelColumn
							rowSpan={3}
							style={{
								verticalAlign: 'top',
							}}>
							비고
						</S.TableLabelColumn>
						<S.TableValueColumn style={{ verticalAlign: 'middle' }} rowSpan={2}>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRInput.TextArea
										typography='label'
										ref={ref}
										onBlur={onBlur}
										status={errors?.employeeSpecialDesc?.message ? 'error' : 'default'}
										onChange={onChange}
										value={value}
										placeholder='비고 입력'
										numberOfLines={3}
									/>
								)}
								name='employeeSpecialDesc'
								control={control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>투폰/투번호</S.TableLabelColumn>
						<S.TableValueColumn
							style={{
								verticalAlign: 'middle',
							}}>
							<Controller
								render={({ field: { onBlur, ref, value, onChange }, formState: { errors } }) => (
									<CRCheckBoxGroup
										checkType='single'
										type='radio'
										gap={0.8}
										onChange={(option) => onChange(option[0].value)}
										value={[{ label: '', value }]}
										options={[
											{
												label: '없음',
												value: false,
											},
											{
												label: '있음',
												value: true,
											},
										]}
									/>
								)}
								name='twoPhoneYn'
								control={control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>투폰 전화번호</S.TableLabelColumn>
						<S.TableValueColumn>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<>
										<CRInput.TableInput
											disabled={!watch('twoPhoneYn')}
											ref={ref}
											type='number'
											onBlur={onBlur}
											maxLength={11}
											status={errors?.subMobilePhoneNo?.message ? 'error' : 'default'}
											onChange={onChange}
											value={value}
											placeholder='투폰 번호 입력'
										/>
										{errors.subMobilePhoneNo && (
											<CRInputMessage type='error'>
												{errors.subMobilePhoneNo?.message ?? ''}
											</CRInputMessage>
										)}
									</>
								)}
								name='subMobilePhoneNo'
								control={control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
				</S.Table>
			</S.Form>
		</S.Container>
	);
}
