import React, { useState } from 'react';

import dayjs from 'dayjs';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRTableMonthSelector from 'components/base/CRTableMonthSelector';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import InformationLayout from 'components/domain/layout/InformationLayout';
import { useMyAccountInfo, usePcorpWorkSchedule } from 'lib/hook/react-query';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { pcorpWorkScheduleTranfer } from 'lib/util/xlsx/pcorpWorkSchduleTranfer';
import { MyAccountInfoDTO } from 'types/api';
import { EmployeeWorkInfoViewType } from 'types/view/employee';

import LeftLayoutContent from './LeftLayoutContent';
import RightLayoutContent from './RightLayoutContent';
import * as S from './styles';

function WorkScheduleManagementPage() {
	const hasDownloadWorkingHistoryExcelFunc = useHasFunc([
		'employee:download_wroking_history_excel',
	]);
	const [date, setDate] = useState(new Date());
	const [currentEmployee, setCurrentEmployee] = useState<EmployeeWorkInfoViewType>();

	const { data: accountInfo } = useMyAccountInfo();
	const { refetch: refetchPcorpWorkSchedule } = usePcorpWorkSchedule({
		centerId: accountInfo?.centerId as number,
		yearMonth: dayjs(date).format('YYYYMM') as string,
	});

	const handleChangeDate = (newDate: Date) => {
		setDate(newDate);
	};

	const handleClickDetailItem = (item: EmployeeWorkInfoViewType) => {
		setCurrentEmployee(item);
	};

	const downloadPcorpWorkScheduleExcel = async () => {
		const { data: currentPcorpScheduleData } = await refetchPcorpWorkSchedule();
		if (!currentPcorpScheduleData || currentPcorpScheduleData.length <= 0)
			Toast.error('등록된 근무일정이 없습니다.');
		else pcorpWorkScheduleTranfer('근무일정표 공단용', currentPcorpScheduleData);
	};

	const downloadList = [
		{
			labelText: '공단연동 엑셀',
			disabled: !hasDownloadWorkingHistoryExcelFunc,
			onClick: downloadPcorpWorkScheduleExcel,
		},
	];

	return (
		<S.Container>
			<S.BreadCrumbContainer>
				<CRText typography='h3'>근무 및 출퇴근관리</CRText>
			</S.BreadCrumbContainer>
			<S.ContentContainer>
				<S.ContentBackground>
					<S.ContentHeaderContainer>
						<CRTableMonthSelector value={date} onChange={handleChangeDate} />
						<S.ButtonContainer>
							{downloadList.map((item) => (
								<CRButton.IconButton
									key={`${item.labelText}`}
									palette='gray'
									type='outlined'
									disabled={item.disabled}
									iconLeft={Assets.icon.fileDownload}
									style={{ gap: '0.8rem', padding: '0 1.6rem' }}
									onClick={item.onClick}>
									<CRText text={item.labelText} padding='0 0.6rem 0 0' />
								</CRButton.IconButton>
							))}
						</S.ButtonContainer>
					</S.ContentHeaderContainer>
					<S.InformationContainer>
						<InformationLayout
							LeftContentComponent={
								<LeftLayoutContent
									date={dayjs(date)}
									centerId={accountInfo?.centerId as number}
									handleClickEmployeeItem={handleClickDetailItem}
									currentEmployee={currentEmployee}
								/>
							}
							RightContentComponent={
								<RightLayoutContent date={dayjs(date)} currentEmployee={currentEmployee} />
							}
							containerCustomStyle={{ height: '100%', padding: '2.4rem 0' }}
							LeftContentCustomStyle={{ border: '1px solid #E1E3E3', borderRadius: '0.8rem' }}
							RightContentCustomStyle={{ border: '1px solid #E1E3E3', borderRadius: '0.8rem' }}
						/>
					</S.InformationContainer>
				</S.ContentBackground>
			</S.ContentContainer>
		</S.Container>
	);
}

export default WorkScheduleManagementPage;
