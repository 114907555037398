import React, { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';

import RouterPath from 'common/router';
import CRSyncMenu from 'components/base/CRSyncMenu';
import CRTab from 'components/base/CRTab';
import { TabProps } from 'components/base/CRTab/DetailTab/type';
import { Toast } from 'components/base/CRToast';
import { SyncDialog } from 'components/domain/dialog/SyncDialog';
import VisitScheduleUploadHistoryDetailDialog from 'components/domain/dialog/VisitScheduleUploadHistoryDetailDialog';
import VisitScheduleUploadHistoryDialog from 'components/domain/dialog/VisitScheduleUploadHistoryDialog';
import {
	useAutomationLastSync,
	useCenterList,
	useMyAccountInfo,
	useSendSlack,
	useSyncRoundingManagerRFIDTag,
	useUpdateAutomationUploadList,
} from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import { endpoint } from 'lib/service/Api/endpoint';
import { ResponseCode } from 'types/api';
import { RoundingScheduleUploadHistory } from 'types/view/roundingHistory';

import RoundingHistoryTOFamilyCareTab from './RoundingHistoryTOFamilyCareTab';
import RoundingHistoryTOVisitBathTab from './RoundingHistoryTOVisitBathTab';
import RoundingHistoryTOVisitCareTab from './RoundingHistoryTOVisitCareTab';

function RoundingHistoryTOPage(): React.ReactElement {
	const hasSyncVisitScheduleFunc = useHasFunc(['schedule:sync_visit_schedule']);
	const roundingHistoryToTab = useMemo(() => RouterPath.roundingHistoryToTab(), []);
	const [tab, setCurrentTab] = useState<string>(roundingHistoryToTab.visitCareRoundingHistory.key);
	const [isTempSync, setIsTempSync] = useState(false);
	const [date, setDate] = useState(new Date());

	const { showDialog, hideDialog } = useDialog();
	const hasPermission = useHasPermission('센터장');
	const { data: myAccountInfo } = useMyAccountInfo();
	const centerId = myAccountInfo?.centerId || 0;
	const { data: lastSyncData } = useAutomationLastSync({
		centerId,
		uploadTypeCds: ['CMN107.MANAGER_RECIPIENT_M'],
	});
	const { data: centerData } = useCenterList();

	const { mutate: sendSlack } = useSendSlack();
	const { mutate: syncRoundingManagerRFIDTag } = useSyncRoundingManagerRFIDTag(
		(client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				setIsTempSync(true);
				Toast.success(
					'방문일정 동기화 요청을 하였습니다. 잠시 뒤 자동화 업데이트 후 반영될 예정입니다.',
				);
			} else {
				Toast.error('방문일정 동기화 요청에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		},
	);

	const { mutate: updateAutomationUploadHist } = useUpdateAutomationUploadList(
		(client, returnData) => {
			client.invalidateQueries([endpoint.getAutomationLastSync.key]);
			client.invalidateQueries([endpoint.getAutomationUploadList.key]);
		},
	);

	const syncRoundingSchedule = async (data: { yearMonth: Date }) => {
		if (!centerId) {
			return;
		}

		const YYYYMM = dayjs(data.yearMonth).format('YYYYMM');
		syncRoundingManagerRFIDTag({ centerId, serviceYm: YYYYMM, uploadHistId: 0 });
		hideDialog();
	};

	const handleClickSyncButton = () => {
		showDialog(() => <SyncDialog title='방문일정' onSync={syncRoundingSchedule} />);
	};

	const handleClickUploadHistoryDetail = (item: RoundingScheduleUploadHistory) => {
		showDialog(({ hideDialog }) => (
			<VisitScheduleUploadHistoryDetailDialog item={item} onClickClose={hideDialog} />
		));
	};

	const handleClickSyncHistory = () => {
		showDialog(({ hideDialog }) => (
			<VisitScheduleUploadHistoryDialog
				centerId={myAccountInfo?.centerId ?? 0}
				onClickClose={hideDialog}
				onClickItem={handleClickUploadHistoryDetail}
			/>
		));
	};

	const lastSyncDate = lastSyncData
		? dayjs(lastSyncData?.uploadStartDate).format('YYYY.MM.DD HH:mm')
		: '';

	const tabItem = useMemo(() => {
		if (!centerData) return [{} as TabProps];
		if (
			centerData &&
			centerData.find((item) => item.centerId === centerId)?.centerTypeCd === 'CMN011.20'
		)
			return [
				{
					label: roundingHistoryToTab.familyCareRoundingHistory.label,
					key: roundingHistoryToTab.familyCareRoundingHistory.key,
					children: (
						<RoundingHistoryTOFamilyCareTab
							targetDate={date}
							handleTargetDate={setDate}
							lastSyncDate={lastSyncDate}
						/>
					),
				},
				{} as TabProps,
			];

		return [
			{
				label: roundingHistoryToTab.visitCareRoundingHistory.label,
				key: roundingHistoryToTab.visitCareRoundingHistory.key,
				children: (
					<RoundingHistoryTOVisitCareTab
						targetDate={date}
						handleTargetDate={setDate}
						lastSyncDate={lastSyncDate}
					/>
				),
			},
			{
				label: roundingHistoryToTab.familyCareRoundingHistory.label,
				key: roundingHistoryToTab.familyCareRoundingHistory.key,
				children: (
					<RoundingHistoryTOFamilyCareTab
						targetDate={date}
						handleTargetDate={setDate}
						lastSyncDate={lastSyncDate}
					/>
				),
			},
			{
				label: roundingHistoryToTab.visitBathRoundingHistory.label,
				key: roundingHistoryToTab.visitBathRoundingHistory.key,
				children: (
					<RoundingHistoryTOVisitBathTab
						targetDate={date}
						handleTargetDate={setDate}
						lastSyncDate={lastSyncDate}
					/>
				),
			},
		];
	}, [date, setDate, lastSyncDate, centerData]);

	useEffect(() => {
		if (
			lastSyncData &&
			lastSyncData.uploadHistId &&
			(lastSyncData.uploadStateCd === 'CMN108.REQUEST' ||
				lastSyncData.uploadStateCd === 'CMN108.PROGRESS')
		) {
			setIsTempSync(true);
			const minuteDiff = dayjs().diff(dayjs(lastSyncData.uploadStartDate), 'minute');

			if (minuteDiff >= 10) {
				updateAutomationUploadHist({ uploadHistId: lastSyncData.uploadHistId });
				sendSlack({
					message: `사회복지사 방문일정 동기화 자동화 이슈 발생! 확인 필요합니다.`,
				});
				setIsTempSync(false);
			}
		} else {
			setIsTempSync(false);
		}
	}, [lastSyncData]);

	return (
		<CRTab.Default
			defaultActiveKey={tab}
			items={tabItem}
			onChange={(tab) => setCurrentTab(tab.key)}
			breadCrumb='방문내역 관리'
			renderRightButton={
				<CRSyncMenu
					syncDisabled={!hasSyncVisitScheduleFunc}
					showSyncButton={hasPermission}
					title={isTempSync ? '방문일정 동기화 중' : '방문일정 동기화'}
					isProgress={isTempSync}
					onClickSync={handleClickSyncButton}
					onClickSyncHistory={handleClickSyncHistory}
				/>
			}
		/>
	);
}

export default RoundingHistoryTOPage;
