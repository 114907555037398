import { styled } from 'styled-components';

export const Container = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	padding: 2.4rem;
	box-sizing: border-box;
	overflow: hidden;
	min-width: 154rem;
`;

export const TableContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	background: ${(props) => props.theme.colors.gray100};
`;

export const TableScrollContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow: auto;
	background: ${(props) => props.theme.colors.gray100};
`;

export const TableHeaderContainer = styled.div`
	padding: 0 2.4rem;
`;

export const EmptyContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.8rem;
	flex: 1;
	height: 100%;
`;

export const EmptyTitle = styled.h3`
	${(props) => props.theme.typography.h3};
	color: ${(props) => props.theme.colors.gray60};
	white-space: pre-wrap;
`;

export const EmptyDescription = styled.p`
	${(props) => props.theme.typography.body};
	text-align: center;
	color: ${(props) => props.theme.colors.gray60};
`;
