import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';

import CRSpinner from 'components/base/CRSpinner';
import CRTable from 'components/base/CRTable';
import CRTableHeader from 'components/base/CRTableHeader';
import { defaultPageInfo } from 'components/base/CRTableHeader/constant';
import CRTableMonthSelector from 'components/base/CRTableMonthSelector';
import { CheckOption } from 'components/base/Selections/type';
import TagErrorTable from 'components/domain/table/TagErrorTable';
import {
	useAutomationLastSync,
	useCenterManagers,
	useCommonCodes,
	useMyAccountInfo,
	useScheduleTagErrorHistory,
} from 'lib/hook/react-query';
import usePageFilter from 'lib/hook/util/usePageFilter';
import { PageInfo } from 'types/view/base';
import { LastESignStateCd } from 'types/view/eDoc';
import { Filter } from 'types/view/filter';

import * as S from './styles';

interface Props {
	date: Date;
	onChangeDate: (date: Date) => void;
}

export default function TagErrorTab({ date, onChangeDate }: Props) {
	const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPageInfo);
	const [keyword, setKeyword] = useState('');
	const { filters: currentFilter, setFilters: setCurrentFilter } = usePageFilter({});
	const [searchValue, setSearchValue] = useState('');
	const { data: commonCodes } = useCommonCodes({ comCdGroupNms: ['CMN006', 'CMN171'] });
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: centerManagers } = useCenterManagers({
		centerId: myAccountInfo?.centerId,
	});

	const { data: lastSyncData } = useAutomationLastSync({
		centerId: myAccountInfo?.centerId,
		uploadTypeCds: ['CMN107.RFID'],
		uploadTargetYm: dayjs(date).format('YYYYMM'),
	});

	const {
		data: tagErrorHistory,
		isLoading: tagErrorHistoryLoading,
		refetch,
	} = useScheduleTagErrorHistory({
		page: pageInfo.page,
		size: pageInfo.size,
		startServiceYmd: dayjs(date).startOf('M').format('YYYYMMDD'),
		endServiceYmd: dayjs(date).endOf('M').format('YYYYMMDD'),
		serviceTypeCd: currentFilter?.serviceTypeFilter?.map((item) => item?.value),
		tagErrYn: currentFilter?.tagErrorFilter?.map((item) => item?.value),
		sendRecordProcess: currentFilter?.sendRecordProcessFilter?.map((item) => item?.value),
		managerId: currentFilter?.managerFilter?.map((item) => item?.value),
		centerId: myAccountInfo?.centerId ? [myAccountInfo.centerId] : undefined,
		keyword,
	});

	const handleSearch = () => {
		setPageInfo((prev) => ({
			...prev,
			page: 1,
		}));
		setKeyword(searchValue);
	};
	const handleChangePageInfo = (pageInfo: PageInfo) => {
		setPageInfo(pageInfo);
	};

	const handleChangeDate = (date: Date) => {
		onChangeDate(date);
		setPageInfo((prev) => ({
			...prev,
			page: 1,
		}));
	};

	const serviceFilter = useMemo(
		() =>
			({
				key: 'serviceTypeFilter',
				type: 'multi',
				placeholder: '급여종류',
				options: (commonCodes || [])
					?.filter((item) => item.comCdGroupNm === 'CMN006' && item.etcDesc1 === 'Y')
					?.map((code) => ({
						label: code.korComCdAliasNm,
						value: code.comCdId,
					})),
			}) as Filter<CheckOption>,
		[commonCodes],
	);

	const errorStatusFilter = useMemo(
		() =>
			({
				key: 'tagErrorFilter',
				type: 'multi',
				placeholder: '오류여부',
				options: [
					{
						label: '오류',
						value: true,
					},
					{
						label: '정상',
						value: false,
					},
				],
			}) as Filter<CheckOption>,
		[commonCodes],
	);

	const sendRecordProcessFilter = useMemo(
		() =>
			({
				key: 'sendRecordProcessFilter',
				type: 'multi',
				placeholder: '기록지 작성 여부',
				options: [
					{
						label: '미완료',
						value: (commonCodes || [])
							// CMN171.30성공
							.filter(
								(item) => item.comCdGroupNm === 'CMN171' && item.comCdId !== LastESignStateCd.완료,
							)
							.map((item) => item.comCdId),
					},
					{
						label: '완료',
						value: [LastESignStateCd.완료],
					},
				],
			}) as Filter<CheckOption>,
		[commonCodes],
	);

	const managerFilter = useMemo(
		() =>
			({
				key: 'managerFilter',
				type: 'multi',
				options:
					centerManagers?.map((item) => ({
						label: item.userNm,
						value: item.memberAccountId,
					})) ?? [],
				placeholder: '사회복지사',
			}) as Filter<CheckOption>,
		[centerManagers],
	);

	const renderEmpty = useMemo(
		() => (
			<S.EmptyContainer>
				<S.EmptyTitle>태그 내역이 없습니다.</S.EmptyTitle>
				<S.EmptyDescription>
					해당 월 일정이 아직 동기화되지 않았습니다.
					<br />
					동기화 버튼을 눌러주세요.
				</S.EmptyDescription>
			</S.EmptyContainer>
		),
		[],
	);

	const handleSearchValue = (value: string) => setSearchValue(value);

	const tagErrorHistoryData = useMemo(() => tagErrorHistory?.content ?? [], [tagErrorHistory]);

	const handleRefresh = () => {
		refetch();
	};

	useEffect(() => {
		if (tagErrorHistory?.totalPages !== undefined) {
			setPageInfo((prev) => ({
				...prev,
				totalPages: tagErrorHistory?.totalPages || 0,
			}));
		}
	}, [tagErrorHistory?.totalPages]);

	// 사회복지사 기본필터를 자신으로 설정
	useLayoutEffect(() => {
		if (myAccountInfo && myAccountInfo?.positionCd === 'CMN103.10') {
			setCurrentFilter((prev) => {
				const alreadyManagerExist = Boolean(
					prev?.managerFilter?.find((manager) => manager.value === myAccountInfo.memberAccountId),
				);
				if (alreadyManagerExist) {
					return prev;
				}
				return {
					...prev,
					managerFilter: [
						...(prev?.managerFilter || []),
						{ value: myAccountInfo.memberAccountId, label: myAccountInfo.userNm },
					],
				};
			});
		}
	}, [myAccountInfo]);

	return (
		<CRTable.BackBlind>
			<S.HeaderContainer>
				<CRTableMonthSelector value={date} onChange={handleChangeDate} />
				<S.SyncTime>
					{lastSyncData?.uploadStartDate
						? `${dayjs(lastSyncData?.uploadStartDate).format('YYYY.MM.DD HH:mm')} 동기화`
						: ''}
					{}
				</S.SyncTime>
			</S.HeaderContainer>
			<S.TableContainer>
				<CRTableHeader
					onChangePageInfo={handleChangePageInfo}
					pageInfo={pageInfo}
					currentFilter={currentFilter}
					stickyMode
					filters={[serviceFilter, errorStatusFilter, sendRecordProcessFilter, managerFilter]}
					searchValue={searchValue}
					placeholder='수급자명으로 검색'
					onChangeSearchValue={handleSearchValue}
					setCurrentFilter={setCurrentFilter}
					onSearch={handleSearch}
					onRefresh={handleRefresh}
					showRefresh
					showViewCount
				/>
				<S.TableScrollContainer>
					{tagErrorHistoryLoading ? (
						<CRSpinner />
					) : tagErrorHistoryData && tagErrorHistoryData?.length > 0 ? (
						<TagErrorTable items={tagErrorHistoryData} onRefresh={handleRefresh} />
					) : (
						renderEmpty
					)}
				</S.TableScrollContainer>
			</S.TableContainer>
		</CRTable.BackBlind>
	);
}
