import React, { ChangeEvent } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { IntegratedAssessmentDesireForms } from 'types/view/recipient';

import CRCheckBox from '../../../base/Selections/CRCheckBox';
import IntegratedAssessmentDesireNursingCareStatusFormHeader from './IntegratedAssessmentDesireNursingCareStatusFormHeader';
import { integratedAssessmentDesireNursingCareStatusQuestions } from './constant';
import * as S from './styles';

interface Props {
	form: UseFormReturn<IntegratedAssessmentDesireForms>;
	showGenerateRequiredFields?: boolean;
	enabled?: boolean;
}

function IntegratedAssessmentDesireNursingCareStatusForm({
	form,
	showGenerateRequiredFields,
	enabled,
}: Props): React.ReactElement {
	return (
		<S.Container>
			<S.Table>
				<IntegratedAssessmentDesireNursingCareStatusFormHeader />
				<S.TableBody>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireNursingCareStatusQuestions[0];
							return (
								<S.TableBodyRow>
									<S.TableBodyColumn>{item.label}</S.TableBodyColumn>
									<S.TableBodyColumn colSpan={2}>
										<S.TableContentVerticalContainer>
											<S.TableContentContainer>
												{(item.answer ?? []).map((answer) => (
													<>
														{answer.disabled ? (
															answer.label
														) : (
															<CRCheckBox
																disabled={!enabled}
																checkBoxType='checkbox'
																checked={form.watch(item.key)?.includes(answer.value)}
																onChange={(event: ChangeEvent<HTMLInputElement>) => {
																	const targetValue = value as string[];

																	if (event.target.checked) {
																		onChange([...(targetValue ?? []), answer.value]);
																	} else {
																		onChange(
																			(targetValue ?? []).filter((item) => item !== answer.value),
																		);
																	}
																}}>
																{answer.label}
															</CRCheckBox>
														)}
														{answer.options && (
															<>
																(
																{answer.options?.map((option) => (
																	<CRCheckBox
																		disabled={!enabled}
																		checkBoxType='checkbox'
																		checked={form.watch(item.key)?.includes(option.value)}
																		onChange={(event: ChangeEvent<HTMLInputElement>) => {
																			const targetValue = value as string[];

																			if (event.target.checked) {
																				onChange([...(targetValue ?? []), option.value]);
																			} else {
																				onChange(
																					(targetValue ?? []).filter(
																						(item) => item !== option.value,
																					),
																				);
																			}
																		}}>
																		{option.label}
																	</CRCheckBox>
																))}
																)
															</>
														)}
													</>
												))}
											</S.TableContentContainer>
											{form
												.watch(integratedAssessmentDesireNursingCareStatusQuestions[0].key)
												?.includes('기타') && (
												<Controller
													render={({ field: { ref, onChange, value }, formState: { errors } }) => (
														<CRInput.TextArea
															ref={ref}
															disabled={!enabled}
															placeholder='기타 사항 입력'
															onChange={onChange}
															value={value as string}
														/>
													)}
													name='호흡기타'
													control={form.control}
												/>
											)}
										</S.TableContentVerticalContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireNursingCareStatusQuestions[0].key}
						control={form.control}
					/>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireNursingCareStatusQuestions[1];
							return (
								<S.TableBodyRow>
									<S.TableBodyColumn>{item.label}</S.TableBodyColumn>
									<S.TableBodyColumn colSpan={2}>
										<S.TableContentVerticalContainer>
											<S.TableContentContainer>
												{(item.answer ?? []).map((answer) => (
													<>
														{answer.disabled ? (
															answer.label
														) : (
															<CRCheckBox
																disabled={!enabled}
																checkBoxType='checkbox'
																checked={form.watch(item.key)?.includes(answer.value)}
																onChange={(event: ChangeEvent<HTMLInputElement>) => {
																	const targetValue = value as string[];

																	if (event.target.checked) {
																		onChange([...(targetValue ?? []), answer.value]);
																	} else {
																		onChange(
																			(targetValue ?? []).filter((item) => item !== answer.value),
																		);
																	}
																}}>
																{answer.label}
															</CRCheckBox>
														)}
														{answer.options && (
															<>
																(
																{answer.options?.map((option) => (
																	<CRCheckBox
																		disabled={!enabled}
																		checkBoxType='checkbox'
																		checked={form.watch(item.key)?.includes(option.value)}
																		onChange={(event: ChangeEvent<HTMLInputElement>) => {
																			const targetValue = value as string[];

																			if (event.target.checked) {
																				onChange([...(targetValue ?? []), option.value]);
																			} else {
																				onChange(
																					(targetValue ?? []).filter(
																						(item) => item !== option.value,
																					),
																				);
																			}
																		}}>
																		{option.label}
																	</CRCheckBox>
																))}
																)
															</>
														)}
													</>
												))}
											</S.TableContentContainer>
											{form
												.watch(integratedAssessmentDesireNursingCareStatusQuestions[1].key)
												?.includes('기타') && (
												<Controller
													render={({ field: { ref, onChange, value }, formState: { errors } }) => (
														<CRInput.TextArea
															ref={ref}
															disabled={!enabled}
															placeholder='기타 사항 입력'
															onChange={onChange}
															value={value as string}
														/>
													)}
													name='영양기타'
													control={form.control}
												/>
											)}
										</S.TableContentVerticalContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireNursingCareStatusQuestions[1].key}
						control={form.control}
					/>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireNursingCareStatusQuestions[2];
							return (
								<S.TableBodyRow>
									<S.TableBodyColumn>{item.label}</S.TableBodyColumn>
									<S.TableBodyColumn colSpan={2}>
										<S.TableContentRowContainer>
											{(item.answer ?? []).map((answer) => (
												<>
													{answer.disabled ? (
														answer.label
													) : (
														<CRCheckBox
															disabled={!enabled}
															checkBoxType='checkbox'
															checked={form.watch(item.key)?.includes(answer.value)}
															onChange={(event: ChangeEvent<HTMLInputElement>) => {
																const targetValue = value as string[];

																if (event.target.checked) {
																	onChange([...(targetValue ?? []), answer.value]);
																} else {
																	onChange(
																		(targetValue ?? []).filter((item) => item !== answer.value),
																	);
																}
															}}>
															{answer.label}
														</CRCheckBox>
													)}
													{answer.options && (
														<>
															(
															{answer.options?.map((option) => (
																<CRCheckBox
																	disabled={!enabled}
																	checkBoxType='checkbox'
																	checked={form.watch(item.key)?.includes(option.value)}
																	onChange={(event: ChangeEvent<HTMLInputElement>) => {
																		const targetValue = value as string[];

																		if (event.target.checked) {
																			onChange([...(targetValue ?? []), option.value]);
																		} else {
																			onChange(
																				(targetValue ?? []).filter((item) => item !== option.value),
																			);
																		}
																	}}>
																	{option.label}
																</CRCheckBox>
															))}
															)
														</>
													)}
												</>
											))}
										</S.TableContentRowContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireNursingCareStatusQuestions[2].key}
						control={form.control}
					/>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireNursingCareStatusQuestions[3];
							return (
								<S.TableBodyRow>
									<S.TableBodyColumn>{item.label}</S.TableBodyColumn>
									<S.TableBodyColumn colSpan={2}>
										<S.TableContentVerticalContainer>
											<S.TableContentContainer>
												{(item.answer ?? []).map((answer) => (
													<>
														{answer.disabled ? (
															answer.label
														) : (
															<CRCheckBox
																disabled={!enabled}
																checkBoxType='checkbox'
																checked={form.watch(item.key)?.includes(answer.value)}
																onChange={(event: ChangeEvent<HTMLInputElement>) => {
																	const targetValue = value as string[];

																	if (event.target.checked) {
																		onChange([...(targetValue ?? []), answer.value]);
																	} else {
																		onChange(
																			(targetValue ?? []).filter((item) => item !== answer.value),
																		);
																	}
																}}>
																{answer.label}
															</CRCheckBox>
														)}
														{answer.options && (
															<>
																(
																{answer.options?.map((option) => (
																	<CRCheckBox
																		disabled={!enabled}
																		checkBoxType='checkbox'
																		checked={form.watch(item.key)?.includes(option.value)}
																		onChange={(event: ChangeEvent<HTMLInputElement>) => {
																			const targetValue = value as string[];

																			if (event.target.checked) {
																				onChange([...(targetValue ?? []), option.value]);
																			} else {
																				onChange(
																					(targetValue ?? []).filter(
																						(item) => item !== option.value,
																					),
																				);
																			}
																		}}>
																		{option.label}
																	</CRCheckBox>
																))}
																)
															</>
														)}
													</>
												))}
											</S.TableContentContainer>
											{form
												.watch(integratedAssessmentDesireNursingCareStatusQuestions[3].key)
												?.includes('기타') && (
												<Controller
													render={({ field: { ref, onChange, value }, formState: { errors } }) => (
														<CRInput.TextArea
															ref={ref}
															disabled={!enabled}
															placeholder='기타 사항 입력'
															onChange={onChange}
															value={value as string}
														/>
													)}
													name='상처기타'
													control={form.control}
												/>
											)}
										</S.TableContentVerticalContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireNursingCareStatusQuestions[3].key}
						control={form.control}
					/>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireNursingCareStatusQuestions[4];
							return (
								<S.TableBodyRow>
									<S.TableBodyColumn rowSpan={2}>욕창</S.TableBodyColumn>
									<S.TableBodyColumn>{item.label}</S.TableBodyColumn>
									<S.TableBodyColumn>
										<S.TableContentVerticalContainer>
											<S.TableContentContainer>
												{(item.answer ?? []).map((answer) => (
													<>
														{answer.disabled ? (
															answer.label
														) : (
															<CRCheckBox
																disabled={!enabled}
																checkBoxType='checkbox'
																checked={form.watch(item.key)?.includes(answer.value)}
																onChange={(event: ChangeEvent<HTMLInputElement>) => {
																	const targetValue = value as string[];

																	if (event.target.checked) {
																		onChange([...(targetValue ?? []), answer.value]);
																	} else {
																		onChange(
																			(targetValue ?? []).filter((item) => item !== answer.value),
																		);
																	}
																}}>
																{answer.label}
															</CRCheckBox>
														)}
														{answer.options && (
															<>
																(
																{answer.options?.map((option) => (
																	<CRCheckBox
																		disabled={!enabled}
																		checkBoxType='checkbox'
																		checked={form.watch(item.key)?.includes(option.value)}
																		onChange={(event: ChangeEvent<HTMLInputElement>) => {
																			const targetValue = value as string[];

																			if (event.target.checked) {
																				onChange([...(targetValue ?? []), option.value]);
																			} else {
																				onChange(
																					(targetValue ?? []).filter(
																						(item) => item !== option.value,
																					),
																				);
																			}
																		}}>
																		{option.label}
																	</CRCheckBox>
																))}
																)
															</>
														)}
													</>
												))}
											</S.TableContentContainer>
										</S.TableContentVerticalContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireNursingCareStatusQuestions[4].key}
						control={form.control}
					/>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireNursingCareStatusQuestions[5];
							return (
								<S.TableBodyRow>
									<S.TableBodyColumn>{item.label}</S.TableBodyColumn>
									<S.TableBodyColumn>
										<S.TableContentVerticalContainer>
											<S.TableContentContainer>
												{(item.answer ?? []).map((answer) => (
													<>
														{answer.disabled ? (
															answer.label
														) : (
															<CRCheckBox
																disabled={!enabled}
																checkBoxType='checkbox'
																checked={form.watch(item.key)?.includes(answer.value)}
																onChange={(event: ChangeEvent<HTMLInputElement>) => {
																	const targetValue = value as string[];

																	if (event.target.checked) {
																		onChange([...(targetValue ?? []), answer.value]);
																	} else {
																		onChange(
																			(targetValue ?? []).filter((item) => item !== answer.value),
																		);
																	}
																}}>
																{answer.label}
															</CRCheckBox>
														)}
														{answer.options && (
															<>
																(
																{answer.options?.map((option) => (
																	<CRCheckBox
																		disabled={!enabled}
																		checkBoxType='checkbox'
																		checked={form.watch(item.key)?.includes(option.value)}
																		onChange={(event: ChangeEvent<HTMLInputElement>) => {
																			const targetValue = value as string[];

																			if (event.target.checked) {
																				onChange([...(targetValue ?? []), option.value]);
																			} else {
																				onChange(
																					(targetValue ?? []).filter(
																						(item) => item !== option.value,
																					),
																				);
																			}
																		}}>
																		{option.label}
																	</CRCheckBox>
																))}
																)
															</>
														)}
													</>
												))}
											</S.TableContentContainer>
											{form
												.watch(integratedAssessmentDesireNursingCareStatusQuestions[5].key)
												?.includes('기타') && (
												<Controller
													render={({ field: { ref, onChange, value }, formState: { errors } }) => (
														<CRInput.TextArea
															ref={ref}
															disabled={!enabled}
															placeholder='기타 사항 입력'
															onChange={onChange}
															value={value as string}
														/>
													)}
													name='욕창부위기타'
													control={form.control}
												/>
											)}
										</S.TableContentVerticalContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireNursingCareStatusQuestions[5].key}
						control={form.control}
					/>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireNursingCareStatusQuestions[6];
							return (
								<S.TableBodyRow>
									<S.TableBodyColumn rowSpan={2}>통증</S.TableBodyColumn>
									<S.TableBodyColumn>{item.label}</S.TableBodyColumn>
									<S.TableBodyColumn>
										<S.TableContentVerticalContainer>
											<S.TableContentContainer>
												{(item.answer ?? []).map((answer) => (
													<>
														{answer.disabled ? (
															answer.label
														) : (
															<CRCheckBox
																disabled={!enabled}
																checkBoxType='checkbox'
																checked={form.watch(item.key)?.includes(answer.value)}
																onChange={(event: ChangeEvent<HTMLInputElement>) => {
																	const targetValue = value as string[];

																	if (event.target.checked) {
																		onChange([...(targetValue ?? []), answer.value]);
																	} else {
																		onChange(
																			(targetValue ?? []).filter((item) => item !== answer.value),
																		);
																	}
																}}>
																{answer.label}
															</CRCheckBox>
														)}
														{answer.options && (
															<>
																(
																{answer.options?.map((option) => (
																	<CRCheckBox
																		disabled={!enabled}
																		checkBoxType='checkbox'
																		checked={form.watch(item.key)?.includes(option.value)}
																		onChange={(event: ChangeEvent<HTMLInputElement>) => {
																			const targetValue = value as string[];

																			if (event.target.checked) {
																				onChange([...(targetValue ?? []), option.value]);
																			} else {
																				onChange(
																					(targetValue ?? []).filter(
																						(item) => item !== option.value,
																					),
																				);
																			}
																		}}>
																		{option.label}
																	</CRCheckBox>
																))}
																)
															</>
														)}
													</>
												))}
											</S.TableContentContainer>
											{form
												.watch(integratedAssessmentDesireNursingCareStatusQuestions[6].key)
												?.includes('기타') && (
												<Controller
													render={({ field: { ref, onChange, value }, formState: { errors } }) => (
														<CRInput.TextArea
															ref={ref}
															disabled={!enabled}
															placeholder='기타 사항 입력'
															onChange={onChange}
															value={value as string}
														/>
													)}
													name='암발생부위기타'
													control={form.control}
												/>
											)}
										</S.TableContentVerticalContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireNursingCareStatusQuestions[6].key}
						control={form.control}
					/>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireNursingCareStatusQuestions[7];
							return (
								<S.TableBodyRow>
									<S.TableBodyColumn>{item.label}</S.TableBodyColumn>
									<S.TableBodyColumn>
										<S.TableContentVerticalContainer>
											<S.TableContentContainer>
												{(item.answer ?? []).map((answer) => (
													<>
														{answer.disabled ? (
															answer.label
														) : (
															<CRCheckBox
																disabled={!enabled}
																checkBoxType='checkbox'
																checked={form.watch(item.key)?.includes(answer.value)}
																onChange={(event: ChangeEvent<HTMLInputElement>) => {
																	const targetValue = value as string[];

																	if (event.target.checked) {
																		onChange([...(targetValue ?? []), answer.value]);
																	} else {
																		onChange(
																			(targetValue ?? []).filter((item) => item !== answer.value),
																		);
																	}
																}}>
																{answer.label}
															</CRCheckBox>
														)}
														{answer.options && (
															<>
																(
																{answer.options?.map((option) => (
																	<CRCheckBox
																		disabled={!enabled}
																		checkBoxType='checkbox'
																		checked={form.watch(item.key)?.includes(option.value)}
																		onChange={(event: ChangeEvent<HTMLInputElement>) => {
																			const targetValue = value as string[];

																			if (event.target.checked) {
																				onChange([...(targetValue ?? []), option.value]);
																			} else {
																				onChange(
																					(targetValue ?? []).filter(
																						(item) => item !== option.value,
																					),
																				);
																			}
																		}}>
																		{option.label}
																	</CRCheckBox>
																))}
																)
															</>
														)}
													</>
												))}
											</S.TableContentContainer>
											{form
												.watch(integratedAssessmentDesireNursingCareStatusQuestions[7].key)
												?.includes('기타') && (
												<Controller
													render={({ field: { ref, onChange, value }, formState: { errors } }) => (
														<CRInput.TextArea
															ref={ref}
															disabled={!enabled}
															placeholder='기타 사항 입력'
															onChange={onChange}
															value={value as string}
														/>
													)}
													name='일반통증부위기타'
													control={form.control}
												/>
											)}
										</S.TableContentVerticalContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireNursingCareStatusQuestions[7].key}
						control={form.control}
					/>
				</S.TableBody>
			</S.Table>
			<Controller
				render={({ field: { ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='판단 근거' isRequired={showGenerateRequiredFields}>
						<CRInput.TextArea
							ref={ref}
							disabled={!enabled}
							placeholder='판단 근거 입력'
							onChange={onChange}
							value={value}
						/>
					</CRInputLabel>
				)}
				name='간호처치상태판단근거'
				control={form.control}
			/>
		</S.Container>
	);
}

export default IntegratedAssessmentDesireNursingCareStatusForm;
