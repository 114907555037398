import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import CRSpinner from 'components/base/CRSpinner';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import FullTimeEmployeeCenterChangeDialog from 'components/domain/dialog/FullTimeEmployeeCenterChangeDialog';
import FullTimeEmployeeBasicInfoFormView from 'components/domain/form/FullTimeEmployeeBasicInfoFormView';
import FullTimeEmployeeBasicStatusFormView from 'components/domain/form/FullTimeEmployeeBasicStatusFormView';
import FullTimeEmployeeTOFormView from 'components/domain/form/FullTimeEmployeeTOFormView';
import FullTimeTrainingFormView from 'components/domain/form/FullTimeTrainingFormView';
import useFullTimeEmployeePage from 'lib/hook/full-time-employee/useFullTimeEmployeePage';
import {
	useCancelMovingFullTimeEmployeeCenter,
	useCheckFullTimeEmployeeDeletable,
	useCommonCodes,
	useDeleteFullTimeEmployee,
	useMyAccountInfo,
} from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import { endpoint } from 'lib/service/Api/endpoint';
import { ResponseCode } from 'types/api';
import { DeleteFullTimeEmployeeRequest } from 'types/api/full-time-employee';

import * as S from './styles';

export default function FullTimeEmployeeBasicTab(): React.ReactElement {
	const hasWithdrawFullTimeEmployeeFunc = useHasFunc([
		'full_time_employee:withdraw_full_time_employee',
	]);
	const hasDeleteFullTimeEmployeeFunc = useHasFunc([
		'full_time_employee:delete_full_time_employee',
	]);
	const hasChangeFullTimeEmployeeCenterFunc = useHasFunc([
		'full_time_employee:change_full_time_employee_center',
	]);
	const { currentEmployee, employeeDetailData, employeeDetailLoading } = useFullTimeEmployeePage();
	const navigate = useNavigate();
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: checkFullTimeEmployeeDeletable } = useCheckFullTimeEmployeeDeletable({
		centerId: myAccountInfo?.centerId,
		employeeId: currentEmployee?.employeeId,
		memberId: currentEmployee?.memberId,
	});
	const cancelMovingFullTimeEmployeeCenter = useCancelMovingFullTimeEmployeeCenter((client) => {
		client.invalidateQueries([
			endpoint.getFullTimeEmployeeDetailInfo.key,
			{
				centerId: myAccountInfo?.centerId,
				employeeId: currentEmployee?.employeeId,
				memberId: currentEmployee?.memberId,
			},
		]);
	});
	const { showDialog, hideDialog } = useDialog();

	const deleteFullTimeEmployee = useDeleteFullTimeEmployee(async (client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			Toast.success('직원 정보를 정상적으로 삭제하였습니다.');
			hideDialog();
			navigate(`/${RouterPath.fullTimeEmployee().tab.uniqueKey}`);
		} else {
			hideDialog();
			Toast.error('직원 정보 삭제에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
	});

	const { data: commonCodes } = useCommonCodes({
		comCdGroupNms: ['CMN070', 'CMN068', 'CMN069', 'CMN149', 'CMN187'],
	});

	const options = useMemo(() => {
		const duty =
			commonCodes
				?.filter((e) => e.comCdGroupNm === 'CMN070' && e?.etcDesc1 === '1')
				?.map((option) => ({
					label: option.korComCdAliasNm,
					value: option.comCdId,
				})) ?? [];
		const workState =
			commonCodes
				?.filter((e) => e.comCdGroupNm === 'CMN068' && e.etcDesc1 === '1')
				?.map((option) => ({
					label: option.korComCdAliasNm,
					value: option.comCdId,
				})) ?? [];
		const workForm =
			commonCodes
				?.filter((e) => e.comCdGroupNm === 'CMN069')
				?.map((option) => ({
					label: option.korComCdAliasNm,
					value: option.comCdId,
				})) ?? [];
		const partType =
			commonCodes
				?.filter((e) => e.comCdGroupNm === 'CMN187')
				?.map((option) => ({
					label: option.korComCdAliasNm,
					value: option.comCdId,
				})) ?? [];
		const disability =
			commonCodes
				?.filter((e) => e.comCdGroupNm === 'CMN149')
				?.map((option) => ({
					label: option.korComCdAliasNm,
					value: option.comCdId,
				})) ?? [];

		return {
			duty,
			workState,
			workForm,
			partType,
			disability,
		};
	}, [commonCodes]);

	const handleNavigateResignPage = async () => {
		navigate(
			`/${RouterPath.fullTimeEmployee().tab.uniqueKey}/${currentEmployee?.employeeId}/resign`,
		);
	};

	const handleClickDeleteEmployee = () => {
		showDialog(() => (
			<DefaultDialog
				title='입사 취소 및 직원 정보 삭제'
				hideDialog={hideDialog}
				cancelOption={{
					text: '닫기',
				}}
				successOption={{
					text: '입사 취소 (삭제)',
					type: 'outlined',
					successCallback: async () => {
						if (!currentEmployee) return;
						const param: DeleteFullTimeEmployeeRequest = {
							employeeId: currentEmployee.employeeId,
							centerId: currentEmployee.centerId,
							memberId: currentEmployee.memberId,
						};

						await deleteFullTimeEmployee.mutateAsync(param);
					},
				}}
				content={
					<CRText>{`${currentEmployee?.korMemberNm}님의 입사를 취소하시겠습니까?\n취소 시 ${currentEmployee?.korMemberNm} 님의 직원 정보를 모두 삭제합니다.`}</CRText>
				}
			/>
		));
	};

	const handleClickChangeCenter = () => {
		showDialog(({ hideDialog }) => (
			<FullTimeEmployeeCenterChangeDialog
				currentFullTimeEmployee={currentEmployee}
				hideDialog={hideDialog}
			/>
		));
	};

	const handleClickCancelChangeCenter = () => {
		if (!employeeDetailData?.ftimeEmployeeCenterMoveHistId) return;

		showDialog(() => (
			<DefaultDialog
				hideDialog={hideDialog}
				title='센터 이동을 취소하시겠습니까?'
				content={
					<CRText>{`${dayjs(employeeDetailData?.centerMoveDate).format('YYYY.MM.DD')} ${
						employeeDetailData.afterCenterNm
					} 센터 이동을 취소합니다.`}</CRText>
				}
				cancelOption={{
					text: '닫기',
				}}
				successOption={{
					text: '취소',
					type: 'outlined',
					successCallback: async () => {
						cancelMovingFullTimeEmployeeCenter.mutateAsync({
							ftimeEmployeeCenterMoveHistId: employeeDetailData?.ftimeEmployeeCenterMoveHistId,
						});
						hideDialog();
					},
				}}
			/>
		));
	};

	const isResign = employeeDetailData?.workStateCd === 'CMN068.30';

	return (
		<S.Container>
			{employeeDetailLoading ? (
				<CRSpinner />
			) : (
				<>
					<FullTimeEmployeeBasicStatusFormView />
					<FullTimeEmployeeBasicInfoFormView options={options} item={employeeDetailData} />
					<FullTimeEmployeeTOFormView />
					<FullTimeTrainingFormView item={employeeDetailData} />
					<S.ButtonContainer>
						<S.LeftButtonContainer>
							{!isResign && (
								<S.WithdrawalButtonContainer>
									<CRButton.Default
										disabled={!hasWithdrawFullTimeEmployeeFunc}
										type='outlined'
										onClick={handleNavigateResignPage}>
										퇴사
									</CRButton.Default>
								</S.WithdrawalButtonContainer>
							)}
							{!isResign && (
								<CRButton.Default
									type='tonal'
									palette='gray'
									onClick={handleClickDeleteEmployee}
									disabled={
										!checkFullTimeEmployeeDeletable?.employeeId || !hasDeleteFullTimeEmployeeFunc
									}>
									입사 취소(삭제)
								</CRButton.Default>
							)}
						</S.LeftButtonContainer>
						{!isResign && (
							<div>
								{employeeDetailData?.ftimeEmployeeCenterMoveHistId ? (
									<CRButton.Default
										disabled={!hasChangeFullTimeEmployeeCenterFunc}
										type='outlined'
										onClick={handleClickCancelChangeCenter}>
										{`센터 이동 취소 (${dayjs(employeeDetailData?.centerMoveDate).format(
											'YYYY.MM.DD',
										)} ${employeeDetailData.afterCenterNm})`}
									</CRButton.Default>
								) : (
									<CRButton.Default
										disabled={!hasChangeFullTimeEmployeeCenterFunc}
										type='outlined'
										palette='gray'
										onClick={handleClickChangeCenter}>
										센터 이동
									</CRButton.Default>
								)}
							</div>
						)}
					</S.ButtonContainer>
				</>
			)}
		</S.Container>
	);
}
