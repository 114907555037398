import React, { ChangeEvent, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import * as Accordion from '@radix-ui/react-accordion';

import Assets from 'assets';
import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import CRFilterGroup from 'components/base/CRInput/CRFilterGroup';
import CRTab from 'components/base/CRTab';
import { TaskTabProps } from 'components/base/CRTab/TaskTab/type';
import { CRText } from 'components/base/CRText';
import FullTimeEmployeeEnrollDialog from 'components/domain/dialog/FullTimeEmployeeEnrollDialog';
import InformationLayout from 'components/domain/layout/InformationLayout';
import FullTimeEmployeeDetailBasicInformationTable from 'components/domain/table/FullTimeEmployeeDetailBasicInformationTable';
import FullTimeEmployeeListTable from 'components/domain/table/FullTimeEmployeeListTable';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import useFullTimeEmployeePage from 'lib/hook/full-time-employee/useFullTimeEmployeePage';
import { useMyAccountInfo } from 'lib/hook/react-query';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { FullTimeEmployeeDTO } from 'types/dto/full-time-employee';

import { MY_TAB_CONFIG, TAB_CONFIG } from './config';
import * as S from './styles';

function FullTimeEmployeePage(): React.ReactElement {
	const hasCreateFullTimeEmployeeFunc = useHasFunc([
		'full_time_employee:create_full_time_employee',
	]);
	const { breadCrumbs, showDialog } = useGlobalLayout();
	const navigate = useNavigate();
	const hasReadFullTimeEmployeeFunc = useHasFunc(['full_time_employee:read_full_time_employee']);
	const hasReadFullTimeEmployeeOwnFunc = useHasFunc([
		'full_time_employee:read_full_time_employee:basic@own',
	]);

	const { data: myAccountInfo } = useMyAccountInfo();
	const {
		currentTab,
		employees,
		currentEmployee,
		currentEmployeeBase,
		setCurrentEmployee,
		searchText,
		setSearchText,
		currentFilter,
		setCurrentFilter,
		stateFilter,
		dutyFilter,
		setEditForm,
		cancelChangeEditSection,
	} = useFullTimeEmployeePage();

	const readable =
		hasReadFullTimeEmployeeFunc ||
		(hasReadFullTimeEmployeeOwnFunc && currentEmployee?.userId === myAccountInfo?.userId);

	const handleClickTab = (tab: TaskTabProps) => {
		if (currentEmployee?.userId === myAccountInfo?.userId || hasCreateFullTimeEmployeeFunc) {
			navigate(
				`/${RouterPath.fullTimeEmployee().tab.uniqueKey}/${currentEmployee?.employeeId}/${tab.key}`,
			);
		} else {
			navigate(
				`/${RouterPath.fullTimeEmployee().tab.uniqueKey}/${currentEmployee?.employeeId}/schedule`,
			);
		}
	};

	const handleClickDetailItem = (item: FullTimeEmployeeDTO) => {
		setCurrentEmployee(item);
		setEditForm(undefined);
		cancelChangeEditSection?.();
		if (hasCreateFullTimeEmployeeFunc) {
			navigate(
				`/${RouterPath.fullTimeEmployee().tab.uniqueKey}/${item?.employeeId}/${
					currentTab ?? 'basic'
				}`,
			);
			return;
		}
		if (item?.userId === myAccountInfo?.userId) {
			navigate(`/${RouterPath.fullTimeEmployee().tab.uniqueKey}/${item?.employeeId}/basic`);
			return;
		}
		navigate(`/${RouterPath.fullTimeEmployee().tab.uniqueKey}/${item?.employeeId}/schedule`);
	};

	const handleChangeSearchText = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchText(event.target.value);
	};

	const handleClickEmployeeEnroll = () => {
		const onSuccess = (employeeId?: number) => {
			if (!employeeId) return;
			navigate(`/${RouterPath.fullTimeEmployee().tab.uniqueKey}/${employeeId}/basic?isNew=true`);
		};
		showDialog(({ hideDialog }) => (
			<FullTimeEmployeeEnrollDialog hideDialog={hideDialog} onSuccess={onSuccess} />
		));
	};

	const LeftContentComponent = useMemo(
		() => (
			<S.Container>
				<S.Header>
					<S.Title>직원 목록</S.Title>
					{hasCreateFullTimeEmployeeFunc && (
						<CRButton.IconButton
							iconLeft={Assets.icon.add}
							type='filled'
							palette='primary'
							size='xSmall'
							onClick={handleClickEmployeeEnroll}>
							직원 추가
						</CRButton.IconButton>
					)}
				</S.Header>
				<CRInput.Search
					placeholder='직원명, 생년월일, 전화번호 검색'
					value={searchText}
					onChange={handleChangeSearchText}
				/>
				<CRFilterGroup
					filters={[stateFilter, dutyFilter]}
					currentFilter={currentFilter}
					setCurrentFilter={setCurrentFilter}
				/>
				<S.TableContainer>
					<FullTimeEmployeeListTable
						items={employees}
						searchValue={searchText}
						onClickRow={handleClickDetailItem}
						currentItem={currentEmployee}
					/>
				</S.TableContainer>
				<CRText text={`총 ${employees?.length}명`} />
			</S.Container>
		),
		[currentFilter, currentEmployee, searchText, dutyFilter, stateFilter, currentTab],
	);

	const RightContentComponent = useMemo(
		() => (
			<S.Container>
				<S.RightTableContainer>
					<Accordion.Root
						type='multiple'
						style={{
							width: '100%',
							marginBottom: 0,
						}}
						defaultValue={['기본 정보']}>
						<Accordion.Item value='기본 정보' asChild>
							<TaskAccordion.Item
								style={{
									marginBottom: 0,
								}}>
								<Accordion.Header asChild>
									<Accordion.Trigger asChild>
										<TaskAccordion.Trigger
											style={{
												padding: 0,
											}}>
											직원 정보
										</TaskAccordion.Trigger>
									</Accordion.Trigger>
								</Accordion.Header>
								<Accordion.Content asChild>
									<TaskAccordion.Content
										style={{
											borderTop: 'none',
											padding: '0.8rem 0',
										}}>
										<FullTimeEmployeeDetailBasicInformationTable item={currentEmployeeBase} />
									</TaskAccordion.Content>
								</Accordion.Content>
							</TaskAccordion.Item>
						</Accordion.Item>
					</Accordion.Root>
				</S.RightTableContainer>
				<S.RightTabContainer>
					<CRTab.Task
						items={readable ? MY_TAB_CONFIG : TAB_CONFIG}
						defaultActiveKey={currentTab}
						onChange={handleClickTab}
					/>
					<S.RightTabViewContainer>
						<S.TabView>
							<Outlet />
						</S.TabView>
					</S.RightTabViewContainer>
				</S.RightTabContainer>
			</S.Container>
		),
		[currentTab, handleClickTab, currentEmployeeBase],
	);

	if (breadCrumbs.length > 1) {
		return <Outlet />;
	}

	return (
		<InformationLayout
			LeftContentComponent={LeftContentComponent}
			RightContentComponent={RightContentComponent}
		/>
	);
}

export default FullTimeEmployeePage;
