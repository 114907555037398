/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRSpinner from 'components/base/CRSpinner';
import CRTable from 'components/base/CRTable';
import CRTableHeader from 'components/base/CRTableHeader';
import { CRText } from 'components/base/CRText';
import { CheckOption } from 'components/base/Selections/type';
import CenterAccountChildDetailDialog from 'components/domain/dialog/CenterAccountChildDetailDialog';
import CenterAccountDetailDialog from 'components/domain/dialog/CenterAccountDetailDialog';
import CenterAccountRecordSummarysDialog from 'components/domain/dialog/CenterAccountRecordSummarysDialog';
import CenterAccountTable from 'components/domain/table/CenterAccountTable';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes } from 'lib/hook/react-query';
import { useUpdateCenterAccountRecords } from 'lib/hook/react-query/mutation/centerAccount';
import {
	useCenterAccountRecords,
	useCenterAccounts,
} from 'lib/hook/react-query/query/centerAccount';
import useDialog from 'lib/hook/util/useDialog';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import usePageFilter from 'lib/hook/util/usePageFilter';
import { ResponseCode } from 'types/api';
import {
	CenterAccountRecordData,
	GetCenterAccountRecordsDownloadRequest,
} from 'types/api/centerAccount';
import { PageInfo } from 'types/view/base';
import { Filter } from 'types/view/filter';

import * as S from './styles';

interface FormFields {
	recordDt: {
		start: Date | null;
		end: Date | null;
	};
	centerAccountIds: CheckOption[];
}

interface Props {
	onShowExcelDownload: (show: boolean) => void;
	onChangeExcelDownloadParam: (param: GetCenterAccountRecordsDownloadRequest) => void;
}

function CenterAccountTab({
	onShowExcelDownload,
	onChangeExcelDownloadParam,
}: Props): React.ReactElement {
	const hasUpdateOwnExpenseFunc = useHasFunc(
		['center:update_bank_account_detail'],
		['center:update_bank_account_detail:own_expense'],
	);
	const { control, watch, handleSubmit } = useForm<FormFields>({
		defaultValues: {
			recordDt: undefined,
			centerAccountIds: [],
		},
	});

	const { showDialog } = useDialog();
	const {
		filters: currentFilter,
		setFilters: setCurrentFilter,
		pageInfo,
		setPageInfo,
	} = usePageFilter({});

	const [keyword, setKeyword] = useState('');
	const [searchValue, setSearchValue] = useState('');

	const recordDt = watch('recordDt');
	const centerAccountIds = watch('centerAccountIds');

	const centerAccounts = useCenterAccounts();

	const { data: commonCodes = {} } = useCommonCodes(
		{
			comCdGroupNms: ['CMN035'],
		},
		commonCodeAdapter,
	);

	const hasReadBankAccountDetailFunc = useHasFunc(['center:read_bank_account_detail']);

	/**
	 * updateCenterAccount와 centerAccountRecords는 순차적으로 요청된다.
	 * updateCenterAccount로 계좌내역을 갱신하고 centerAccountRecords로 갱신된 내용을 받아온다.
	 */
	const updateCenterAccount = useUpdateCenterAccountRecords();
	const centerAccountRecords = useCenterAccountRecords({
		centerAccountIds: updateCenterAccount.data?.data?.centerAccountIds,
		recordEndDt: updateCenterAccount.data?.data?.recordEndDt,
		recordStartDt: updateCenterAccount.data?.data?.recordStartDt,
		depositTypeCds: currentFilter?.depositTypeCds?.map((type) => type.value) || [],
		withdrawTypeCds: currentFilter?.withdrawTypeCds?.map((type) => type.value) || [],
		recordKindNms: currentFilter?.recordKindNms?.map((type) => type.value) || [],
		size: pageInfo.size,
		page: pageInfo.page,
		keyword,
	});

	useEffect(() => {
		if (centerAccountRecords?.data?.totalPages !== undefined) {
			setPageInfo((prev) => ({
				...prev,
				totalPages: centerAccountRecords?.data?.totalPages || 0,
			}));
		}
	}, [centerAccountRecords?.data?.totalPages]);

	const handleClickRow = (item: CenterAccountRecordData) => {
		const hasPermission = item.centerAccountTypeCd === 'CMN208.10' ? hasUpdateOwnExpenseFunc : true;
		if (!hasPermission) return;
		showDialog(({ hideDialog }) => (
			<CenterAccountDetailDialog accountDetail={item} onClose={hideDialog} />
		));
	};

	const handleClickRowDetail = (idx: number, item: CenterAccountRecordData) => () => {
		const hasPermission = item.centerAccountTypeCd === 'CMN208.10' ? hasUpdateOwnExpenseFunc : true;
		if (!hasPermission) return;
		const filtedDetailInfo = {
			...item,
			centerAccountRecordDetails: item.centerAccountRecordDetails.filter(
				(_, index) => index === idx,
			),
		};
		showDialog(({ hideDialog }) => (
			<CenterAccountChildDetailDialog accountDetail={filtedDetailInfo} onClose={hideDialog} />
		));
	};

	const handleClickOpenRecordSummary = () => {
		const dialogProps = updateCenterAccount?.data?.data;
		if (!dialogProps) return;

		showDialog(({ hideDialog }) => (
			<CenterAccountRecordSummarysDialog
				centerAccountIds={dialogProps.centerAccountIds}
				recordEndDt={dialogProps.recordEndDt}
				recordStartDt={dialogProps.recordStartDt}
				onClose={hideDialog}
			/>
		));
	};

	const recordKindNmsFilter = useMemo(
		() =>
			({
				key: 'recordKindNms',
				type: 'multi',
				options: [
					{
						label: '입금',
						value: '입금',
					},
					{
						label: '출금',
						value: '출금',
					},
				],
				placeholder: '종류',
			}) as Filter<CheckOption>,
		[],
	);

	const depositTypeFilter = useMemo(
		() =>
			({
				key: 'depositTypeCds',
				type: 'multi',
				options: commonCodes.CMN035?.filter((val) => val.data?.etcDesc1 === '입금') ?? [],
				placeholder: '입금 유형',
			}) as Filter<CheckOption>,
		[commonCodes],
	);

	const withdrawTypeFilter = useMemo(
		() =>
			({
				key: 'withdrawTypeCds',
				type: 'multi',
				options: commonCodes.CMN035?.filter((val) => val.data?.etcDesc1 === '출금') ?? [],
				placeholder: '출금 유형',
			}) as Filter<CheckOption>,
		[commonCodes],
	);

	const centerAccountOptions = useMemo(
		() =>
			(centerAccounts?.data || []).map((account) => ({
				label: account.value,
				value: account.id,
				data: account,
			})),
		[centerAccounts?.data],
	);

	const handleChangeSearchValue = (value: string) => {
		setSearchValue(value);
	};

	const handleSearch = () => {
		setPageInfo((prev) => ({
			...prev,
			page: 1,
		}));
		setKeyword(searchValue);
	};

	const handleChangePageInfo = (pageInfo: PageInfo) => {
		setPageInfo(pageInfo);
	};

	/**
	 *
	 * @param recordKindNms
	 * 유형의 타입이 한가지만 선택되었을때 유형을 문자열로 뱉어줌
	 * @returns
	 */
	const getRecordKindNmsSingleValue = (
		recordKindNms: CheckOption[],
	): undefined | '입금' | '출금' => {
		if (recordKindNms?.length === 0) return undefined;
		if (recordKindNms?.length === 2) return undefined;
		if (recordKindNms?.length === 1) {
			return recordKindNms?.[0]?.value;
		}
		return undefined;
	};

	const onFilterChange = (data: {
		recordKindNms: CheckOption[];
		depositTypeCds: CheckOption[];
		withdrawTypeCds: CheckOption[];
	}) => {
		const recordKindNmsSingleValue = getRecordKindNmsSingleValue(data.recordKindNms);

		if (recordKindNmsSingleValue === '입금') {
			setCurrentFilter({
				...data,
				withdrawTypeCds: [],
			});
			return;
		}
		if (recordKindNmsSingleValue === '출금') {
			setCurrentFilter({
				...data,
				depositTypeCds: [],
			});
			return;
		}

		setCurrentFilter(data);
	};

	const getFilterList = (filter: any) => {
		const recordKindNmsSingleValue = getRecordKindNmsSingleValue(filter.recordKindNms);
		if (recordKindNmsSingleValue === '입금') {
			return [recordKindNmsFilter, depositTypeFilter];
		}
		if (recordKindNmsSingleValue === '출금') {
			return [recordKindNmsFilter, depositTypeFilter];
		}

		return [recordKindNmsFilter, depositTypeFilter, withdrawTypeFilter];
	};

	const updateAccountRecords = (data: FormFields) => {
		const recordEndDt = dayjs(data.recordDt?.end).format('YYYYMMDD');
		const recordStartDt = dayjs(data.recordDt?.start).format('YYYYMMDD');
		updateCenterAccount.mutateAsync({
			centerAccountIds: data.centerAccountIds.map((account) => Number(account.value)),
			recordEndDt,
			recordStartDt,
		});
	};

	useEffect(() => {
		const isShowExcelDownload = !!centerAccountRecords?.data?.content?.length;
		onShowExcelDownload(isShowExcelDownload);
		if (isShowExcelDownload && recordDt?.start && recordDt?.end && centerAccountIds.length) {
			const excelDownloadParam: GetCenterAccountRecordsDownloadRequest = {
				recordStartDt: dayjs(recordDt.start).format('YYYYMMDD'),
				recordEndDt: dayjs(recordDt.end).format('YYYYMMDD'),
				centerAccountIds: centerAccountIds.map((account) => Number(account.value)),
				depositTypeCds: currentFilter?.depositTypeCds?.map((type) => type.value) || [],
				withdrawTypeCds: currentFilter?.withdrawTypeCds?.map((type) => type.value) || [],
				recordKindNms: currentFilter?.recordKindNms?.map((type) => type.value) || [],
				keyword,
			};
			onChangeExcelDownloadParam(excelDownloadParam);
		}
	}, [
		centerAccountRecords?.data?.content?.length,
		currentFilter,
		recordDt,
		centerAccountIds,
		keyword,
	]);

	const isLoadable =
		recordDt?.end && recordDt?.start && centerAccountIds.length > 0 && hasReadBankAccountDetailFunc;

	const isAllDataLoading = updateCenterAccount.isLoading && centerAccountRecords.isLoading;
	const isAccountUpdateSuccess = updateCenterAccount?.data?.code === ResponseCode.SUCCESS;
	const isAccountUpdateFail =
		updateCenterAccount?.data?.code && updateCenterAccount?.data?.code !== ResponseCode.SUCCESS;
	const shouldUpdateRecord = updateCenterAccount.data === undefined;

	const isSummaryAvailable = Boolean(
		updateCenterAccount?.data?.data?.centerAccountIds?.length &&
			updateCenterAccount?.data?.data?.recordEndDt &&
			updateCenterAccount?.data?.data?.recordStartDt,
	);

	return (
		<>
			<FlexContainer
				width='100%'
				justify='space-between'
				align='flex-end'
				padding='2.4rem 2.4rem 0 2.4rem'>
				<FlexContainer width='88rem' gap='1rem' align='flex-end'>
					<S.InputLabelContainer>
						<CRInputLabel label='기간' isRequired>
							<Controller
								control={control}
								name='recordDt'
								render={({ field: { onChange, value, ...fields } }) => (
									<CRInput.DateRangePicker
										{...fields}
										value={value}
										selected={dayjs().subtract(12, 'month').toDate()}
										monthsShown={13}
										maxDate={dayjs().toDate()}
										placeholder='기간 선택'
										onChangeValue={onChange}
										scrollToNowMonth
									/>
								)}
							/>
						</CRInputLabel>
					</S.InputLabelContainer>
					<S.InputLabelContainer>
						<CRInputLabel label='계좌명' isRequired>
							<Controller
								control={control}
								name='centerAccountIds'
								render={({ field: { onChange, value, ...fields } }) => (
									<CRInput.MultiSelector
										{...fields}
										showAllSelect
										placeholder='계좌명 선택'
										value={value}
										onChange={onChange}
										options={centerAccountOptions}
									/>
								)}
							/>
						</CRInputLabel>
					</S.InputLabelContainer>

					{isAllDataLoading ? (
						<CRButton.Default
							style={{ borderRadius: '5.6rem' }}
							size='large'
							onClick={handleSubmit(updateAccountRecords)}
							disabled>
							<FlexContainer gap='0.8rem' width='10rem' align='center'>
								<CRSpinner floatMode={false} />
								<CRText text='불러오는 중' color='gray60' />
							</FlexContainer>
						</CRButton.Default>
					) : (
						<CRButton.Default
							style={{ borderRadius: '5.6rem' }}
							size='large'
							onClick={handleSubmit(updateAccountRecords)}
							disabled={!isLoadable}>
							불러오기
						</CRButton.Default>
					)}
				</FlexContainer>
				{isSummaryAvailable && (
					<CRButton.Default
						style={{ borderRadius: '5.6rem' }}
						size='large'
						onClick={handleClickOpenRecordSummary}
						palette='gray'
						type='outlined'>
						요약
					</CRButton.Default>
				)}
			</FlexContainer>
			{/* 초기화면. 기간, 계좌를 선택하지 않으면 문구 노출 */}
			{shouldUpdateRecord && !updateCenterAccount.isLoading && (
				<FlexContainer
					direction='column'
					width='100%'
					height='65rem'
					align='center'
					justify='center'>
					<CRText typography='h3' text='기간과 계좌명을 선택해 주십시오.' color='gray60' />
					<CRText color='gray60' text='기간과 계좌명을 전부 선택해 주셔야' />
					<CRText color='gray60' text='센터 계좌를 불러올 수 있습니다.' />
				</FlexContainer>
			)}
			{/* 계좌 내역 업데이트중 */}
			{updateCenterAccount.isLoading && (
				<CRSpinner containerStyle={{ height: '70rem' }} floatMode={false} />
			)}
			{/* 계좌 내역 업데이트 성공 후 UI */}
			{/* 업데이트 된 계좌 내역을 보여줄 테이블 렌더링. */}
			{isAccountUpdateSuccess && (
				<S.ContentWrapper>
					<CRTable.BackBlind>
						<CRTableHeader
							searchValue={searchValue}
							onChangeSearchValue={handleChangeSearchValue}
							currentFilter={currentFilter}
							setCurrentFilter={onFilterChange}
							filters={getFilterList(currentFilter)}
							onSearch={handleSearch}
							onRefresh={centerAccountRecords.refetch}
							onChangePageInfo={handleChangePageInfo}
							placeholder='입금처/출금처, 메모 검색'
							pageInfo={pageInfo}
							showViewCount
							stickyMode
							showRefresh
						/>
						{/* 계좌내역 불러오는중. */}
						{centerAccountRecords.isLoading ? (
							<CRSpinner containerStyle={{ height: '70rem' }} floatMode={false} />
						) : (
							<>
								{/* 데이터가 있다면 테이블에 노출. */}
								{centerAccountRecords?.data?.content?.length ? (
									<CenterAccountTable
										searchText={keyword}
										items={centerAccountRecords.data.content || []}
										onClickRow={handleClickRow}
										onClickRowDetail={handleClickRowDetail}
									/>
								) : (
									<FlexContainer
										direction='column'
										width='100%'
										height='65rem'
										align='center'
										justify='center'>
										<CRText typography='h3' text='센터 계좌 내역이 없습니다.' color='gray60' />
										<CRText color='gray60' text='조건을 수정해서 다시 검색해주세요' />
									</FlexContainer>
								)}
							</>
						)}
					</CRTable.BackBlind>
				</S.ContentWrapper>
			)}
			{/* 계좌 내역 업데이트 실패 후 UI */}
			{isAccountUpdateFail && (
				<FlexContainer
					direction='column'
					width='100%'
					height='65rem'
					align='center'
					justify='center'>
					<CRText typography='h3' text='센터계좌를 불러올 수 없습니다.' color='gray60' />
					<CRText color='gray60' text='잠시 후 다시 시도하거나' />
					<CRText color='gray60' text='관리자에게 요청해 주세요.' />
				</FlexContainer>
			)}
		</>
	);
}

export default CenterAccountTab;
