import { styled } from 'styled-components';

export const MainComponentContainer = styled.div<{ $hasFooter: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	overflow-y: scroll;
	height: ${(props) =>
		props.$hasFooter ? 'calc(100vh - 5.8rem - 8.6rem)' : 'calc(100vh - 5.8rem)'};
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 103.2rem;
	min-width: 80rem;
	/* margin: 0 auto; */
`;

export const FormContainer = styled.div`
	box-sizing: border-box;
	width: 100%;
	max-width: 103.2rem;
	min-width: 80rem;
`;

export const FooterContainer = styled.div`
	position: fixed;
	width: 100%;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	/* background: ${(props) => props.theme.colors.gray99}; */
`;

export const FooterContentContainer = styled.div`
	width: 100%;
	max-width: 108rem;
	min-width: 80rem;
	padding: 0rem 2.4rem;
`;
