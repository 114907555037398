import IApi from 'lib/service/Api/IApi';
import { MyFuncDTO } from 'types/dto';

export default class PermissionService {
	private static instance: PermissionService;

	private myFuncs: MyFuncDTO[] = [];

	static get shared() {
		if (!PermissionService.instance) {
			PermissionService.instance = new PermissionService();
		}

		return PermissionService.instance;
	}

	async init(api: IApi) {
		const result = await api.getMyFuncs();
		if (result) {
			this.myFuncs = result;
		}
	}

	clear() {
		this.myFuncs = [];
	}

	hasPermission = (allowFuncCd: string[], deniedFuncCd?: string[]): boolean =>
		this.myFuncs.some(
			(func) => func.applyRoleFuncPolicyNm === 'ALLOW' && allowFuncCd.includes(func.combinedFuncNm),
		) &&
		!this.myFuncs.some(
			(func) =>
				func.applyRoleFuncPolicyNm === 'DENY' && deniedFuncCd?.includes(func.combinedFuncNm),
		);
}
