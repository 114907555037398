import React, { useMemo } from 'react';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import InformationTable from 'components/ui/InformationTable';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import { FullTimeEmployeeAnnualTORemainAdjustDTO } from 'types/dto/full-time-employee';

import * as S from './styles';

interface Props {
	items: FullTimeEmployeeAnnualTORemainAdjustDTO[];
	onClickUpdate: (data: FullTimeEmployeeAnnualTORemainAdjustDTO) => void;
	onClickDelete: (data: FullTimeEmployeeAnnualTORemainAdjustDTO) => void;
}

export default function FullTimeEmployeeAnnualTODeductionTab({
	items = [],
	onClickUpdate,
	onClickDelete,
}: Props): React.ReactElement {
	const hasUpdateFullTimeEmployeeToHistory = useHasFunc([
		'full_time_employee:update_full_time_employee_to_history',
	]);
	const header = useMemo(
		(): InformationTableItemType[] =>
			[
				{
					type: 'label',
					label: '조정일',
					labelStyle: {
						width: '16rem',
					},
				},
				{
					type: 'label',
					label: '차감일수',
					labelStyle: {
						width: '10rem',
					},
				},
				{
					type: 'label',
					label: '조정 후 잔여 연차',
					labelStyle: {
						width: '16rem',
					},
				},
				{
					type: 'label',
					label: '사유',
					labelStyle: {
						width: '20rem',
					},
				},
				{
					type: 'label',
					label: '',
				},
			] as InformationTableItemType[],
		[],
	);

	return (
		<S.TabContainer>
			<InformationTable
				items={[
					header,
					...items.map(
						(item) =>
							[
								{
									type: 'value',
									value: dayjs(item.annualAdjustDate).format('YYYY.MM.DD'),
								},
								{
									type: 'value',
									value: item.adjustAnnualCntNm,
								},
								{
									type: 'value',
									value: item.afterAnnualDayCntNm,
								},
								{
									type: 'value',
									value: item.annualAdjustDesc,
								},
								{
									type: 'value',
									value: hasUpdateFullTimeEmployeeToHistory && (
										<S.ButtonContainer>
											<CRButton.Default
												size='xSmall'
												type='outlined'
												palette='gray'
												onClick={() => onClickUpdate(item)}>
												수정
											</CRButton.Default>
											<CRButton.Default
												size='xSmall'
												type='outlined'
												onClick={() => onClickDelete(item)}>
												삭제
											</CRButton.Default>
										</S.ButtonContainer>
									),
								},
							] as InformationTableItemType[],
					),
				]}
			/>
		</S.TabContainer>
	);
}
