import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import dayjs from 'dayjs';

import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import CRFileDownloader from 'components/base/CRInput/CRFileDownloader';
import CRTag from 'components/base/CRTag';
import { Toast } from 'components/base/CRToast';
import { displayPhoneNumber } from 'lib';
import { useDeleteAdvice, useEmployeeAdvice } from 'lib/hook/react-query';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { endpoint } from 'lib/service/Api/endpoint';

import * as S from './styles';

function EmployeeConsultingDetailPage(): React.ReactElement {
	const hasDeleteConsultantFunc = useHasFunc(['employee:delete_consultant']);
	const navigate = useNavigate();
	const params = useParams<{ adviceId: string; id: string }>();

	const { data } = useEmployeeAdvice({
		employeeId: Number.parseInt(params.id ?? '0', 10),
		adviceId: Number.parseInt(params.adviceId ?? '0', 10),
	});

	const deleteAdviceMutation = useDeleteAdvice((client) => {
		client.invalidateQueries([
			endpoint.getRecipientAdvices,
			{
				recipientId: params.id,
			},
		]);
	});

	const handleClickEditRecipientConsulting = () => {
		const recipientRouter = RouterPath.recipient();
		navigate(
			`/${recipientRouter.tab.uniqueKey}/${params.id}/${recipientRouter.consulting.uniqueKey}/${recipientRouter.consultingEdit.uniqueKey}/${params.adviceId}`,
		);
	};

	const handleClickDelete = async () => {
		const resultMessage = await deleteAdviceMutation.mutateAsync({
			adviceId: Number.parseInt(params.adviceId ?? '0', 10),
		});
		if (resultMessage) {
			Toast.success(resultMessage);
		}
		navigate(-1);
	};

	const fileList = data?.file?.fileDetails?.filter((val) => val.fileDeleteYn === false) || [];

	if (!data) return <div />;

	return (
		<S.Container>
			<S.BodyContainer>
				<S.ContentContainer>
					<S.HeaderContainer>
						<S.Title>상담상세</S.Title>
						<CRButton.Default
							disabled={!hasDeleteConsultantFunc}
							type='outlined'
							onClick={handleClickDelete}>
							삭제
						</CRButton.Default>
						<CRButton.Default
							palette='gray'
							type='outlined'
							onClick={handleClickEditRecipientConsulting}>
							편집
						</CRButton.Default>
					</S.HeaderContainer>
					<S.DetailContainer>
						<S.DetailHeaderContainer>
							<S.DetailTitleContainer>
								<S.ManagerText>{`${data.clientNm} (${data.clientDivNm})`}</S.ManagerText>
								<S.DateText>{`${dayjs(data.consultDate).format('YYYY.MM.DD HH:mm')}, ${
									data.methodNm
								}, ${displayPhoneNumber(data.telNum)}`}</S.DateText>
							</S.DetailTitleContainer>
							<S.DetailInformationRow>
								<S.DetailInformationKey>상담유형</S.DetailInformationKey>
								<S.DetailInformationValue>{data.tagGroupNm}</S.DetailInformationValue>
								<S.DetailInformationKey>사회복지사</S.DetailInformationKey>
								<S.DetailInformationValue>
									{`${data.centerNm} ${data.writerNm}`}
								</S.DetailInformationValue>
							</S.DetailInformationRow>
							<S.DetailInformationRow>
								<S.DetailInformationKey>태그</S.DetailInformationKey>
								<S.DetailInformationValue>
									<CRTag palette='gray'>{data.tagNm}</CRTag>
								</S.DetailInformationValue>
							</S.DetailInformationRow>
						</S.DetailHeaderContainer>
						<S.DetailBodyContainer>
							<S.DetailDescription>{data.consultDesc}</S.DetailDescription>
							<S.OpinionContainer>
								<S.OpinionTitle>사회복지사 의견</S.OpinionTitle>
								<S.OpinionDescription>{data.consultActionDesc}</S.OpinionDescription>
							</S.OpinionContainer>
						</S.DetailBodyContainer>
						<S.FileContainer>
							<CRFileDownloader items={fileList} />
						</S.FileContainer>
					</S.DetailContainer>
				</S.ContentContainer>
			</S.BodyContainer>
		</S.Container>
	);
}

export default EmployeeConsultingDetailPage;
