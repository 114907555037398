import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import Assets from 'assets';
import Colors from 'common/colors';
import CRButton from 'components/base/CRButton';
import CRCardFormLayout from 'components/base/CRCardFormLayout';
import CRIcon from 'components/base/CRIcon';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInputMessage from 'components/base/CRInputMessage';
import CRSpinner from 'components/base/CRSpinner';
import { Toast } from 'components/base/CRToast';
import { useCheckAccountOwner, useWageDeductionEmployees } from 'lib/hook/react-query';
import useContractChangeEmployeeTask from 'lib/hook/view/contract/useContractChangeEmployeeTask';
import { ResponseCode } from 'types/api';
import { CheckAccountOwnerData, CheckAccountOwnerRequest } from 'types/api/common';

import * as S from './styles';

function ChangeEmployeeContractOwnExpenseForm(): React.ReactElement {
	const { commonCodes, form, disabled } = useContractChangeEmployeeTask();
	const { data: wageDeductionEmployees } = useWageDeductionEmployees({
		centerId: form.getValues('contractBaseInfo.socialWorkerCenterId'),
		recipientId: form.getValues('contractBaseInfo.recipientId'),
	});
	const [isValidAccount, setIsValidAccount] = useState(false);

	const { mutate: checkAccountOwner, isLoading: checkAccountLoading } = useCheckAccountOwner(
		(client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS && returnData?.data) {
				const result: CheckAccountOwnerData = JSON.parse(returnData.data);
				if (result.data.OUTSTATCD === '0021') {
					setIsValidAccount(true);
					form?.setValue(`inspection.burdenAmt.burdenAmtAutotransAccountValidYn`, true);
					form?.clearErrors(`inspection.burdenAmt.burdenAmtAutotransAccountDepositorNm`);
				} else if (result.data.OUTSTATCD === '0031') {
					setIsValidAccount(false);
					form?.setError(`inspection.burdenAmt.burdenAmtAutotransAccountDepositorNm`, {
						message: result.data.OUTRSLTMSG,
					});
				} else {
					Toast.error('계좌 조회에 실패했습니다.');
				}
			}
		},
	);

	const hasAccountInfo = !!(
		form.watch(`inspection.burdenAmt.burdenAmtAutotransBankCd`) &&
		form.watch(`inspection.burdenAmt.burdenAmtAutotransAccountNo`) &&
		form.watch(`inspection.burdenAmt.burdenAmtAutotransAccountDepositorNm`)
	);

	const handleClickCheckAccount = () => {
		if (checkAccountLoading || !hasAccountInfo) return;
		const param: CheckAccountOwnerRequest = {
			acctNo: form.getValues(`inspection.burdenAmt.burdenAmtAutotransAccountNo`),
			bankCode: form.getValues(`inspection.burdenAmt.burdenAmtAutotransBankCd`),
			custNm: form.getValues(`inspection.burdenAmt.burdenAmtAutotransAccountDepositorNm`),
		};
		checkAccountOwner(param);
	};

	useEffect(() => {
		setIsValidAccount(form.getValues('inspection.burdenAmt.burdenAmtAutotransAccountValidYn'));
	}, [form.getValues('inspection.burdenAmt.burdenAmtAutotransAccountValidYn')]);

	return (
		<S.Container>
			<CRInputLabel label='납부 방식' isRequired type='left' showOverflow>
				<Controller
					name='inspection.burdenAmt.burdenAmtPayMethodCd'
					control={form?.control}
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRInput.Selector
							placeholder='납부 방식 선택'
							items={commonCodes?.CMN041}
							currentValue={value ? { label: '', value } : undefined}
							onChangeValue={(item) => {
								onChange(item.value);
								if (item.value !== 'CMN041.30') {
									form.setValue('inspection.burdenAmt.burdenAmtPayDeductEmployeeId', undefined);
								}
							}}
							disabled={disabled}
						/>
					)}
				/>
				{form.watch('inspection.burdenAmt.burdenAmtPayMethodCd') === 'CMN041.10' && (
					<CRCardFormLayout label='자동이체 정보'>
						<Controller
							name='inspection.burdenAmt.burdenAmtAutotransBankCd'
							control={form?.control}
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInputLabel label='은행명' isRequired type='left-sub' showOverflow>
									<CRInput.Selector
										placeholder='은행명 선택'
										items={commonCodes?.CMN037}
										currentValue={value ? { label: '', value } : undefined}
										onChangeValue={(item) => {
											onChange(item.value);
											form?.setValue(
												'inspection.burdenAmt.burdenAmtAutotransAccountValidYn',
												false,
											);
											setIsValidAccount(false);
										}}
										disabled={disabled}
									/>
								</CRInputLabel>
							)}
						/>
						<Controller
							name='inspection.burdenAmt.burdenAmtAutotransAccountNo'
							control={form?.control}
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInputLabel label='계좌번호' isRequired type='left-sub' showOverflow>
									<CRInput.Default
										placeholder='계좌번호 입력'
										value={value}
										onChange={(item) => {
											onChange(item);
											form?.setValue(
												'inspection.burdenAmt.burdenAmtAutotransAccountValidYn',
												false,
											);
											setIsValidAccount(false);
										}}
										disabled={disabled}
									/>
								</CRInputLabel>
							)}
						/>
						<Controller
							name='inspection.burdenAmt.burdenAmtAutotransAccountDepositorNm'
							control={form?.control}
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInputLabel label='계좌주' isRequired type='left-sub' showOverflow>
									<CRInput.Default
										placeholder='계좌주 입력'
										value={value}
										onChange={(item) => {
											onChange(item);
											form?.setValue(
												'inspection.burdenAmt.burdenAmtAutotransAccountValidYn',
												false,
											);
											setIsValidAccount(false);
										}}
										suffix={
											<CRButton.Default
												disabled={disabled || !hasAccountInfo}
												style={{
													position: 'relative',
													width: checkAccountLoading ? '4rem' : 'auto',
												}}
												size='xSmall'
												palette='gray'
												type='outlined'
												onClick={handleClickCheckAccount}>
												{checkAccountLoading ? (
													<CRSpinner />
												) : isValidAccount ? (
													<CRIcon src={Assets.icon.check} />
												) : (
													'조회'
												)}
											</CRButton.Default>
										}
									/>
									{errors.inspection?.burdenAmt?.burdenAmtAutotransAccountDepositorNm?.message && (
										<CRInputMessage type='error'>
											{errors.inspection?.burdenAmt?.burdenAmtAutotransAccountDepositorNm
												?.message ?? ''}
										</CRInputMessage>
									)}
								</CRInputLabel>
							)}
						/>
					</CRCardFormLayout>
				)}
				{form.watch('inspection.burdenAmt.burdenAmtPayMethodCd') === 'CMN041.30' && (
					<CRCardFormLayout label='임금공제 대상자 선택'>
						<Controller
							name='inspection.burdenAmt.burdenAmtPayDeductEmployeeId'
							control={form?.control}
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInputLabel label='공재 대상 직원' isRequired type='left-sub' showOverflow>
									<CRInput.Selector
										placeholder='공제 대상 직원 선택'
										items={(wageDeductionEmployees ?? []).map((item) => ({
											label: item.employeeNm,
											value: item.employeeId,
										}))}
										currentValue={value ? { label: '', value } : undefined}
										onChangeValue={(item) => onChange(item.value)}
										disabled={disabled}
									/>
								</CRInputLabel>
							)}
						/>
					</CRCardFormLayout>
				)}
			</CRInputLabel>
		</S.Container>
	);
}

export default ChangeEmployeeContractOwnExpenseForm;
