import React, { useLayoutEffect, useMemo, useState } from 'react';

import CRSpinner from 'components/base/CRSpinner';
import CRTable from 'components/base/CRTable';
import CRTableHeader from 'components/base/CRTableHeader';
import CRTableMonthSelector from 'components/base/CRTableMonthSelector';
import { CRText } from 'components/base/CRText';
import { CheckOption } from 'components/base/Selections/type';
import RoundingHistoryTable from 'components/domain/table/RoundingHistoryTable';
import { MyAccountInfoDTO } from 'types/api';
import { Filter } from 'types/view/filter';
import { RoundingHistoryViewType } from 'types/view/roundingHistory';

import * as S from './styles';

interface Props {
	targetDate: Date;
	filters: Filter<CheckOption>[];
	handleTargetDate: (date: Date) => void;
	lastSyncDate: string;
	roundingHistoryData?: RoundingHistoryViewType[];
	refetch: () => void;
	isLoading: boolean;
	myAccountInfo?: MyAccountInfoDTO | null | undefined;
}

function RoundingHistoryTabPage({
	targetDate,
	handleTargetDate,
	roundingHistoryData,
	lastSyncDate,
	refetch,
	filters,
	isLoading,
	myAccountInfo,
}: Props): React.ReactElement {
	const [keyword, setKeyword] = useState<string>('');
	const [searchValue, setSearchValue] = useState<string>('');
	const [currentFilter, setCurrentFilter] = useState<{
		statusFilter: CheckOption[];
		visitCategoryFilter: CheckOption[];
		tagFilter: CheckOption[];
		managerFilter: CheckOption[];
	}>({ managerFilter: [], statusFilter: [], tagFilter: [], visitCategoryFilter: [] });

	const filteredRoundingHistoryData = useMemo(() => {
		if (!roundingHistoryData || roundingHistoryData.length === 0) return [];

		const applyFilter = (
			data: RoundingHistoryViewType[],
			filterKey: string,
			filterFn: (filter: CheckOption, item: RoundingHistoryViewType) => void,
		) => {
			const filterValues = currentFilter?.[filterKey as keyof typeof currentFilter];
			if (!filterValues || filterValues.length === 0) return data;

			return data.filter((item) =>
				filterValues.some((filter: CheckOption) => filterFn(filter, item)),
			);
		};

		const filteredData = applyFilter(
			roundingHistoryData,
			'statusFilter',
			(filter, item) => filter.value === item.visitCompleteYn,
		);

		const visitCategoryFilteredData = applyFilter(
			filteredData,
			'visitCategoryFilter',
			(filter, item) => filter.value === item.data.visitTypeCd,
		);

		const tagFilteredData = applyFilter(
			visitCategoryFilteredData,
			'tagFilter',
			(filter, item) => filter.value === item.tagYn,
		);

		const managerFilteredData = applyFilter(
			tagFilteredData,
			'managerFilter',
			(filter, item) => filter.value === item.data.managerId,
		);

		const keyworkFilteredData = managerFilteredData.filter((item) =>
			item.data.recipientNm.includes(keyword),
		);

		return keyworkFilteredData;
	}, [roundingHistoryData, currentFilter, keyword]);

	const handleRefresh = async () => {
		await refetch();
	};
	const handleSearch = () => {
		setKeyword(searchValue);
	};

	const renderEmpty = useMemo(
		() => (
			<S.EmptyContainer>
				<S.EmptyTitle>아직 일정이 없습니다.</S.EmptyTitle>
				<S.EmptyDescription>
					해당 월에 수급자 일정이 있어야 내역이 나타납니다.
					<br />
					궁금한 점은 관리자에게 문의하세요.
				</S.EmptyDescription>
			</S.EmptyContainer>
		),
		[],
	);

	const renderTableContent = useMemo(() => {
		if (isLoading) return <CRSpinner />;
		if (filteredRoundingHistoryData.length > 0) {
			return (
				<S.TableScrollContainer>
					<RoundingHistoryTable roundingHistoryData={filteredRoundingHistoryData} />
				</S.TableScrollContainer>
			);
		}
		return renderEmpty;
	}, [filteredRoundingHistoryData, isLoading]);

	useLayoutEffect(() => {
		if (myAccountInfo && myAccountInfo?.positionCd === 'CMN103.10') {
			setCurrentFilter((prev) => {
				const alreadyManagerExist = Boolean(
					prev.managerFilter.find((manager) => manager.value === myAccountInfo.memberAccountId),
				);
				if (alreadyManagerExist) {
					return prev;
				}
				return {
					...prev,
					managerFilter: [
						...prev.managerFilter,
						{ value: myAccountInfo.memberAccountId, label: myAccountInfo.userNm },
					],
				};
			});
		}
	}, [myAccountInfo]);

	return (
		<CRTable.BackBlind>
			<S.ContentHeader>
				<CRTableMonthSelector value={targetDate} onChange={handleTargetDate} />
				<CRText text={`${lastSyncDate} 동기화`} typography='label' color='gray60' />
			</S.ContentHeader>
			<S.TableContainer>
				<CRTableHeader
					currentFilter={currentFilter ?? {}}
					stickyMode
					filters={filters}
					searchValue={searchValue}
					placeholder='수급자명으로 검색'
					onChangeSearchValue={setSearchValue}
					setCurrentFilter={setCurrentFilter}
					onSearch={handleSearch}
					showRefresh
					onRefresh={handleRefresh}
				/>
				{renderTableContent}
			</S.TableContainer>
		</CRTable.BackBlind>
	);
}

export default RoundingHistoryTabPage;
