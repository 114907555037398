import React, { useMemo } from 'react';

import * as Accordion from '@radix-ui/react-accordion';

import MyCenterBasicInformationFormView from 'components/domain/form/MyCenterBasicInformationFormView';
import MyCenterPcorpCertFormView from 'components/domain/form/MyCenterPcorpCertFormView';
import MyCenterUsageInformationFormView from 'components/domain/form/MyCenterUsageInformationFormView';
import MyCenterWorkLeavePermitIpFormView from 'components/domain/form/MyCenterWorkLeavePermitIpFormView';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import { useHasFunc } from 'lib/hook/util/useHasFunc';

import * as S from './styles';

export default function CenterInformationTab() {
	const hasReadJointCertificateFunc = useHasFunc(['center:read_joint_certificate']);
	const forms = useMemo(
		() => [
			{
				id: '#1',
				label: '기본 정보',
				component: <MyCenterBasicInformationFormView />,
			},
			{
				id: '#2',
				label: '모바일 출퇴근 IP 제한 설정',
				component: <MyCenterWorkLeavePermitIpFormView />,
			},
			{
				id: '#3',
				label: '이용 정보',
				component: <MyCenterUsageInformationFormView />,
			},
			...(hasReadJointCertificateFunc
				? [
						{
							id: '#4',
							label: '센터 공동인증서',
							component: <MyCenterPcorpCertFormView />,
						},
					]
				: []),
		],
		[hasReadJointCertificateFunc],
	);
	return (
		<S.Container>
			{/* <ScrollHashContainer /> */}
			<S.FormContainer>
				<Accordion.Root type='multiple' defaultValue={forms.map((form) => form.id)}>
					{forms.map((form) => (
						<Accordion.Item value={form.id} asChild>
							<TaskAccordion.Item>
								<Accordion.Header asChild>
									<Accordion.Trigger asChild>
										<TaskAccordion.Trigger>{form.label}</TaskAccordion.Trigger>
									</Accordion.Trigger>
								</Accordion.Header>
								<Accordion.Content asChild>
									<TaskAccordion.Content>{form.component}</TaskAccordion.Content>
								</Accordion.Content>
							</TaskAccordion.Item>
						</Accordion.Item>
					))}
				</Accordion.Root>
			</S.FormContainer>
		</S.Container>
	);
}
