import React, { useCallback } from 'react';

import dayjs from 'dayjs';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRTable from 'components/base/CRTable';
import { Toast } from 'components/base/CRToast';
import AddFullTimeEmployeeHistoryOfWorkingStatusDialog from 'components/domain/dialog/AddFullTimeEmployeeHistoryOfWorkingStatusDialog';
import {
	useDeleteFulLTimeEmployeeWorkState,
	useFullTimeEmployeeWorkStates,
} from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { endpoint } from 'lib/service/Api/endpoint';
import { FullTimeEmployeeDTO, FullTimeEmployeeWorkStateDTO } from 'types/dto/full-time-employee';

import Colors from '../../../../common/colors';
import CRStatus from '../../../base/CRStatus';
import DefaultDialog from '../DefaultDialog';
import { FULL_TIME_EMPLOYEE_WORKING_STATUS_HISTORY_TABLE_HEADER_CONFIG } from './constants';
import * as S from './styles';

interface Props {
	currentFullTimeEmployee?: FullTimeEmployeeDTO;
	onClickClose?: () => void;
	isResign?: boolean;
}

function FullTimeEmployeeWorkingStatusHistoryDialog({
	currentFullTimeEmployee,
	onClickClose,
	isResign,
}: Props): React.ReactElement {
	const hasUpdateFullTimeEmployeeWorkStateFunc = useHasFunc([
		'full_time_employee:update_full_time_employee:state',
	]);
	const { showDialog } = useDialog();
	const { data: workStates = [] } = useFullTimeEmployeeWorkStates({
		employeeId: currentFullTimeEmployee?.employeeId,
		centerId: currentFullTimeEmployee?.centerId,
		memberId: currentFullTimeEmployee?.memberId,
	});
	const deleteFullTimeEmployeeWorkState = useDeleteFulLTimeEmployeeWorkState(
		async (client, returnData, variables) => {
			client.invalidateQueries([
				endpoint.getFullTimeEmployeeWorkStates.key,
				{
					centerId: currentFullTimeEmployee?.centerId,
					employeeId: currentFullTimeEmployee?.employeeId,
					memberId: currentFullTimeEmployee?.memberId,
				},
			]);

			await client.invalidateQueries([
				endpoint.getFullTimeEmployees.key,
				{
					centerIds: [currentFullTimeEmployee?.centerId],
				},
			]);

			await client.invalidateQueries([
				endpoint.getFullTimeEmployeeBaseInfo.key,
				{
					centerId: currentFullTimeEmployee?.centerId,
					employeeId: currentFullTimeEmployee?.employeeId,
				},
			]);

			await client.invalidateQueries([
				endpoint.getFullTimeEmployeeDetailInfo.key,
				{
					centerId: currentFullTimeEmployee?.centerId,
					employeeId: currentFullTimeEmployee?.employeeId,
					memberId: currentFullTimeEmployee?.memberId,
				},
			]);
			Toast.success('상태 변경 이력을 삭제되었습니다.');
		},
	);

	const handleClickAdd = () => {
		const currentWorkState = workStates?.find((item) => item.divNm === '현재');
		const newWorkState = {
			ftimeEmployeeWorkStateHistId: undefined,
			...currentWorkState,
		} as FullTimeEmployeeWorkStateDTO;

		showDialog(({ hideDialog }) => (
			<AddFullTimeEmployeeHistoryOfWorkingStatusDialog
				currentFullTimeEmployee={currentFullTimeEmployee}
				currentWorkState={newWorkState}
				hideDialog={hideDialog}
			/>
		));
	};

	const handleClickEdit = (target: FullTimeEmployeeWorkStateDTO) => {
		const currentWorkState = workStates?.find((item) => item.divNm === '현재');
		const newWorkState = {
			ftimeEmployeeWorkStateHistId: undefined,
			...currentWorkState,
		} as FullTimeEmployeeWorkStateDTO;

		showDialog(({ hideDialog }) => (
			<AddFullTimeEmployeeHistoryOfWorkingStatusDialog
				currentFullTimeEmployee={currentFullTimeEmployee}
				currentWorkState={newWorkState}
				targetWorkState={target}
				hideDialog={hideDialog}
			/>
		));
	};

	const handleClickDelete = (item: FullTimeEmployeeWorkStateDTO) => {
		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='상태 변경 이력을 삭제 하시겠습니까?'
				content={`[${dayjs(item.workStateChangeDate).format('YYYY.MM.DD')} / ${
					item.workStateNm
				}] 이력을 삭제합니다.\n현재 상태 이력 삭제 시, 직전 이력을 현재 상태로 되돌립니다.`}
				successOption={{
					text: '삭제',
					successCallback: async () => {
						if (!item.ftimeEmployeeWorkStateHistId) return;

						await deleteFullTimeEmployeeWorkState.mutateAsync({
							ftimeEmployeeWorkStateHistId: item.ftimeEmployeeWorkStateHistId,
						});
						hideDialog();
					},
				}}
				cancelOption={{
					text: '취소',
					callback: () => {
						hideDialog();
					},
				}}
			/>
		));
	};

	const StateButtonComponent = useCallback(
		(state: string) => (
			<CRStatus
				options={[
					{ key: '현재', color: 'green' },
					{ key: '이전 이력', color: 'gray' },
					{ key: '변경 예정', color: 'yellow' },
				]}>
				{state}
			</CRStatus>
		),
		[],
	);

	const ButtonContainer = useCallback(
		(id?: number, item?: FullTimeEmployeeWorkStateDTO & { index: number }) => {
			const isLastOne = item?.index === (workStates?.length ?? 0) - 1;

			if (!item) return <div />;
			if (['', 'CMN068.30'].includes(item.workStateCd)) return <div />;
			if (isLastOne) return <div />;

			return (
				<S.OptionContainer>
					<CRButton.Default
						disabled={!hasUpdateFullTimeEmployeeWorkStateFunc}
						size='xSmall'
						type='outlined'
						palette='gray'
						onClick={() => handleClickEdit(item)}>
						수정
					</CRButton.Default>
					<CRButton.Default
						disabled={!hasUpdateFullTimeEmployeeWorkStateFunc}
						size='xSmall'
						type='outlined'
						palette='primary'
						onClick={() => handleClickDelete(item)}>
						삭제
					</CRButton.Default>
				</S.OptionContainer>
			);
		},
		[workStates?.length],
	);

	return (
		<CRDialog
			type='L'
			title='상태 변경 이력'
			bottomContainerStyle={{ borderTop: `0.1rem solid ${Colors.gray90}` }}
			body={
				<CRTable.Root>
					<CRTable.Head
						heads={FULL_TIME_EMPLOYEE_WORKING_STATUS_HISTORY_TABLE_HEADER_CONFIG}
						disableBackBlind
					/>
					<CRTable.Body>
						{(workStates ?? []).map((item, index) => (
							<CRTable.Row
								key={item.ftimeEmployeeWorkStateHistId}
								item={{ ...item, index }}
								customRender={{
									ftimeEmployeeWorkStateHistId: ButtonContainer,
									divNm: StateButtonComponent,
									workStateChangeDate: (item) => dayjs(item).format('YYYY.MM.DD'),
								}}
								renderKeys={[
									'divNm',
									'workStateChangeDate',
									'workStateNm',
									'workStateChangeDesc',
									'ftimeEmployeeWorkStateHistId',
								]}
							/>
						))}
					</CRTable.Body>
				</CRTable.Root>
			}
			footer={
				<S.FooterRightSideContainer>
					{!isResign && (
						<CRButton.IconButton
							disabled={!hasUpdateFullTimeEmployeeWorkStateFunc}
							iconLeft={Assets.icon.add}
							type='outlined'
							palette='gray'
							onClick={handleClickAdd}>
							상태 변경
						</CRButton.IconButton>
					)}
				</S.FooterRightSideContainer>
			}
			onClickClose={onClickClose}
		/>
	);
}

export default React.memo(FullTimeEmployeeWorkingStatusHistoryDialog);
