import React, { ChangeEvent } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { IntegratedAssessmentDesireForms } from 'types/view/recipient';

import CRCheckBox from '../../../base/Selections/CRCheckBox';
import IntegratedAssessmentDesireRecipientCaregiverNeedsFormHeader from './IntegratedAssessmentDesireRecipientCaregiverNeedsFormHeader';
import { integratedAssessmentDesireRecipientCaregiverNeedsQuestions } from './constant';
import * as S from './styles';

interface Props {
	form: UseFormReturn<IntegratedAssessmentDesireForms>;
	showGenerateRequiredFields?: boolean;
	enabled?: boolean;
}

function IntegratedAssessmentDesireRecipientCaregiverNeedsForm({
	form,
	showGenerateRequiredFields,
	enabled,
}: Props): React.ReactElement {
	return (
		<S.Container>
			<S.Table>
				<IntegratedAssessmentDesireRecipientCaregiverNeedsFormHeader />
				<S.TableBody>
					{integratedAssessmentDesireRecipientCaregiverNeedsQuestions.map((question) => (
						<Controller
							render={({ field: { ref, onChange, value }, formState: { errors } }) => (
								<S.TableBodyRow>
									<S.TableBodyColumn style={{ textAlign: 'center' }}>
										{question.label}
									</S.TableBodyColumn>
									<S.TableBodyColumn>
										<S.TableContentContainer>
											{(question.answer ?? []).map((answer) => (
												<CRCheckBox
													disabled={!enabled}
													checkBoxType='checkbox'
													checked={form.watch(question.key)?.includes(answer.value)}
													onChange={(event: ChangeEvent<HTMLInputElement>) => {
														const targetValue = value as string[];

														if (event.target.checked) {
															onChange([...(targetValue ?? []), answer.value]);
														} else {
															onChange((targetValue ?? []).filter((item) => item !== answer.value));
														}
													}}>
													{answer.label}
												</CRCheckBox>
											))}
											{form.watch(question.key)?.includes('기타') && (
												<Controller
													render={({ field: { ref, onChange, value }, formState: { errors } }) => (
														<CRInput.TextArea
															ref={ref}
															disabled={!enabled}
															placeholder='기타 사항 입력'
															onChange={onChange}
															value={value as string}
														/>
													)}
													name={`${question.key}기타` as keyof IntegratedAssessmentDesireForms}
													control={form.control}
												/>
											)}
										</S.TableContentContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							)}
							name={question.key}
							control={form.control}
						/>
					))}
				</S.TableBody>
			</S.Table>
			<Controller
				render={({ field: { ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='판단 근거' isRequired={showGenerateRequiredFields}>
						<CRInput.TextArea
							ref={ref}
							disabled={!enabled}
							placeholder='판단 근거 입력'
							onChange={onChange}
							value={value}
						/>
					</CRInputLabel>
				)}
				name='수급자및보호자개별욕구판단근거'
				control={form.control}
			/>
		</S.Container>
	);
}

export default IntegratedAssessmentDesireRecipientCaregiverNeedsForm;
