import React, { ChangeEvent, useRef, useState } from 'react';

import CRButton from 'components/base/CRButton';

import Assets from '../../../assets';
import CRSpinner from '../../../components/base/CRSpinner';
import * as S from './styles';

interface Props {
	onChange?: (files: File) => Promise<void> | void;
	disabled?: boolean;
}

function OnwExpenseFileUploadButton({ onChange, disabled = false }: Props): React.ReactElement {
	const [loading, setLoading] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);

	const handleChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) return;
		setLoading(true);
		await onChange?.(event.target.files[0]);
		setLoading(false);
	};

	const onClickInput = () => {
		inputRef.current?.click();
	};

	return (
		<S.UploadButtonContainer>
			<CRButton.Default disabled={disabled} palette='gray' type='outlined' onClick={onClickInput}>
				<S.Button>
					{loading ? <CRSpinner floatMode={false} /> : <S.Icon src={Assets.icon.description} />}
					업로드
				</S.Button>
			</CRButton.Default>
			<input ref={inputRef} hidden type='file' onChange={handleChangeFile} />
		</S.UploadButtonContainer>
	);
}

export default OnwExpenseFileUploadButton;
