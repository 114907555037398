import { MyFuncDTO } from 'types/dto/common';

export enum ResponseCode {
	SUCCESS = '200',
	ERROR = 500,
	CANCEL = 'ERR_CANCELED',
}

export interface FieldError {
	codes?: Array<string>;
	arguments?: Array<any>;
	defaultMessage?: string;
	objectName?: string;
	field?: string;
	rejectedValue?: any;
	bindingFailure?: boolean;
	code?: string;
}

export interface BaseResponse<T = any> {
	code?: ResponseCode;
	message?: string;
	data?: T;
}

export interface ErrorValidationResponse {
	code: ResponseCode;
	message: string;
	fieldErrors: Array<FieldError>;
}

export interface Sort {
	empty: boolean;
	sorted: boolean;
	unsorted: boolean;
}

export interface Pageable {
	offset: number;
	sort: Sort;
	pageNumber: number;
	pageSize: number;
	paged: boolean;
	unpaged: boolean;
}

export type SortOrder = 'ASC' | 'DESC';

export type GetMyFuncsRequest = undefined;

export type GetMyFuncsData = MyFuncDTO[];

export type GetMyFuncsResponse = BaseResponse<GetMyFuncsData>;
