import React, { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';

import Assets from 'assets';
import RouterPath from 'common/router';
import CRSyncMenu from 'components/base/CRSyncMenu';
import CRTab from 'components/base/CRTab';
import { Toast } from 'components/base/CRToast';
import { MonitoringDataSyncDialog } from 'components/domain/dialog/MonitoringDataSyncDialog';
import MonitoringSyncHistoryDialog from 'components/domain/dialog/MonitoringSyncHistoryDialog';
import { PCorpSyncDialog } from 'components/domain/dialog/PCorpSyncDialog';
import { useMyAccountInfo } from 'lib/hook/react-query';
import { useSyncMonitoring, useSyncPCorp } from 'lib/hook/react-query/mutation/monitoring';
import {
	useCompleteMonitoringSyncDate,
	useRecentMonitoringSyncDate,
} from 'lib/hook/react-query/query/monitoring';
import useDateFilter from 'lib/hook/util/useDateFilter';
import useDialog from 'lib/hook/util/useDialog';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import { MonitoringUploadTypeCds, ResponseCode } from 'types/api';
import { LoadMonitoringDataForm, MonitoringDataSyncForm } from 'types/view/monitoring';

import MonitoringTab from './MonitoringTab';
import * as S from './styles';

export default function MonitoringPage() {
	const hasSyncLongTermDataFunc = useHasFunc(['monitoring:sync_longterm_data']);
	const hasCreateMonitoringListFunc = useHasFunc(['monitoring:create_monitoring_list']);
	const monitoringTab = useMemo(() => RouterPath.scheduleOperate().monitoring, []);
	const dialog = useDialog();
	const [date, setDate] = useDateFilter();
	const [isPCorpSyncLoading, setIsPCorpSyncLoading] = useState(true);
	const [isAllDataSyncLoading, setIsAllDataSyncLoading] = useState(true);
	const { data: myAccountInfo } = useMyAccountInfo();
	const longTermSync = useSyncPCorp((client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			setIsPCorpSyncLoading(true);
			Toast.success(
				'공단 데이터 불러오기 요청을 하였습니다. 잠시 뒤 자동화 업데이트 후 반영될 예정입니다.',
			);
		} else {
			Toast.error(
				'공단 데이터 불러오기 요청에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.',
			);
		}
	});

	const allDataSync = useSyncMonitoring((client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			setIsAllDataSyncLoading(true);
			Toast.success(
				'전체 데이터 갱신 요청을 하였습니다. 잠시 뒤 자동화 업데이트 후 반영될 예정입니다.',
			);
		} else {
			Toast.error('전체 데이터 갱신 요청에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
	});

	const { data: completeMonitoringSyncDate, refetch: completeSyncDateRefetch } =
		useCompleteMonitoringSyncDate({
			centerId: myAccountInfo?.centerId,
			serviceYm: dayjs(date).format('YYYYMM'),
		});

	const allDataSyncDate = completeMonitoringSyncDate?.find(
		(item) => item.uploadTypeCd === MonitoringUploadTypeCds.일정모니터링_동기화,
	)?.uploadStartDate;
	const { data: recentMonitoringSyncDate } = useRecentMonitoringSyncDate({
		centerId: myAccountInfo?.centerId,
		serviceYm: dayjs(date).format('YYYYMM'),
	});
	const centerId = myAccountInfo?.centerId;

	const hasPermission = useHasPermission('센터장');

	const handlePcorpSync = async (data: LoadMonitoringDataForm) => {
		if (!centerId) return;

		longTermSync.mutate({
			centerId: myAccountInfo.centerId,
			serviceYm: dayjs(data.yearMonth).format('YYYYMM'),
			syncTypeCds: data.dataTypes.map((item) => item.value),
		});
		dialog.hideDialog();
	};

	const handleAllDataSync = async (data: MonitoringDataSyncForm) => {
		if (!centerId) return;

		allDataSync.mutate({
			centerId: myAccountInfo.centerId,
			serviceYm: dayjs(data.yearMonth).format('YYYYMM'),
		});
		dialog.hideDialog();
	};

	const handleChangeDate = (newDate: Date) => {
		setDate(newDate);
	};

	const handleClickAllDataSync = () => {
		dialog.showDialog(() => <MonitoringDataSyncDialog onSync={handleAllDataSync} />);
	};

	const handleClickSyncHistory = () => {
		dialog.showDialog(() => (
			<MonitoringSyncHistoryDialog
				serviceYm={dayjs(date).format('YYYYMM')}
				completeSyncDate={completeMonitoringSyncDate || []}
			/>
		));
	};

	const handleClickPCorpSync = async () => {
		await completeSyncDateRefetch();
		dialog.showDialog(() => (
			<PCorpSyncDialog
				onSync={handlePcorpSync}
				completeSyncData={completeMonitoringSyncDate || []}
			/>
		));
	};
	const longtermLoading = useMemo(
		() => isPCorpSyncLoading || longTermSync.isLoading,
		[isPCorpSyncLoading, longTermSync],
	);

	const allDataSyncLoading = useMemo(
		() => isAllDataSyncLoading || allDataSync.isLoading,
		[isAllDataSyncLoading, allDataSync],
	);

	useEffect(() => {
		const PCorpRFID = recentMonitoringSyncDate?.find(
			(item) => item.uploadTypeCd === MonitoringUploadTypeCds.공단_기타_RFID_동기화,
		);

		const PCorpSchedule = recentMonitoringSyncDate?.find(
			(item) => item.uploadTypeCd === MonitoringUploadTypeCds.공단_일정계획_동기화,
		);

		const PCorpTag = recentMonitoringSyncDate?.find(
			(item) => item.uploadTypeCd === MonitoringUploadTypeCds.공단_태그_동기화,
		);

		const ScheduleMonitoring = recentMonitoringSyncDate?.find(
			(item) => item.uploadTypeCd === MonitoringUploadTypeCds.일정모니터링_동기화,
		);

		const pCorpDataArray = [PCorpRFID, PCorpSchedule, PCorpTag];
		const isPCorpDataSyncLoading = pCorpDataArray?.some(
			(data) =>
				data?.uploadStateCd === 'CMN108.REQUEST' || data?.uploadStateCd === 'CMN108.PROGRESS',
		);

		if (isPCorpDataSyncLoading) {
			setIsPCorpSyncLoading(true);
		} else {
			setIsPCorpSyncLoading(false);
		}

		const isAllDataSyncLoading = [ScheduleMonitoring]?.some(
			(data) =>
				data?.uploadStateCd === 'CMN108.REQUEST' || data?.uploadStateCd === 'CMN108.PROGRESS',
		);

		if (isAllDataSyncLoading) {
			setIsAllDataSyncLoading(true);
		} else {
			setIsAllDataSyncLoading(false);
		}
	}, [recentMonitoringSyncDate]);

	return (
		<CRTab.Default
			defaultActiveKey={monitoringTab.key}
			breadCrumb='일정 모니터링'
			renderRightButton={
				<S.TopButtonContainer>
					<CRSyncMenu
						syncDisabled={!hasSyncLongTermDataFunc}
						showHistoryButton={false}
						showSyncButton={hasPermission}
						title={longtermLoading ? '공단 데이터 불러오는 중' : '공단 데이터 불러오기'}
						isProgress={longtermLoading}
						leftIcon={Assets.icon.dataImport}
						onClickSync={handleClickPCorpSync}
					/>
					<CRSyncMenu
						syncDisabled={!hasCreateMonitoringListFunc}
						title={allDataSyncLoading ? '전체 데이터 갱신 중' : '전체 데이터 갱신'}
						isProgress={allDataSyncLoading}
						onClickSync={handleClickAllDataSync}
						onClickSyncHistory={handleClickSyncHistory}
					/>
				</S.TopButtonContainer>
			}
			items={[
				{
					label: monitoringTab.label,
					key: monitoringTab.key,
					children: (
						<MonitoringTab
							date={date}
							onChangeDate={handleChangeDate}
							allDataSyncDate={allDataSyncDate}
						/>
					),
				},
			]}
		/>
	);
}
