import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Accordion from '@radix-ui/react-accordion';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRTable from 'components/base/CRTable';
import { Toast } from 'components/base/CRToast';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import DeleteDialog from 'components/domain/dialog/DeleteDialog';
import EmployeeDetailBasicInformationTable from 'components/domain/table/EmployeeDetailBasicInformationTable';
import InformationTable from 'components/ui/InformationTable';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import { EmployeeResign } from 'lib';
import { commonCodeAdapter } from 'lib/adapter/common';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import {
	useCommonCodes,
	useEmployeePageBaseInfo,
	useEmployeeResignInfo,
	useMyAccountInfo,
	useResignEmployee,
} from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { EmployeeDTO, ResignEmployeeRequest, ResponseCode } from 'types/api';
import { EmployeeResignForm } from 'types/view/employee';

import * as S from './styles';

function EmployeeResignPage(): React.ReactElement {
	const { data: myAccountInfo } = useMyAccountInfo();
	const { setCurrentEmployee, currentEmployee } = useEmployeePage();
	const params = useParams<{ id: string }>();
	const { showDialog, hideDialog } = useDialog();
	const { data: currentEmployeeBase } = useEmployeePageBaseInfo({
		centerId: myAccountInfo?.centerId,
		employeeId: Number(params.id),
	});

	const { data: employeeResignInfo, mutate: getResignInfo } = useEmployeeResignInfo();
	const {
		control,
		handleSubmit,
		setValue,
		formState: { isValid },
		watch,
	} = useForm<EmployeeResignForm>({
		resolver: yupResolver(EmployeeResign),
	});

	const navigate = useNavigate();

	const { data: commonCodes } = useCommonCodes(
		{
			comCdGroupNms: ['CMN109'],
		},
		commonCodeAdapter,
	);

	const isAllCheck =
		watch('insuranceLossRequestYn') &&
		watch('wardResignDischargeRequestYn') &&
		watch('incompletePaperConfirmYn') &&
		watch('calculateConfirmYn');

	const { mutate: resignEmployee } = useResignEmployee((client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			if (returnData.data && currentEmployee) {
				client.invalidateQueries([
					endpoint.getEmployeeDetailInfo.key,
					{
						centerId: returnData.data.centerId,
						employeeId: returnData.data.employeeId,
					},
				]);

				client.setQueryData(
					[
						endpoint.getEmployees.key,
						{
							centerIds: [myAccountInfo?.centerId],
						},
					],
					(data: EmployeeDTO[] = []) => {
						const employeesCache = data;
						const newCache = employeesCache?.map((employee) => {
							if (employee.employeeId === returnData?.data?.employeeId) {
								return {
									...employee,
									workStateNm: '퇴사',
								};
							}
							return employee;
						});

						return newCache;
					},
				);

				setCurrentEmployee({
					...currentEmployee,
					workStateNm: '퇴사',
				});
			}
			Toast.success('직원 상태를 퇴사로 변경했습니다.');
			navigate(`/${RouterPath.employee().tab.uniqueKey}/${params.id}/basic`);
			// navigate(-1);
		} else {
			Toast.error('실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
		hideDialog();
	});

	const resignData = employeeResignInfo?.data;

	const renderServiceStartDate = useCallback(
		(serviceStartDate: string) => (
			<S.TextContainer>
				{serviceStartDate ? dayjs(serviceStartDate).format('YYYY.MM.DD') : '-'}
			</S.TextContainer>
		),
		[],
	);

	const renderManagerName = useCallback(() => {
		const managerNameArr = currentEmployeeBase?.managerNm?.split(' ');
		const managerName =
			managerNameArr && managerNameArr?.length > 0
				? managerNameArr[managerNameArr.length - 1]
				: '-';
		return <S.TextContainer>{managerName}</S.TextContainer>;
	}, [currentEmployeeBase]);

	const totalWorkDate = useMemo(() => {
		const serviceStartDate = watch('serviceStartDate');
		const serviceStateChangeDate = watch('serviceStateChangeDate');

		if (serviceStartDate && serviceStateChangeDate) {
			const diffTotalMonth = dayjs(serviceStateChangeDate).diff(dayjs(serviceStartDate), 'month');

			if (diffTotalMonth > 11) {
				return `총 ${Math.floor(diffTotalMonth / 12)}년 ${Math.floor(
					diffTotalMonth % 12,
				)}개월 근무`;
			}
			return `${dayjs(serviceStartDate).format('YYYY.MM.DD')} ~ ${dayjs(
				serviceStateChangeDate,
			).format('YYYY.MM.DD')}(총 ${Math.floor(diffTotalMonth % 12)}개월 근무)`;
		}

		return '';
	}, [watch('serviceStartDate'), watch('serviceStateChangeDate')]);

	const onSubmit = (data: EmployeeResignForm) => {
		if (!resignData) return;

		const params: ResignEmployeeRequest = {
			centerId: resignData.centerId,
			employeeId: resignData.employeeId,
			serviceStartDate: dayjs(data.serviceStartDate).format('YYYYMMDD'),
			serviceStateChangeDate: dayjs(data.serviceStateChangeDate).format('YYYYMMDD'),
			serviceChangeDescCd: data.serviceChangeDescCd,
			serviceChangeEtcDesc: data.serviceChangeDesc,
			serviceChangeDesc: data.serviceChangeDesc || '',
			insuranceLossRequestYn: data.insuranceLossRequestYn,
			wardResignDischargeRequestYn: data.wardResignDischargeRequestYn,
			incompletePaperConfirmYn: data.incompletePaperConfirmYn,
			calculateConfirmYn: data.calculateConfirmYn,
		};

		resignEmployee(params);
	};

	const handleClickResign = () => {
		showDialog(({ hideDialog }) => (
			<DeleteDialog
				title='퇴사 신청'
				content={`${currentEmployeeBase?.korMemberNm}(${currentEmployeeBase?.employeeId}) 직원 상태를 퇴사로 변경합니다.`}
				hideDialog={hideDialog}
				cancelOption={{
					text: '취소',
				}}
				successOption={{
					text: '퇴사',
					successCallback: handleSubmit(onSubmit),
				}}
			/>
		));
	};

	const handleToggleAllCheck = () => {
		if (!isAllCheck) {
			setValue('insuranceLossRequestYn', true);
			setValue('wardResignDischargeRequestYn', true);
			setValue('incompletePaperConfirmYn', true);
			setValue('calculateConfirmYn', true, { shouldValidate: true });
		} else {
			setValue('insuranceLossRequestYn', false);
			setValue('wardResignDischargeRequestYn', false);
			setValue('incompletePaperConfirmYn', false);
			setValue('calculateConfirmYn', false, { shouldValidate: true });
		}
	};

	const contractLeaveServiceData = employeeResignInfo?.data?.contractLeaveServiceResponses || [];

	useEffect(() => {
		if (myAccountInfo?.centerId && params?.id) {
			getResignInfo({
				centerId: myAccountInfo?.centerId,
				employeeId: params.id,
			});
		}
	}, [myAccountInfo?.centerId, params.id]);

	useEffect(() => {
		if (employeeResignInfo && employeeResignInfo?.code !== ResponseCode.SUCCESS) {
			navigate(-1);
		}
	}, [employeeResignInfo]);

	useEffect(() => {
		if (currentEmployeeBase && currentEmployeeBase?.joinDate) {
			setValue('serviceStartDate', currentEmployeeBase?.joinDate);
			setValue('serviceStateChangeDate', dayjs().format('YYYY-MM-DD'), { shouldValidate: true });
		}
	}, [currentEmployeeBase]);

	useEffect(() => {
		if (resignData?.serviceStartDate) {
			setValue('serviceStartDate', resignData.serviceStartDate, { shouldValidate: true });
		}
		if (resignData?.serviceStateChangeDate) {
			setValue('serviceStateChangeDate', resignData.serviceStateChangeDate);
		}
		if (resignData?.serviceChangeDescCd) {
			setValue('serviceChangeDescCd', resignData.serviceChangeDescCd);
		}
		if (resignData?.edocParams?.[0]?.leaveReason) {
			setValue('serviceChangeDesc', resignData?.edocParams?.[0]?.leaveReason);
		}
	}, [resignData]);

	return (
		<S.Container>
			<S.Title>{`${currentEmployeeBase?.korMemberNm}(${currentEmployeeBase?.employeeId}) 직원 퇴사`}</S.Title>
			<S.ContentsContainer>
				<Accordion.Root
					type='multiple'
					style={{
						width: '100%',
					}}
					defaultValue={['기본 정보', '계약 종료 급여', '퇴사 신청', '점검']}>
					<Accordion.Item value='기본 정보' asChild>
						<TaskAccordion.Item>
							<Accordion.Header asChild>
								<Accordion.Trigger asChild>
									<TaskAccordion.Trigger>기본 정보</TaskAccordion.Trigger>
								</Accordion.Trigger>
							</Accordion.Header>
							<Accordion.Content asChild>
								<TaskAccordion.Content>
									<EmployeeDetailBasicInformationTable item={currentEmployeeBase} />
								</TaskAccordion.Content>
							</Accordion.Content>
						</TaskAccordion.Item>
					</Accordion.Item>
					<Accordion.Item value='계약 종료 급여' asChild>
						<TaskAccordion.Item>
							<Accordion.Header asChild>
								<Accordion.Trigger asChild>
									<TaskAccordion.Trigger>계약 종료 급여</TaskAccordion.Trigger>
								</Accordion.Trigger>
							</Accordion.Header>
							<Accordion.Content asChild>
								<TaskAccordion.Content>
									<InformationTable
										disabledFullWidth
										items={[
											[
												{
													type: 'label',
													label: '소속',
													labelStyle: {
														width: '12rem',
													},
												},
												{
													type: 'label',
													label: '사회복지사',
													labelStyle: {
														width: '12rem',
													},
												},
												{
													type: 'label',
													label: '급여 종류',
													labelStyle: {
														width: '12rem',
													},
												},
												{
													type: 'label',
													label: '담당 직원',
													labelStyle: {
														width: '12rem',
													},
												},
												{
													type: 'label',
													label: '급여 시작일',
													labelStyle: {
														width: '12rem',
													},
												},
											],
											...contractLeaveServiceData.map(
												(item) =>
													[
														{
															type: 'value',
															value: item.centerNm,
														},
														{
															type: 'value',
															value: renderManagerName(),
														},
														{
															type: 'value',
															value: item.serviceTypeCdNm,
														},
														{
															type: 'value',
															value: item.employeeNm,
														},
														{
															type: 'value',
															value: renderServiceStartDate(item.serviceStartDate),
														},
													] as InformationTableItemType[],
											),
										]}
									/>
								</TaskAccordion.Content>
							</Accordion.Content>
						</TaskAccordion.Item>
					</Accordion.Item>
					<Accordion.Item value='퇴사 신청' asChild>
						<TaskAccordion.Item>
							<Accordion.Header asChild>
								<Accordion.Trigger asChild>
									<TaskAccordion.Trigger>퇴사 신청</TaskAccordion.Trigger>
								</Accordion.Trigger>
							</Accordion.Header>
							<Accordion.Content asChild>
								<TaskAccordion.Content>
									<div
										style={{
											width: '57.2rem',
										}}>
										<Controller
											control={control}
											name='serviceStartDate'
											render={({ field: { onChange, value } }) => (
												<CRInputLabel label='입사일' type='left-sub' isRequired>
													<CRInput.DatePicker
														value={dayjs(value).toDate()}
														onChangeValue={(data) => onChange(dayjs(data).format('YYYY-MM-DD'))}
														placeholder='입사일 입력'
														maxDate={dayjs(watch('serviceStateChangeDate')).toDate()}
													/>
												</CRInputLabel>
											)}
										/>
										<Controller
											control={control}
											name='serviceStateChangeDate'
											render={({ field: { onChange, value } }) => (
												<CRInputLabel label='퇴사일' type='left-sub' isRequired>
													<CRInput.DatePicker
														value={dayjs(value).toDate()}
														onChangeValue={(data) => onChange(dayjs(data).format('YYYY-MM-DD'))}
														placeholder='퇴사일 입력'
														minDate={dayjs(watch('serviceStartDate')).toDate()}
													/>
												</CRInputLabel>
											)}
										/>
										<CRInputLabel label='이용기간' type='left-sub'>
											<CRInput.Default disabled value={totalWorkDate} placeholder='이용기간' />
										</CRInputLabel>
										<Controller
											control={control}
											name='serviceChangeDescCd'
											render={({ field: { onChange, value } }) => (
												<CRInputLabel label='종료 유형' type='left-sub' isRequired>
													<CRInput.Selector
														currentValue={
															value
																? {
																		value,
																		label: '',
																	}
																: undefined
														}
														onChangeValue={(data) => onChange(data.value)}
														items={commonCodes?.CMN109.filter(
															(item) => item.data?.etcDesc2 === 'Y',
														)}
														placeholder='종료 유형 선택'
													/>
												</CRInputLabel>
											)}
										/>
										{watch('serviceChangeDescCd') === 'CMN109.99' && (
											<Controller
												control={control}
												name='serviceChangeEtcDesc'
												render={({ field: { onChange, value } }) => (
													<CRInputLabel label='' type='left-sub'>
														<CRInput.Default
															value={value}
															placeholder='기타 사유 입력'
															onChange={onChange}
														/>
													</CRInputLabel>
												)}
											/>
										)}
										<Controller
											control={control}
											name='serviceChangeDesc'
											render={({ field: { onChange, value } }) => (
												<CRInputLabel label='종료 사유' type='left-sub' isRequired>
													<CRInput.TextArea value={value} onChange={onChange} numberOfLines={10} />
												</CRInputLabel>
											)}
										/>
									</div>
								</TaskAccordion.Content>
							</Accordion.Content>
						</TaskAccordion.Item>
					</Accordion.Item>
					<Accordion.Item value='점검' asChild>
						<TaskAccordion.Item>
							<Accordion.Header asChild>
								<Accordion.Trigger asChild>
									<TaskAccordion.Trigger>점검</TaskAccordion.Trigger>
								</Accordion.Trigger>
							</Accordion.Header>
							<Accordion.Content asChild>
								<TaskAccordion.Content>
									<S.CheckBoxContainer>
										<S.CheckBoxRow
											style={{
												paddingTop: '0.4rem',
											}}>
											<CRCheckBox
												checked={
													watch('insuranceLossRequestYn') &&
													watch('wardResignDischargeRequestYn') &&
													watch('incompletePaperConfirmYn') &&
													watch('calculateConfirmYn')
												}
												onChange={handleToggleAllCheck}>
												전체 점검 완료
											</CRCheckBox>
										</S.CheckBoxRow>
										<S.Divider />
										<S.CheckBoxRow>
											<Controller
												render={({ field: { onChange, value } }) => (
													<CRCheckBox checked={value} onChange={onChange}>
														4대 보험 상실 신고 <S.RequiredMark>*</S.RequiredMark>
													</CRCheckBox>
												)}
												name='insuranceLossRequestYn'
												control={control}
											/>
										</S.CheckBoxRow>
										<S.CheckBoxRow>
											<Controller
												render={({ field: { onChange, value } }) => (
													<CRCheckBox checked={value} onChange={onChange}>
														시군구 보고(직원 퇴사 신고) <S.RequiredMark>*</S.RequiredMark>
													</CRCheckBox>
												)}
												name='wardResignDischargeRequestYn'
												control={control}
											/>
										</S.CheckBoxRow>
										<S.CheckBoxRow>
											<Controller
												render={({ field: { onChange, value } }) => (
													<>
														<CRCheckBox checked={value} onChange={onChange}>
															미비 서류 확인 <S.RequiredMark>*</S.RequiredMark>
														</CRCheckBox>
														<S.SubCheckContainer>
															<S.SubCheckDescription>
																자격증 (요양보호사, 간호사, 간호조무사)
															</S.SubCheckDescription>
															<S.SubCheckDescription>근로자 계약서</S.SubCheckDescription>
															<S.SubCheckDescription>건강검진 결과지</S.SubCheckDescription>
															<S.SubCheckDescription>법정의무교육 수료증</S.SubCheckDescription>
															<S.SubCheckDescription>
																(해당 시) 임금이체위임 동의서
															</S.SubCheckDescription>
															<S.SubCheckDescription>
																(해당 시) 급여제공 서약서
															</S.SubCheckDescription>
															<S.SubCheckDescription>
																(해당 시) 급여제공기록지
															</S.SubCheckDescription>
														</S.SubCheckContainer>
													</>
												)}
												name='incompletePaperConfirmYn'
												control={control}
											/>
										</S.CheckBoxRow>
										<S.CheckBoxRow>
											<Controller
												render={({ field: { onChange, value } }) => (
													<CRCheckBox checked={value} onChange={onChange}>
														정산 여부 확인(미납급, 환급금) <S.RequiredMark>*</S.RequiredMark>
													</CRCheckBox>
												)}
												name='calculateConfirmYn'
												control={control}
											/>
										</S.CheckBoxRow>
									</S.CheckBoxContainer>
								</TaskAccordion.Content>
							</Accordion.Content>
						</TaskAccordion.Item>
					</Accordion.Item>
				</Accordion.Root>
			</S.ContentsContainer>
			<S.BottomSection>
				<S.BottomMenuContainer>
					<S.BottomRightMenuContainer>
						<CRButton.Default type='text' palette='gray' onClick={() => navigate(-1)}>
							취소
						</CRButton.Default>
						<CRButton.Default disabled={!isValid} type='outlined' onClick={handleClickResign}>
							퇴사
						</CRButton.Default>
					</S.BottomRightMenuContainer>
				</S.BottomMenuContainer>
			</S.BottomSection>
		</S.Container>
	);
}

export default EmployeeResignPage;
