import dayjs from 'dayjs';

import {
	displayBirthDay,
	displayPhoneNumber,
	displayRdsNumber,
	displayRsdnNoToBirthDate,
} from 'lib';
import { MyAccountInfoDTO } from 'types/api/auth';
import { CenterDTO } from 'types/api/center';
import {
	EDocAdmissionUseRequestDTO,
	EDocPledgeDTO,
	EDocResignDTO,
	IssueEDocumentRequest,
} from 'types/api/eDoc';
import { EmployeeDTO } from 'types/api/employee';
import {
	AdmissionUseRequestFormTypes,
	CMSAutoTransferFormTypes,
	EDocOtherBankBookIssueItem,
	EDocPledgeIssueItem,
	EDocResignIssueItem,
	EDocWithOutSignIssueItem,
	EmploymentCertificationFormTypes,
	IssueAdmissionUseRequestIssueItem,
	IssueAdmissionUseRequestService,
	IssueCMSAutoTransferIssueItem,
	OtherBankBookFormTypes,
	PledgeFormTypes,
	ResignFormTypes,
} from 'types/view/eDoc';
import { Recipient } from 'types/view/recipient';

export const eDocwithOutSignIssueFormAdapter = (
	formData: EmploymentCertificationFormTypes,
	currentEmployee: EmployeeDTO,
	currentSender?: MyAccountInfoDTO,
	stampImageData = '',
) => {
	if (!formData.data?.data.employee || !currentSender || !currentEmployee) {
		return undefined;
	}
	const {
		data: {
			data: { employee },
		},
	} = formData;

	const eFormData = {
		crfeNm: formData.data?.eformFileNm,
		params: employee.map((item, index) => {
			const data: EDocWithOutSignIssueItem = {
				companyNm: item.centerNm,
				assignTaskNm: item?.dutyCdNm,
				workStartEndDate: item?.contractPeriod,
				issueDescNm: formData.issueReason[0].label || formData.issueReasonEtcDescription,
			};
			if (index === 0) {
				data.nm = currentEmployee.korMemberNm;
				data.birthDt = displayBirthDay(currentEmployee.birthDt);
				data.workStartDate = item?.contractStartDate
					? dayjs(item?.contractStartDate).format('YYYY-MM-DD')
					: '';
			}
			if (index === employee.length - 1) {
				data.year = String(dayjs().get('year'));
				data.month = String(dayjs().get('month') + 1);
				data.day = String(dayjs().get('date'));
				data.corpNm = item.centerNm;
				data.corpStampImageData = stampImageData;
			}

			return data;
		}),
	};

	const result = {
		edocTitle: `${currentEmployee.korMemberNm}님 ${formData.data.edocNm}`,
		edocSenderId: currentSender.memberAccountId,
		edocSenderNm: currentSender.userNm,
		edocSenderPhoneNo: '',
		centerId: currentSender.centerId,
		paperTypeCd: formData.data.paperTypeCd,
		edocParamValue: JSON.stringify(eFormData),
		edocReceivePhoneNos: [String(currentEmployee.mobilePhoneNo)],
		edocReceiverIds: [currentEmployee.employeeId],
		edocReceiverNms: [String(currentEmployee.korMemberNm)],
	} as IssueEDocumentRequest;

	return result;
};

export const eDocPledgeIssueFormAdapter = (
	formData: PledgeFormTypes,
	currentEmployee: EmployeeDTO,
	currentSender?: MyAccountInfoDTO,
) => {
	if (!formData.data?.data.employee || !currentSender || !currentEmployee) {
		return undefined;
	}

	const { employee } = (formData.data as unknown as EDocPledgeDTO).data;

	const eFormData = {
		crfeNm: formData.data?.eformFileNm,
		params: [
			{
				nm: employee.korMemberNm,
				signNm: '',
				birthDt: displayBirthDay(employee.birthDt),
				workStartDate: dayjs(employee.accidentInsuranceAcqDate).format('YYYY.MM.DD'),
				centerNm: employee.centerNm,
				recipientNm: formData.recipient.label,
				content: formData.pledgeContent,
				year: String(dayjs().get('year')),
				month: String(dayjs().get('month') + 1),
				day: String(dayjs().get('date')),
			} as EDocPledgeIssueItem,
		],
	};

	const result = {
		edocTitle: `${currentEmployee.korMemberNm}님 ${formData.data.edocNm}`,
		edocSenderId: currentSender.memberAccountId,
		edocSenderNm: currentSender.userNm,
		edocSenderPhoneNo: '',
		centerId: currentSender.centerId,
		paperTypeCd: formData.data.paperTypeCd,
		edocParamValue: JSON.stringify(eFormData),
		signatoryIds: [employee.employeeId, currentSender.memberAccountId],
		signatoryNms: [employee.korMemberNm || '', currentSender.userNm || ''],
		signatoryPhoneNos: [employee.mobilePhoneNo || '', ''],
		signatoryBirthDts: [employee.birthDt || '', ''],
	} as IssueEDocumentRequest;

	return result;
};

export const eDocOtherBankBookIssueFormAdapter = (
	formData: OtherBankBookFormTypes,
	currentEmployee: EmployeeDTO,
	currentSender?: MyAccountInfoDTO,
) => {
	if (!formData.data?.data.employee || !currentSender || !currentEmployee) {
		return undefined;
	}

	const { employee } = (formData.data as unknown as EDocPledgeDTO).data;

	const eFormData = {
		crfeNm: formData.data?.eformFileNm,
		params: [
			{
				nm: employee.korMemberNm,
				signNm: '',
				birthDt: displayBirthDay(employee.birthDt),
				workStartDate: dayjs(employee.accidentInsuranceAcqDate).format('YYYY.MM.DD'),
				bankNm: formData.bank.label,
				accountNumber: formData.accountNumber,
				accountNm: formData.name,
				accountBirth: displayBirthDay(formData.birthDt),
				accountPhone: displayPhoneNumber(formData.phoneNumber),
				relationshipType: formData.relationEtc || formData.relation.label,
				reason: formData.reason,
				year: String(dayjs().get('year')),
				month: String(dayjs().get('month') + 1),
				day: String(dayjs().get('date')),
			} as EDocOtherBankBookIssueItem,
		],
	};

	const result = {
		edocTitle: `${currentEmployee.korMemberNm}님 ${formData.data.edocNm}`,
		edocSenderId: currentSender.memberAccountId,
		edocSenderNm: currentSender.userNm,
		edocSenderPhoneNo: '',
		centerId: currentSender.centerId,
		paperTypeCd: formData.data.paperTypeCd,
		edocParamValue: JSON.stringify(eFormData),
		signatoryIds: [employee.employeeId, currentSender.memberAccountId],
		signatoryNms: [employee.korMemberNm || '', currentSender.userNm || ''],
		signatoryPhoneNos: [employee.mobilePhoneNo || '', ''],
		signatoryBirthDts: [employee.birthDt || '', ''],
	} as IssueEDocumentRequest;

	return result;
};

export const eDocResignIssueFormAdapter = (
	formData: ResignFormTypes,
	currentEmployee: EmployeeDTO,
	currentSender?: MyAccountInfoDTO,
) => {
	if (!formData.data?.data.employee || !currentSender || !currentEmployee) {
		return undefined;
	}

	const { employee } = (formData.data as unknown as EDocResignDTO).data;

	const eFormData = {
		crfeNm: formData.data?.eformFileNm,
		params: [
			{
				nm: employee.korMemberNm,
				signNm: '',
				centerNm: employee.centerNm,
				birthDt: displayBirthDay(employee.birthDt),
				workStartDate: dayjs(employee.accidentInsuranceAcqDate).format('YYYY.MM.DD'),
				leaveHopeYear: dayjs(formData.resignDate).format('YYYY'),
				leaveHopeMonth: dayjs(formData.resignDate).format('M'),
				leaveHopeDay: dayjs(formData.resignDate).format('D'),
				assignTaskNm: currentEmployee.dutyNms,
				leaveChk1: formData.resignType.value === 'CMN109.51' ? '1' : '',
				leaveChk2: formData.resignType.value === 'CMN109.52' ? '1' : '',
				leaveComCdId: formData.resignType.value,
				leaveDetailComCdId: formData.resignDetailType.value,
				year: String(dayjs().get('year')),
				month: String(dayjs().get('month') + 1),
				day: String(dayjs().get('date')),
				leaveYear: dayjs(formData.resignDate).format('YYYY'),
				leaveMonth: dayjs(formData.resignDate).format('M'),
				leaveDay: dayjs(formData.resignDate).format('D'),
				leaveReason: formData.reason || '',
			} as EDocResignIssueItem,
		],
	};

	const result = {
		edocTitle: `${currentEmployee.korMemberNm}님 ${formData.data.edocNm}`,
		edocSenderId: currentSender.memberAccountId,
		edocSenderNm: currentSender.userNm,
		edocSenderPhoneNo: '',
		centerId: currentSender.centerId,
		paperTypeCd: formData.data.paperTypeCd,
		edocParamValue: JSON.stringify(eFormData),
		signatoryIds: [employee.employeeId, currentSender.memberAccountId],
		signatoryNms: [employee.korMemberNm || '', currentSender.userNm || ''],
		signatoryPhoneNos: [employee.mobilePhoneNo || '', ''],
		signatoryBirthDts: [employee.birthDt || '', ''],
	} as IssueEDocumentRequest;

	return result;
};

// 입소이용신청서 발급
export const eDocAdmissionUseRequestFormAdapter = (
	formData: AdmissionUseRequestFormTypes,
	currentRecipient?: Recipient,
	currentSender?: MyAccountInfoDTO,
	currentCenter?: CenterDTO,
) => {
	if (!formData?.data || !currentSender || !currentRecipient || !currentCenter) {
		return undefined;
	}

	const {
		recipient: { recipient, guardians },
		youNm = '',
		center,
	} = (formData.data as unknown as EDocAdmissionUseRequestDTO).data;

	let serviceTotalAmt = 0;
	const service = formData.service.map((item, index) => {
		const serviceAmtOfMonth =
			item.serviceFee && item.serviceNumberOfMonth
				? Number(item.serviceFee) * Number(item.serviceNumberOfMonth)
				: 0;
		serviceTotalAmt += serviceAmtOfMonth;
		const serviceItem = {
			serviceType: item.serviceType?.label || '',
			serviceDesc: item.serviceDesc || '',
			serviceFee: item.serviceFee ? Number(item.serviceFee).toLocaleString() : '',
			serviceNumberOfMonth: item.serviceNumberOfMonth?.toLocaleString() || '',
			serviceAmtOfMonth: String(serviceAmtOfMonth.toLocaleString()),
			serviceCenterNm: currentSender.centerNm,
			serviceCenterMark: String(currentCenter.centerMark),
		} as IssueAdmissionUseRequestService & { [key: string]: string };

		if (index === 0) {
			serviceItem.recipientNm = formData.recipientNm || '';
			serviceItem.recipientBirthDt = displayRsdnNoToBirthDate(formData.recipientRsdnNo) || '';
			serviceItem.recipientType = '재가급여';
			serviceItem.recipientStartEndDate =
				`${dayjs(formData.startEndDate.start).format('YYYY.MM.DD')} ~ ${
					formData.startEndDate.end
						? dayjs(formData.startEndDate.end).format('YYYY.MM.DD')
						: dayjs().format('YYYY.MM.DD')
				}` || '';
			serviceItem.longTermNo = formData.longTermNo || '';
			serviceItem.longTermGradeNm = formData.longTermGradeCd?.label || '';
		}
		if (index === formData.service.length - 1) {
			// 마지막 급여 데이터 에 총 수가 추가
			serviceItem.serviceTotalAmt = `${serviceTotalAmt.toLocaleString()}원` || '';
		}

		return serviceItem;
	});

	const eFormData = {
		crfeNm: formData.data?.eformFileNm,
		params: [
			{
				newChk: formData.type?.value === '신규' ? '1' : '',
				renewalChk: formData.type?.value === '갱신' ? '1' : '',
				changeChk: formData.type?.value === '변경' ? '1' : '',
				terminationChk: formData.type?.value === '해지' ? '1' : '',
				consenterNm: currentSender?.userNm || '',
				signConsenterNm: '',
				consenterPhoneNo: displayPhoneNumber(currentSender?.workMobilePhoneNo) || '',
				consenterRelNm: '담당 사회복지사',
				consenterBirthDt: displayBirthDay(formData.birthDt) || '',
				consenterAddr: `${center?.centerZipCode ? `(${center?.centerZipCode}) ` : ''} ${
					center?.centerBasAddr || ''
				}${` ${formData?.data?.data?.center?.centerDetailAddr || ''}`}`,
				recipientNm: formData.recipientNm || '-',
				signRecipientNm: '',
				recipientBirthDt: displayBirthDay(recipient.birthDt) || '',
				recipientAddr: `${formData?.recipientBaseAddr || ''}${
					formData?.recipientDetailAddr ? `, ${formData?.recipientDetailAddr}` : ''
				}`,
				recipientPhoneNo: displayPhoneNumber(recipient.mobilePhoneNo) || '',
				rsdnNo: displayRdsNumber(formData.recipientRsdnNo) || '',
				longTermNo: formData.longTermNo || '-',
				longTermGradeNm: formData.longTermGradeCd?.label || '-',
				centerNm: currentRecipient.centerName || '-',
				gubunChk1: recipient.burdenRateCd === 'CMN043.10' ? '1' : '',
				gubunChk2: recipient.burdenRateCd !== 'CMN043.10' ? '1' : '',
				year: String(dayjs(formData.requestDate).get('year')),
				month: String(dayjs(formData.requestDate).get('month') + 1),
				day: String(dayjs(formData.requestDate).get('date')),
				youNm,
				service,
				welfare: [],
			} as IssueAdmissionUseRequestIssueItem,
		],
	};

	const mainGuardian = guardians.find((item) => item.mainGuardianYn);
	const result = {
		edocTitle: formData.data.edocNm,
		edocSenderId: currentSender.memberAccountId,
		edocSenderNm: currentSender.userNm,
		edocSenderPhoneNo: '',
		centerId: currentSender.centerId,
		paperTypeCd: formData?.data.paperTypeCd,
		edocParamValue: JSON.stringify(eFormData),
		signatoryIds: [recipient.recipientId, currentSender.memberAccountId],
		signatoryNms: [recipient.recipientNm || '', currentSender.userNm || ''],
		signatoryPhoneNos: [mainGuardian?.mobilePhoneNo || '', ''],
		signatoryBirthDts: [mainGuardian?.birthDt || '', ''],
		receiverTargetDivCd: 'CMN118.20',
	} as IssueEDocumentRequest;

	return result;
};

// CMS 자동이체 신청서 발급
export const eDocCMSAutoTransferFormAdapter = (
	formData: CMSAutoTransferFormTypes,
	currentRecipient?: Recipient,
	currentSender?: MyAccountInfoDTO,
	currentCenter?: CenterDTO,
) => {
	if (!formData?.data || !currentSender || !currentRecipient || !currentCenter) {
		return undefined;
	}

	const { center } = (formData.data as unknown as EDocAdmissionUseRequestDTO).data;

	const eFormData = {
		crfeNm: formData.data?.eformFileNm,
		params: [
			{
				receiptCompanyNm: center?.centerNm, // 수납업체
				receiptPurposeDesc: '장기요양 본인부담금 수납', // 수납목적
				representativeNm: center?.representativeNm, // 대표자
				bizNo: center?.bizNo, // 사업자등록번호
				bizAddr: `${center?.centerZipCode ? `(${center?.centerZipCode}) ` : ''}${
					center?.centerBasAddr || ''
				}${` ${formData?.data?.data?.center?.centerDetailAddr || ''}`}`, // 주소
				requesterNm: formData.applicantName, // 신청인
				signRequesterNm: '', // 신청인사인
				depositorRelationshipDesc: '본인', // 예금주와의관계
				phoneNo: formData.applicantPhoneNo, // 연락처
				paymentFixChk: '', // 납부금액 - 고정금액체크
				paymentChangeChk: '', // 납부금액 - 변동체크
				paymentFixAmount: '', // 납부금액 - 고정금액
				paymentEveryMonthDate: '', // 납부일 매월 _일
				nonPaymentWithdrawalDate1: '', // 미납시 _일 첫번째
				nonPaymentWithdrawalDate2: '', // 미납시 _일 두번째
				bankNm: formData.bank?.label, // 은행명
				depositorNm: formData.accountName, // 예금주
				signDepositorNm: '', // 예금주사인
				bankAccountNo1: formData.account?.slice(0, 1) || '', // 계좌번호 1
				bankAccountNo2: formData.account?.slice(1, 2) || '',
				bankAccountNo3: formData.account?.slice(2, 3) || '',
				bankAccountNo4: formData.account?.slice(3, 4) || '',
				bankAccountNo5: formData.account?.slice(4, 5) || '',
				bankAccountNo6: formData.account?.slice(5, 6) || '',
				bankAccountNo7: formData.account?.slice(6, 7) || '',
				bankAccountNo8: formData.account?.slice(7, 8) || '',
				bankAccountNo9: formData.account?.slice(8, 9) || '',
				bankAccountNo10: formData.account?.slice(9, 10) || '',
				bankAccountNo11: formData.account?.slice(10, 11) || '',
				bankAccountNo12: formData.account?.slice(11, 12) || '',
				bankAccountNo13: formData.account?.slice(12, 13) || '',
				bankAccountNo14: formData.account?.slice(13, 14) || '',
				bankAccountNo15: formData.account?.slice(14, 15) || '',
				bankAccountNo16: formData.account?.slice(15, 16) || '',
				depositorBirthDate1: formData.accountOwnerBirthday?.slice(0, 1) || '', // 예금주생년월일 1
				depositorBirthDate2: formData.accountOwnerBirthday?.slice(1, 2) || '',
				depositorBirthDate3: formData.accountOwnerBirthday?.slice(2, 3) || '',
				depositorBirthDate4: formData.accountOwnerBirthday?.slice(3, 4) || '',
				depositorBirthDate5: formData.accountOwnerBirthday?.slice(4, 5) || '',
				depositorBirthDate6: formData.accountOwnerBirthday?.slice(5, 6) || '',
				depositorBirthDate7: formData.accountOwnerBirthday?.slice(6, 7) || '',
				depositorBirthDate8: formData.accountOwnerBirthday?.slice(7, 8) || '',
				depositorBirthDate9: formData.accountOwnerBirthday?.slice(8, 9) || '',
				depositorBirthDate10: formData.accountOwnerBirthday?.slice(9, 10) || '',
				depositorPhoneNo1: formData.accountOwnerPhoneNo?.slice(0, 1) || '', // 예금주 휴대전화번호 1
				depositorPhoneNo2: formData.accountOwnerPhoneNo?.slice(1, 2) || '',
				depositorPhoneNo3: formData.accountOwnerPhoneNo?.slice(2, 3) || '',
				depositorPhoneNo4: formData.accountOwnerPhoneNo?.slice(3, 4) || '',
				depositorPhoneNo5: formData.accountOwnerPhoneNo?.slice(4, 5) || '',
				depositorPhoneNo6: formData.accountOwnerPhoneNo?.slice(5, 6) || '',
				depositorPhoneNo7: formData.accountOwnerPhoneNo?.slice(6, 7) || '',
				depositorPhoneNo8: formData.accountOwnerPhoneNo?.slice(7, 8) || '',
				depositorPhoneNo9: formData.accountOwnerPhoneNo?.slice(8, 9) || '',
				depositorPhoneNo10: formData.accountOwnerPhoneNo?.slice(9, 10) || '',
				depositorPhoneNo11: formData.accountOwnerPhoneNo?.slice(10, 11) || '',
				collectPersonalInfoAgreeChk: '1', // 개인정보이용수집 동의 체크
				collectPersonalInfoDisAgreeChk: '', // 개인정보이용수집 비동의 체크
				provisionPersonalInfoThirdPartyAgreeChk: '1', // 개인정보 제3자 제공 동의 체크
				provisionPersonalInfoThirdPartyDisAgreeChk: '', // 개인정보 제3자 제공 비동의 체크
				year: dayjs(formData.applicantDate).format('YYYY'), // 년
				month: dayjs(formData.applicantDate).format('MM'), // 월
				day: dayjs(formData.applicantDate).format('DD'), // 일
			} as IssueCMSAutoTransferIssueItem,
		],
	};

	const result = {
		edocTitle: formData.data.edocNm,
		edocSenderId: currentSender.memberAccountId,
		edocSenderNm: currentSender.userNm,
		edocSenderPhoneNo: '',
		centerId: currentSender.centerId,
		paperTypeCd: formData?.data.paperTypeCd,
		edocParamValue: JSON.stringify(eFormData),
		signatoryIds: [currentRecipient.recipientId],
		signatoryNms: [currentRecipient.name || ''],
		signatoryPhoneNos: [currentRecipient?.phoneNumber || ''],
		signatoryBirthDts: [currentRecipient?.birthday || ''],
	} as IssueEDocumentRequest;

	return result;
};
