import React, { useCallback } from 'react';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRStatus from 'components/base/CRStatus';
import CRTable from 'components/base/CRTable';
import FileUploadButton from 'components/domain/table/RecipientIntegratedAssessmentTable/FileUploadButton';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import {
	IntegratedAssessmentBedsoreInfoDTO,
	IntegratedAssessmentDTO,
	IntegratedAssessmentDesireInfoDTO,
	IntegratedAssessmentFallInfoDTO,
	IntegratedAssessmentKmmseInfoDTO,
	IntegratedAssessmentRecentSalaryOfferChangePlanDTO,
	IntegratedAssessmentSalaryOfferPlanInfoDTO,
} from 'types/dto';
import { IntegratedAssessmentType } from 'types/view/recipient';

import { RECIPIENT_INTEGRATED_ASSESSMENT_HEAD_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	items: IntegratedAssessmentDTO[];
	onClickAddIntegratedAssessmentType?: (
		index: number,
		type: IntegratedAssessmentType,
	) => () => Promise<void>;
	onClickIntegratedAssessmentType?: (
		index: number,
		type: IntegratedAssessmentType,
	) => () => Promise<void>;
	onClickChangeSalaryOfferPlan?: (salaryOfferPlanId: number) => () => Promise<void>;
	onClickUploadSalaryOfferPlan?: (seq: number, file: File) => Promise<void>;
	onClickDeleteSalaryOfferPlan?: (salaryOfferPlanId: number) => Promise<void>;
	onClickSendSalaryOfferPlan?: (salaryOfferPlanId: number) => Promise<void>;
	onClickSalaryOfferPreview?: (data: IntegratedAssessmentSalaryOfferPlanInfoDTO) => Promise<void>;
}

function RecipientIntegratedAssessmentTable({
	items = [],
	onClickAddIntegratedAssessmentType,
	onClickIntegratedAssessmentType,
	onClickChangeSalaryOfferPlan,
	onClickUploadSalaryOfferPlan,
	onClickDeleteSalaryOfferPlan,
	onClickSendSalaryOfferPlan,
	onClickSalaryOfferPreview,
}: Props): React.ReactElement {
	const hasDeleteServicePlanBeforeSigningFunc = useHasFunc([
		'recipient:delete_service_plan_before_signing',
	]);
	const hasDeleteServicePlanAfterSigningFunc = useHasFunc([
		'recipient:delete_service_plan_after_signing',
	]);
	const FallInfoComponent = useCallback(
		(item: IntegratedAssessmentFallInfoDTO | undefined, data: any) => {
			if (!item) {
				return (
					<S.CellContainer>
						<CRButton.Default
							palette='gray'
							size='xSmall'
							type='outlined'
							onClick={onClickAddIntegratedAssessmentType?.(
								data.index,
								IntegratedAssessmentType.FallInfo,
							)}>
							추가
						</CRButton.Default>
					</S.CellContainer>
				);
			}

			return (
				<S.IntegratedAssessmentListContainer
					onClick={onClickIntegratedAssessmentType?.(
						item.recipientIaId,
						IntegratedAssessmentType.FallInfo,
					)}>
					<S.IntegratedAssessmentInfo>{`${item.evalDescTypeCd} (${item.totalPoint}, ${item.writeStateCd === 'CMN085.10' ? '미확정' : '확정'})`}</S.IntegratedAssessmentInfo>
					<S.IntegratedAssessmentInfo>
						{dayjs(item.writeDate).format('YY.MM.DD')}
					</S.IntegratedAssessmentInfo>
					<S.IntegratedAssessmentInfo>{item.writerNm}</S.IntegratedAssessmentInfo>
				</S.IntegratedAssessmentListContainer>
			);
		},
		[onClickAddIntegratedAssessmentType, onClickIntegratedAssessmentType],
	);

	const BedsoreInfoComponent = useCallback(
		(item: IntegratedAssessmentBedsoreInfoDTO | undefined, data: any) => {
			if (!item) {
				return (
					<S.CellContainer>
						<CRButton.Default
							palette='gray'
							size='xSmall'
							type='outlined'
							onClick={onClickAddIntegratedAssessmentType?.(
								data.index,
								IntegratedAssessmentType.BedsoreInfo,
							)}>
							추가
						</CRButton.Default>
					</S.CellContainer>
				);
			}

			return (
				<S.IntegratedAssessmentListContainer
					onClick={onClickIntegratedAssessmentType?.(
						item.recipientIaId,
						IntegratedAssessmentType.BedsoreInfo,
					)}>
					<S.IntegratedAssessmentInfo>{`${item.evalDescTypeCd} (${item.totalPoint}, ${item.writeStateCd === 'CMN085.10' ? '미확정' : '확정'})`}</S.IntegratedAssessmentInfo>
					<S.IntegratedAssessmentInfo>
						{dayjs(item.writeDate).format('YY.MM.DD')}
					</S.IntegratedAssessmentInfo>
					<S.IntegratedAssessmentInfo>{item.writerNm}</S.IntegratedAssessmentInfo>
				</S.IntegratedAssessmentListContainer>
			);
		},
		[onClickAddIntegratedAssessmentType, onClickIntegratedAssessmentType],
	);

	const KmmseInfoComponent = useCallback(
		(item: IntegratedAssessmentKmmseInfoDTO | undefined, data: any) => {
			if (!item) {
				return (
					<S.CellContainer>
						<CRButton.Default
							palette='gray'
							size='xSmall'
							type='outlined'
							onClick={onClickAddIntegratedAssessmentType?.(
								data.index,
								IntegratedAssessmentType.KmmseInfo,
							)}>
							추가
						</CRButton.Default>
					</S.CellContainer>
				);
			}

			return (
				<S.IntegratedAssessmentListContainer
					onClick={onClickIntegratedAssessmentType?.(
						item.recipientIaId,
						IntegratedAssessmentType.KmmseInfo,
					)}>
					<S.IntegratedAssessmentInfo>{`${item.evalDescTypeCd} (${item.totalPoint}, ${item.writeStateCd === 'CMN085.10' ? '미확정' : '확정'})`}</S.IntegratedAssessmentInfo>
					<S.IntegratedAssessmentInfo>
						{dayjs(item.writeDate).format('YY.MM.DD')}
					</S.IntegratedAssessmentInfo>
					<S.IntegratedAssessmentInfo>{item.writerNm}</S.IntegratedAssessmentInfo>
				</S.IntegratedAssessmentListContainer>
			);
		},
		[onClickAddIntegratedAssessmentType, onClickIntegratedAssessmentType],
	);

	const DesireInfoComponent = useCallback(
		(item: IntegratedAssessmentDesireInfoDTO | undefined, data: any) => {
			if (!item) {
				return (
					<S.CellContainer>
						<CRButton.Default
							palette='gray'
							size='xSmall'
							type='outlined'
							onClick={onClickAddIntegratedAssessmentType?.(
								data.index,
								IntegratedAssessmentType.DesireInfo,
							)}>
							추가
						</CRButton.Default>
					</S.CellContainer>
				);
			}

			return (
				<S.IntegratedAssessmentListContainer
					onClick={onClickIntegratedAssessmentType?.(
						item.recipientIaId,
						IntegratedAssessmentType.DesireInfo,
					)}>
					<S.IntegratedAssessmentInfo>{`${item.evalDescTypeCd} (${item.writeStateCd === 'CMN085.10' ? '미확정' : '확정'})`}</S.IntegratedAssessmentInfo>
					<S.IntegratedAssessmentInfo>
						{dayjs(item.writeDate).format('YY.MM.DD')}
					</S.IntegratedAssessmentInfo>
					<S.IntegratedAssessmentInfo>{item.writerNm}</S.IntegratedAssessmentInfo>
				</S.IntegratedAssessmentListContainer>
			);
		},
		[onClickAddIntegratedAssessmentType, onClickIntegratedAssessmentType],
	);

	const SalaryOfferPlanInfoComponent = useCallback(
		(
			item: IntegratedAssessmentSalaryOfferPlanInfoDTO | undefined,
			data?: IntegratedAssessmentDTO & { index: number },
		) => {
			if (!item) {
				return (
					<S.CellContainer>
						<FileUploadButton
							onChange={(file) => onClickUploadSalaryOfferPlan?.(data?.index ?? 0, file)}
						/>
					</S.CellContainer>
				);
			}

			return (
				<S.CellContainer>
					<CRStatus
						options={[
							{
								key: '업로드 완료',
								color: 'green',
							},
						]}>
						업로드 완료
					</CRStatus>
					<S.ButtonContainer>
						{item.esignSimpResponse?.isSent ? (
							<CRButton.Default
								palette='gray'
								size='xSmall'
								type='outlined'
								onClick={() => onClickSalaryOfferPreview?.(item)}>
								미리보기
							</CRButton.Default>
						) : (
							<CRButton.Default
								palette='gray'
								size='xSmall'
								type='outlined'
								onClick={() => onClickSendSalaryOfferPlan?.(item.salaryOfferPlanId)}>
								발송
							</CRButton.Default>
						)}
						{item.esignSimpResponse?.isSent ? (
							<CRButton.Default
								disabled={!hasDeleteServicePlanAfterSigningFunc}
								size='xSmall'
								type='outlined'
								onClick={() => onClickDeleteSalaryOfferPlan?.(item.salaryOfferPlanId ?? 0)}>
								삭제
							</CRButton.Default>
						) : (
							<CRButton.Default
								disabled={
									!hasDeleteServicePlanAfterSigningFunc && !hasDeleteServicePlanBeforeSigningFunc
								}
								size='xSmall'
								type='outlined'
								onClick={() => onClickDeleteSalaryOfferPlan?.(item.salaryOfferPlanId ?? 0)}>
								삭제
							</CRButton.Default>
						)}
					</S.ButtonContainer>
				</S.CellContainer>
			);
		},
		[onClickUploadSalaryOfferPlan, onClickSendSalaryOfferPlan, onClickDeleteSalaryOfferPlan],
	);

	const RecentSalaryOfferChangePlanComponent = useCallback(
		(
			item: IntegratedAssessmentRecentSalaryOfferChangePlanDTO | undefined,
			data?: IntegratedAssessmentDTO,
		) => {
			if (!item) {
				return (
					<S.CellContainer>
						<CRButton.Default
							onClick={onClickChangeSalaryOfferPlan?.(
								data?.salaryOfferPlanInfo?.salaryOfferPlanId ?? 0,
							)}
							palette='gray'
							size='xSmall'
							type='outlined'
							disabled={!data?.salaryOfferPlanInfo}>
							추가
						</CRButton.Default>
					</S.CellContainer>
				);
			}

			return (
				<S.IntegratedAssessmentListContainer
					onClick={onClickChangeSalaryOfferPlan?.(
						data?.salaryOfferPlanInfo?.salaryOfferPlanId ?? 0,
					)}>
					<S.IntegratedAssessmentInfo>{item.salaryKindNm}</S.IntegratedAssessmentInfo>
					<S.IntegratedAssessmentInfo>
						{dayjs(item.writerDate).format('YY.MM.DD')}
					</S.IntegratedAssessmentInfo>
					<S.IntegratedAssessmentInfo>{item.writerNm}</S.IntegratedAssessmentInfo>
				</S.IntegratedAssessmentListContainer>
			);
		},
		[],
	);

	return (
		<S.Container>
			<CRTable.Root>
				<CRTable.Head heads={RECIPIENT_INTEGRATED_ASSESSMENT_HEAD_CONFIG} offset={1} />
				<CRTable.Body>
					{items.map((item, index) => (
						<CRTable.Row
							// key={index}
							item={{
								...item,
								index: index + 1,
								recentSalaryOfferChangePlan: item.salaryOfferPlanInfo?.recentSalaryOfferChangePlan,
							}}
							renderKeys={[
								'index',
								'fallInfo',
								'bedsoreInfo',
								'kmmseInfo',
								'desireInfo',
								'salaryOfferPlanInfo',
								'recentSalaryOfferChangePlan',
							]}
							customRender={{
								fallInfo: FallInfoComponent,
								bedsoreInfo: BedsoreInfoComponent,
								kmmseInfo: KmmseInfoComponent,
								desireInfo: DesireInfoComponent,
								salaryOfferPlanInfo: SalaryOfferPlanInfoComponent,
								recentSalaryOfferChangePlan: RecentSalaryOfferChangePlanComponent,
							}}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default RecipientIntegratedAssessmentTable;
