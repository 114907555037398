import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import * as Accordion from '@radix-ui/react-accordion';
import dayjs from 'dayjs';

import CRPageFooter from 'components/base/CRPageFooter';
import CRPageHeader from 'components/base/CRPageHeader';
import { Toast } from 'components/base/CRToast';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import IntegratedSalaryOfferChangeForm from 'components/domain/form/IntegratedSalaryOfferChangeForm';
import IntegratedSalaryOfferChangeRecipientInfoForm from 'components/domain/form/IntegratedSalaryOfferChangeRecipientInfoForm';
import TaskLayout from 'components/domain/layout/TaskLayout';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import { commonCodeAdapter } from 'lib/adapter/common';
import {
	useCommonCodes,
	useCreateRecipientIntegratedAssessmentSalaryOfferChangePlan,
	useDeleteRecipientIntegratedAssessmentSalaryOfferChangePlan,
	useMyAccountInfo,
	useRecipientIntegratedAssessmentSalaryOfferChangePlan,
	useUpdateRecipientIntegratedAssessmentSalaryOfferChangePlan,
} from 'lib/hook/react-query';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import useDialog from 'lib/hook/util/useDialog';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { endpoint } from 'lib/service/Api/endpoint';
import { ResponseCode } from 'types/api';
import { IntegratedAssessmentSalaryOfferPlanChangePlanListDTO } from 'types/dto';
import { SalaryChangeOfferPlanBaseForm } from 'types/view/integratedAssessment';

import { displayBirthDay } from '../../../lib';
import IntegratedAssessmentSalaryOfferList from './integratedAssessmentSalaryOfferList';
import * as S from './styles';

function IntegratedAssessmentSalaryOfferPlanInfoPage(): React.ReactElement {
	const hasDeleteServicePlanChangelogFunc = useHasFunc(['recipient:delete_service_plan_changelog']);
	const form = useForm<SalaryChangeOfferPlanBaseForm>();
	const navigate = useNavigate();
	const { showDialog, hideDialog } = useDialog();
	const { data: myAccountInfo } = useMyAccountInfo();
	const { currentRecipient, currentRecipientInfoSummary } = useRecipientPage();
	const params = useParams<{ id: string; salaryOfferPlanId: string }>();
	const [selectedItem, setSelectedItem] = useState<
		IntegratedAssessmentSalaryOfferPlanChangePlanListDTO | undefined
	>();
	const { data: commonCodes } = useCommonCodes(
		{
			comCdGroupNms: ['CMN042'],
		},
		commonCodeAdapter,
	);
	const gradeCodes = commonCodes?.CMN042 || [];

	const isEtc = form.watch('salaryOfferChangeDivCd')?.value === 'CMN173.99';

	const { data } = useRecipientIntegratedAssessmentSalaryOfferChangePlan({
		salaryOfferPlanId: params.salaryOfferPlanId ?? '',
		salaryOfferChangePlanId: selectedItem?.salaryOfferChangePlanId.toString() ?? '',
	});

	const createRecipientIntegratedAssessmentSalaryOfferChangePlan =
		useCreateRecipientIntegratedAssessmentSalaryOfferChangePlan((client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				Toast.success('정상적으로 저장을 완료하였습니다.');
				client.invalidateQueries([endpoint.getRecipientIntegratedAssessment.key]);
				client.invalidateQueries([
					endpoint.getRecipientIntegratedAssessmentSalaryOfferChangePlans.key,
					{ salaryOfferPlanId: params.salaryOfferPlanId ?? '' },
				]);
			} else {
				Toast.error('저장에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		});

	const updateRecipientIntegratedAssessmentSalaryOfferChangePlan =
		useUpdateRecipientIntegratedAssessmentSalaryOfferChangePlan((client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				Toast.success('정상적으로 저장을 완료하였습니다.');
				client.invalidateQueries([endpoint.getRecipientIntegratedAssessment.key]);
				client.invalidateQueries([
					endpoint.getRecipientIntegratedAssessmentSalaryOfferChangePlans.key,
					{ salaryOfferPlanId: params.salaryOfferPlanId ?? '' },
				]);
			} else {
				Toast.error('저장에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		});

	const deleteRecipientIntegratedAssessmentSalaryOfferChangePlan =
		useDeleteRecipientIntegratedAssessmentSalaryOfferChangePlan((client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				Toast.success('정상적으로 삭제을 완료하였습니다.');
				client.invalidateQueries([endpoint.getRecipientIntegratedAssessment.key]);
				client.invalidateQueries([
					endpoint.getRecipientIntegratedAssessmentSalaryOfferChangePlans.key,
					{ salaryOfferPlanId: params.salaryOfferPlanId ?? '' },
				]);
				setSelectedItem(undefined);
				hideDialog();
			} else {
				Toast.error('삭제에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		});

	const handleClickDelete = async () => {
		if (!params.salaryOfferPlanId) return;

		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='문서 삭제'
				hideDialog={hideDialog}
				content='선택된 급여제공변경계획서 문서를 삭제하시겠습니까?'
				cancelOption={{
					text: '취소',
					callback: hideDialog,
				}}
				successOption={{
					text: '삭제',
					type: 'outlined',
					successCallback: async () => {
						await deleteRecipientIntegratedAssessmentSalaryOfferChangePlan.mutateAsync({
							salaryOfferPlanId: params.salaryOfferPlanId ?? '',
							salaryOfferChangePlanId: selectedItem?.salaryOfferChangePlanId.toString() ?? '',
						});
					},
				}}
			/>
		));
	};

	const handleClickSave = async () => {
		if (!myAccountInfo) return;
		if (!params.salaryOfferPlanId) return;
		const param = {
			...data,
			salaryOfferPlanId: params.salaryOfferPlanId ?? '',
			salaryOfferChangePlanId: selectedItem?.salaryOfferChangePlanId.toString() ?? '',
			salaryKindCd: form.getValues('serviceType')?.[0]?.value,
			salaryDivCdList: form.getValues('salaryDivCdList')?.map((item) => item.value),
			etcSalaryDivNm: form.getValues('etcSalaryDivNm'),
			writeDate: dayjs(form.getValues('date')).format('YYYY-MM-DD HH:mm:ss'),
			writerId: myAccountInfo.memberAccountId,
			salaryOfferChangeDivCd: form.getValues('salaryOfferChangeDivCd')?.value,
			opinionDesc: form.getValues('opinionDesc'),
			salaryDesc: form.getValues('salaryDesc'),
			changeDesc: form.getValues('changeDesc'),
			longTermNo: data?.longTermNo ?? '',
		};

		if (isEtc) {
			param.etcSalaryOfferChangeDivNm = form.getValues('etcSalaryOfferChangeDivNm');
		}

		if (selectedItem) {
			if (!updateRecipientIntegratedAssessmentSalaryOfferChangePlan.isLoading) {
				await updateRecipientIntegratedAssessmentSalaryOfferChangePlan.mutateAsync(param);
			}
		} else if (!createRecipientIntegratedAssessmentSalaryOfferChangePlan.isLoading) {
			await createRecipientIntegratedAssessmentSalaryOfferChangePlan.mutateAsync({
				...param,
				recipientNm: currentRecipientInfoSummary.name || '',
				recipientBirthDt: currentRecipient?.birthday || '',
				recipientGenderCd: currentRecipientInfoSummary.genderCd || '',
				longTermGradeCd: currentRecipientInfoSummary.longTermGradeCd,
			});
		}
	};

	const handleClickBack = () => {
		navigate(-1);
	};

	const handleClickItem = (item: IntegratedAssessmentSalaryOfferPlanChangePlanListDTO) => {
		setSelectedItem(item);
	};

	const forms = [
		{
			id: `#1`,
			label: '수급자 정보',
			component: (
				<IntegratedSalaryOfferChangeRecipientInfoForm
					item={{
						name: currentRecipientInfoSummary.name || '',
						gender: currentRecipientInfoSummary.genderCd === 'CMN013.MALE' ? '남자' : '여자',
						birthday: displayBirthDay(currentRecipient?.birthday, true),
						longTermNo: currentRecipientInfoSummary.longTermNo,
						longTermGrade:
							gradeCodes?.find(
								(item) => item?.value === currentRecipientInfoSummary.longTermGradeCd,
							)?.label || '-',
					}}
				/>
			),
		},
		{
			id: `#2`,
			label: '급여제공 변경, 반영 정보',
			component: <IntegratedSalaryOfferChangeForm form={form} />,
		},
	];

	useEffect(() => {
		if (data) {
			form.reset({
				date: data?.writeDate ? dayjs(data?.writeDate).toDate() : undefined,
				salaryKindCd: { label: '', value: data.salaryKindCd },
				salaryDivCdList: data.salaryDivCdList.map((value) => ({ label: '', value })),
				serviceType: [{ label: '', value: data.salaryKindCd }],
				salaryOfferChangeDivCd: data.salaryOfferChangeDivCd
					? { label: '', value: data.salaryOfferChangeDivCd }
					: undefined,
				etcSalaryOfferChangeDivNm: data.etcSalaryOfferChangeDivNm,
				opinionDesc: data.opinionDesc,
				salaryDesc: data.salaryDesc,
				changeDesc: data.changeDesc,
				etcSalaryDivNm: data.etcSalaryDivNm,
			});
		} else {
			form.reset({
				date: undefined,
				salaryKindCd: undefined,
				salaryDivCdList: undefined,
				serviceType: undefined,
				salaryOfferChangeDivCd: undefined,
				etcSalaryOfferChangeDivNm: '',
				opinionDesc: '',
				salaryDesc: '',
				changeDesc: '',
				etcSalaryDivNm: '',
			});
		}
	}, [data]);

	useEffect(() => {
		if (form.watch('salaryOfferChangeDivCd')?.value && !isEtc) {
			form.setValue('etcSalaryOfferChangeDivNm', '');
		}
	}, [isEtc]);

	return (
		<TaskLayout
			LeftSideComponent={
				<IntegratedAssessmentSalaryOfferList
					selectedItem={selectedItem}
					onClick={handleClickItem}
				/>
			}>
			<S.MainComponentContainer>
				<S.BodyContainer>
					<S.ContentContainer>
						<S.HeaderComponentContainer>
							<CRPageHeader
								headerTitle='급여제공변경계획서'
								buttons={
									selectedItem
										? [
												{
													palette: 'primary',
													buttonType: 'button',
													type: 'outlined',
													onClick: handleClickDelete,
													disabled: !hasDeleteServicePlanChangelogFunc,
													children: '삭제',
												},
											]
										: []
								}
							/>
						</S.HeaderComponentContainer>
						<Accordion.Root type='multiple' defaultValue={forms.map((form) => form.id)}>
							{forms.map((form) => (
								<Accordion.Item value={form.id} asChild>
									<TaskAccordion.Item>
										<Accordion.Header asChild>
											<Accordion.Trigger asChild>
												<TaskAccordion.Trigger href={form.id}>{form.label}</TaskAccordion.Trigger>
											</Accordion.Trigger>
										</Accordion.Header>
										<Accordion.Content asChild>
											<TaskAccordion.Content>{form.component}</TaskAccordion.Content>
										</Accordion.Content>
									</TaskAccordion.Item>
								</Accordion.Item>
							))}
						</Accordion.Root>
					</S.ContentContainer>
				</S.BodyContainer>
				<S.FooterContainer>
					<S.FooterContentContainer>
						<CRPageFooter
							rightButtons={[
								{
									palette: 'gray',
									buttonType: 'button',
									type: 'text',
									onClick: handleClickBack,
									children: '취소',
								},
								{
									palette: 'primary',
									buttonType: 'button',
									type: 'filled',
									onClick: handleClickSave,
									children: '저장',
								},
							]}
						/>
					</S.FooterContentContainer>
				</S.FooterContainer>
			</S.MainComponentContainer>
		</TaskLayout>
	);
}

export default IntegratedAssessmentSalaryOfferPlanInfoPage;
