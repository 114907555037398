import React, { useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import Colors from 'common/colors';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { IntegratedAssessmentFallForms } from 'types/view/recipient';

import IntegratedFallFormHeader from './IntegratedFallFormHeader';
import IntegratedFallFormScoreInfo from './IntegratedFallFormScoreInfo';
import { integratedAssessmentFallQuestions } from './constant';
import * as S from './styles';

interface Props {
	form: UseFormReturn<IntegratedAssessmentFallForms, any, undefined>;
	editable: boolean;
}

function IntegratedFallForm({ form, editable }: Props): React.ReactElement {
	const { control, watch } = form;

	const totalPoint = useMemo(
		() =>
			watch('연령') +
			watch('정신상태') +
			watch('배변') +
			watch('낙상경험') +
			watch('활동상태') +
			watch('걸음걸이및균형') +
			watch('약물'),
		[watch()],
	);

	return (
		<S.Container>
			<S.Table>
				<IntegratedFallFormHeader />
				<S.TableBody>
					{integratedAssessmentFallQuestions.map((question) => (
						<Controller
							render={({ field: { ref, onChange, value }, formState: { errors } }) => (
								<S.TableBodyRow>
									<S.TableBodyColumn
										$hasValue
										style={{
											borderRight: `0.4rem solid ${Colors.gray90}`,
											background: Colors.gray99,
										}}>
										{question.label}
									</S.TableBodyColumn>
									{question.answer.map((answer, index) => (
										<S.TableBodyColumn
											onClick={
												answer.description.length && editable
													? () => onChange(answer.point)
													: undefined
											}
											$selected={value === answer.point}
											$disabled={!editable}
											$hasValue={!!answer.description.length}>
											{answer.description}
										</S.TableBodyColumn>
									))}
									<S.TableBodyColumn $hasValue>{watch(question.key) as string}</S.TableBodyColumn>
								</S.TableBodyRow>
							)}
							name={question.key}
							control={control}
						/>
					))}
					<S.TableBodyTotalRow>
						<S.TableBodyTotalColumn colSpan={6}>총점</S.TableBodyTotalColumn>
						<S.TableBodyTotalColumn>{totalPoint}</S.TableBodyTotalColumn>
					</S.TableBodyTotalRow>
				</S.TableBody>
			</S.Table>
			<IntegratedFallFormScoreInfo />
			<Controller
				render={({ field: { ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='비고'>
						<CRInput.TextArea
							ref={ref}
							disabled={!editable}
							placeholder='비고 입력'
							onChange={onChange}
							value={value}
						/>
					</CRInputLabel>
				)}
				name='낙상비고'
				control={control}
			/>
		</S.Container>
	);
}

export default IntegratedFallForm;
