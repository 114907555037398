import styled from 'styled-components';

export const Container = styled.div`
	height: 100%;
	overflow: overlay;
`;

export const RecipientCustomContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.8rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.4rem;
	width: 100%;

	tr:has(&) td:last-child > div {
		display: none;
	}

	tr:has(&):hover td:last-child > div {
		display: flex;
	}
`;

export const OptionContainer = styled.div`
	display: flex;
	align-items: center;
	min-height: 3.3rem;
	gap: 0.4rem;

	tr:has(&) td:last-child > div {
		display: none;
	}

	tr:has(&):hover td:last-child > div {
		display: flex;
	}
`;

export const TextBox = styled.div`
	${(props) => props.theme.typography.label};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const DialogTextContainer = styled.div`
	padding: 0 2.4rem 1.6rem;
`;

export const NonVisitAlertButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 0rem 2.4rem;
	gap: 1.6rem;

	& > * {
		user-select: auto;
	}
`;

export const DialogButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;
