import React, { ChangeEvent } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { IntegratedAssessmentDesireForms } from 'types/view/recipient';

import CRCheckBox from '../../../base/Selections/CRCheckBox';
import IntegratedAssessmentDesireRehabilitationStatusFormHeader from './IntegratedAssessmentDesireRehabilitationStatusFormHeader';
import { integratedAssessmentMajorIllnessStatusQuestions } from './constant';
import * as S from './styles';

interface Props {
	form: UseFormReturn<IntegratedAssessmentDesireForms>;
	showGenerateRequiredFields?: boolean;
	enabled?: boolean;
}

function IntegratedAssessmentDesireRehabilitationStatusForm({
	form,
	showGenerateRequiredFields,
	enabled,
}: Props): React.ReactElement {
	return (
		<S.Container>
			<S.Table>
				<IntegratedAssessmentDesireRehabilitationStatusFormHeader />
				<S.TableBody>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => (
							<S.TableBodyRow>
								<S.TableBodyColumn style={{ width: '13.2rem' }}>
									{integratedAssessmentMajorIllnessStatusQuestions[0].label}
								</S.TableBodyColumn>
								<S.TableBodyColumn>
									<S.TableContentContainer>
										{(integratedAssessmentMajorIllnessStatusQuestions[0].answer ?? []).map(
											(answer) => (
												<CRCheckBox
													disabled={!enabled}
													checkBoxType='checkbox'
													checked={form
														.watch(integratedAssessmentMajorIllnessStatusQuestions[0].key)
														?.includes(answer.value)}
													onChange={(event: ChangeEvent<HTMLInputElement>) => {
														const targetValue = value as string[];

														if (event.target.checked) {
															onChange([...(targetValue ?? []), answer.value]);
														} else {
															onChange((targetValue ?? []).filter((item) => item !== answer.value));
														}
													}}>
													{answer.label}
												</CRCheckBox>
											),
										)}
									</S.TableContentContainer>
								</S.TableBodyColumn>
							</S.TableBodyRow>
						)}
						name={integratedAssessmentMajorIllnessStatusQuestions[0].key}
						control={form.control}
					/>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => (
							<S.TableBodyRow>
								<S.TableBodyColumn style={{ width: '13.2rem' }}>
									{integratedAssessmentMajorIllnessStatusQuestions[1].label}
								</S.TableBodyColumn>
								<S.TableBodyColumn>
									<S.TableContentContainer>
										{(integratedAssessmentMajorIllnessStatusQuestions[1].answer ?? []).map(
											(answer) => (
												<CRCheckBox
													disabled={!enabled}
													checkBoxType='checkbox'
													checked={form
														.watch(integratedAssessmentMajorIllnessStatusQuestions[1].key)
														?.includes(answer.value)}
													onChange={(event: ChangeEvent<HTMLInputElement>) => {
														const targetValue = value as string[];

														if (event.target.checked) {
															onChange([...(targetValue ?? []), answer.value]);
														} else {
															onChange((targetValue ?? []).filter((item) => item !== answer.value));
														}
													}}>
													{answer.label}
												</CRCheckBox>
											),
										)}
									</S.TableContentContainer>
								</S.TableBodyColumn>
							</S.TableBodyRow>
						)}
						name={integratedAssessmentMajorIllnessStatusQuestions[1].key}
						control={form.control}
					/>
					<S.TableBodyRow>
						<S.TableBodyColumn style={{ width: '13.2rem' }} rowSpan={2}>
							{integratedAssessmentMajorIllnessStatusQuestions[2].label}
						</S.TableBodyColumn>
						<S.TableBodyColumn>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => (
									<S.TableContentContainer>
										지난 3개월 간 낙상 경험 (
										{(integratedAssessmentMajorIllnessStatusQuestions[2].answer ?? []).map(
											(answer) => (
												<CRCheckBox
													disabled={!enabled}
													checkBoxType='checkbox'
													checked={form
														.watch(integratedAssessmentMajorIllnessStatusQuestions[2].key)
														?.includes(answer.value)}
													onChange={(event: ChangeEvent<HTMLInputElement>) => {
														const targetValue = value as string[];

														if (event.target.checked) {
															onChange([...(targetValue ?? []), answer.value]);
														} else {
															onChange((targetValue ?? []).filter((item) => item !== answer.value));
														}
													}}>
													{answer.label}
												</CRCheckBox>
											),
										)}
										)
									</S.TableContentContainer>
								)}
								name={integratedAssessmentMajorIllnessStatusQuestions[2].key}
								control={form.control}
							/>
						</S.TableBodyColumn>
					</S.TableBodyRow>
					<S.TableBodyRow>
						<S.TableBodyColumn>
							<S.TableContentContainer>
								<Controller
									render={({ field: { ref, onChange, value }, formState: { errors } }) => (
										<S.TableContentContainer>
											걸음걸이 및 균형(
											{(integratedAssessmentMajorIllnessStatusQuestions[3].answer ?? []).map(
												(answer) => (
													<CRCheckBox
														disabled={!enabled}
														checkBoxType='checkbox'
														checked={form
															.watch(integratedAssessmentMajorIllnessStatusQuestions[3].key)
															?.includes(answer.value)}
														onChange={(event: ChangeEvent<HTMLInputElement>) => {
															const targetValue = value as string[];

															if (event.target.checked) {
																onChange([...(targetValue ?? []), answer.value]);
															} else {
																onChange(
																	(targetValue ?? []).filter((item) => item !== answer.value),
																);
															}
														}}>
														{answer.label}
													</CRCheckBox>
												),
											)}
											)
										</S.TableContentContainer>
									)}
									name={integratedAssessmentMajorIllnessStatusQuestions[3].key}
									control={form.control}
								/>
							</S.TableContentContainer>
						</S.TableBodyColumn>
					</S.TableBodyRow>
				</S.TableBody>
			</S.Table>
			<Controller
				render={({ field: { ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='판단 근거' isRequired={showGenerateRequiredFields}>
						<CRInput.TextArea
							ref={ref}
							disabled={!enabled}
							placeholder='판단 근거 입력'
							onChange={onChange}
							value={value}
						/>
					</CRInputLabel>
				)}
				name='재활상태판단근거'
				control={form.control}
			/>
		</S.Container>
	);
}

export default IntegratedAssessmentDesireRehabilitationStatusForm;
