import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import CRSpinner from 'components/base/CRSpinner';
import CRTable from 'components/base/CRTable';
import CRTableHeader from 'components/base/CRTableHeader';
import { CheckOption } from 'components/base/Selections/type';
import OwnExpensePayDetailDialog from 'components/domain/dialog/OwnExpensePayDetailDialog';
import OwnExpensePayHistoryTable from 'components/domain/table/OwnExpensePayHistoryTable';
import { commonCodeAdapter } from 'lib/adapter/common';
import { ownExpenseHistoriesAdapter } from 'lib/adapter/own-expense';
import {
	useBurdenPayHistories,
	useCenterManagers,
	useCommonCodes,
	useMyAccountInfo,
} from 'lib/hook/react-query';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import usePageFilter from 'lib/hook/util/usePageFilter';
import { PageInfo } from 'types/view/base';
import { Filter } from 'types/view/filter';
import { OwnExpensePayHistory } from 'types/view/ownExpense';

import * as S from './styles';

function OwnExpensePayHistoryTab(): React.ReactElement {
	const [keyword, setKeyword] = useState('');
	const {
		filters: currentFilter,
		setFilters: setCurrentFilter,
		pageInfo,
		setPageInfo,
	} = usePageFilter({});
	const { showDialog } = useGlobalLayout();
	const [searchValue, setSearchValue] = useState('');
	const {
		data: commonCodes = {
			CMN041: [],
		},
	} = useCommonCodes({ comCdGroupNms: ['CMN041', 'CMN135'] }, commonCodeAdapter);

	const handleClickPayHistoryItem = (item: OwnExpensePayHistory) => {
		showDialog(({ hideDialog }) => (
			<OwnExpensePayDetailDialog item={item} onClickClose={hideDialog} />
		));
	};
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: centerManagers } = useCenterManagers({
		centerId: myAccountInfo?.centerId,
	});
	const {
		data: burdenHistories,
		isLoading: burdenHistoriesLoading,
		refetch,
	} = useBurdenPayHistories(
		{
			centerId: myAccountInfo?.centerId,
			burdenAmtPayMethodCds: currentFilter?.payMethodFilter?.map((item) => item.value),
			burdenAmtPayStateCds: currentFilter?.payStateFilter?.map((item) => item.value),
			cashReceiptIssueYns: currentFilter?.cashRecipientFilter?.map((item) => item.value),
			managerIds: currentFilter?.managerFilter?.map((item) => item.value),
			keyword,
			page: pageInfo.page,
			size: pageInfo.size,
		},
		ownExpenseHistoriesAdapter,
	);

	const managerFilter = useMemo(
		() =>
			({
				key: 'managerFilter',
				type: 'multi',
				options:
					centerManagers?.map((item) => ({
						label: item.userNm,
						value: item.memberAccountId,
					})) ?? [],
				placeholder: '사회복지사',
			}) as Filter<CheckOption>,
		[centerManagers],
	);

	const payMethodFilter = useMemo(
		() =>
			({
				key: 'payMethodFilter',
				type: 'multi',
				placeholder: '납부 방식',
				options: commonCodes?.CMN041 || [],
			}) as Filter<CheckOption>,
		[commonCodes?.CMN041],
	);

	const cashRecipientFilter = useMemo(
		() =>
			({
				key: 'cashRecipientFilter',
				type: 'multi',
				placeholder: '현금영수증',
				options: [
					{
						label: '대상자',
						value: true,
					},
					{
						label: '비대상자',
						value: false,
					},
				],
			}) as Filter<CheckOption>,
		[commonCodes?.CMN041],
	);

	const payStateFilter = useMemo(
		() =>
			({
				key: 'payStateFilter',
				type: 'multi',
				placeholder: '납부 상태',
				options: commonCodes?.CMN135 || [],
			}) as Filter<CheckOption>,
		[commonCodes?.CMN135],
	);

	const handleSearchValue = (value: string) => setSearchValue(value);

	const handleSearch = () => {
		setPageInfo((prev) => ({
			...prev,
			page: 1,
		}));
		setKeyword(searchValue);
	};

	const handleChangePageInfo = (pageInfo: PageInfo) => {
		setPageInfo(pageInfo);
	};

	const handleRefresh = () => {
		refetch();
	};

	const renderEmpty = useMemo(
		() => (
			<S.EmptyContainer>
				<S.EmptyTitle>본인부담금 정보가 없습니다.</S.EmptyTitle>
				<S.EmptyDescription>
					본인부담금이 아직 생성되지 않았습니다.
					<br />
					센터장에게 요청해 주세요.
				</S.EmptyDescription>
			</S.EmptyContainer>
		),
		[],
	);

	useEffect(() => {
		if (burdenHistories?.totalElements !== undefined) {
			setPageInfo((prev) => ({
				...prev,
				totalPages: burdenHistories?.totalElements
					? Math.ceil(burdenHistories.totalElements / prev.size)
					: 0,
			}));
		}
	}, [burdenHistories?.totalElements]);

	// 사회복지사 기본필터를 자신으로 설정
	useLayoutEffect(() => {
		if (myAccountInfo && myAccountInfo?.positionCd === 'CMN103.10') {
			setCurrentFilter((prev) => {
				const alreadyManagerExist = Boolean(
					prev?.managerFilter?.find((manager) => manager.value === myAccountInfo.memberAccountId),
				);
				if (alreadyManagerExist) {
					return prev;
				}
				return {
					...prev,
					managerFilter: [
						...(prev?.managerFilter || []),
						{ value: myAccountInfo.memberAccountId, label: myAccountInfo.userNm },
					],
				};
			});
		}
	}, [myAccountInfo]);

	return (
		<CRTable.BackBlind>
			<CRTableHeader
				onChangePageInfo={handleChangePageInfo}
				pageInfo={pageInfo}
				currentFilter={currentFilter}
				stickyMode
				filters={[payMethodFilter, payStateFilter, cashRecipientFilter, managerFilter]}
				searchValue={searchValue}
				placeholder='수급자명으로 검색'
				onChangeSearchValue={handleSearchValue}
				setCurrentFilter={setCurrentFilter}
				onSearch={handleSearch}
				showRefresh
				showViewCount
				onRefresh={handleRefresh}
			/>
			{burdenHistoriesLoading ? (
				<CRSpinner />
			) : burdenHistories?.content && burdenHistories.content.length > 0 ? (
				<OwnExpensePayHistoryTable
					items={burdenHistories?.content}
					onClickItem={handleClickPayHistoryItem}
				/>
			) : (
				renderEmpty
			)}
		</CRTable.BackBlind>
	);
}

export default OwnExpensePayHistoryTab;
