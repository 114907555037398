import dayjs from 'dayjs';

import { displayBirthDay, displayBizNo, displayPhoneNumber, displayRdsNumber } from 'lib';
import { MyAccountInfoDTO } from 'types/api/auth';
import { EDocPledgeDTO, EDocResignDTO, IssueEDocumentRequest } from 'types/api/eDoc';
import { EmployeeBaseInfoDTO, EmployeeDTO } from 'types/api/employee';
import {
	CareerFormTypes,
	DeviceUnusedApplicationFormTypes,
	EDocDeviceUnusedApplicationIssueItem,
	EDocForeignConfirmIssueItem,
	EDocForeignRequestConfirmIssueItem,
	EDocHealthInsuranceAcqCancelIssueItem,
	EDocHealthInsuranceLossCancelIssueItem,
	EDocOtherBankBookIssueItem,
	EDocPledgeIssueItem,
	EDocResignIssueItem,
	EDocWithOutSignIssueItem,
	EmploymentCertificationFormTypes,
	ForeignConfirmFormTypes,
	ForeignRequestConfirmFormTypes,
	HealthInsuranceAcqCancelFormTypes,
	HealthInsuranceLossCancelFormTypes,
	OtherBankBookFormTypes,
	PledgeFormTypes,
	ResignFormTypes,
} from 'types/view/eDoc';
import { InsCancelCds } from 'types/view/insurance';

// 재직증명서
export const eDocEmploymentCertFormAdapter = (
	formData: EmploymentCertificationFormTypes,
	currentEmployee: EmployeeDTO,
	currentSender?: MyAccountInfoDTO,
	stampImageData = '',
) => {
	if (!formData.data?.data.employee || !currentSender || !currentEmployee) {
		return undefined;
	}
	const {
		data: {
			data: { employee },
		},
	} = formData;

	const eFormData = {
		crfeNm: formData.data?.eformFileNm,
		params: employee?.map((item, index) => {
			const data: EDocWithOutSignIssueItem = {
				companyNm: item.centerNm,
				assignTaskNm: item?.dutyCdNm,
				workStartEndDate: `${dayjs(formData.workStartDate).format('YYYY.MM.DD')} ~ 현재`,
				issueDescNm: formData.issueReasonEtcDescription || formData.issueReason[0].label,
			};
			if (index === 0) {
				data.nm = currentEmployee.korMemberNm;
				data.birthDt = displayBirthDay(currentEmployee.birthDt);
				data.workStartDate = formData.workStartDate
					? dayjs(formData.workStartDate).format('YYYY-MM-DD')
					: '';
			}
			if (index === employee.length - 1) {
				data.year = String(dayjs().get('year'));
				data.month = String(dayjs().get('month') + 1);
				data.day = String(dayjs().get('date'));
				data.corpNm = item.centerNm;
				data.corpStampImageData = stampImageData;
			}

			return data;
		}),
	};

	const result: IssueEDocumentRequest = {
		edocTitle: formData.data.edocNm,
		edocSenderId: currentSender.memberAccountId,
		edocSenderNm: currentSender.userNm,
		edocSenderPhoneNo: '',
		centerId: currentSender.centerId,
		paperTypeCd: formData.data.paperTypeCd,
		edocParamValue: JSON.stringify(eFormData),
		edocReceiverPhoneNo: currentEmployee.mobilePhoneNo,
		edocReceiverId: currentEmployee.employeeId,
		edocReceiverNm: currentEmployee.korMemberNm,
		edocReceiverBirthDt: currentEmployee.birthDt,
		receiverTargetDivCd: 'CMN118.30',
	};

	return result;
};

// 경력증명서
export const eDocWithOutSignIssueFormAdapter = (
	formData: CareerFormTypes,
	currentEmployee: EmployeeDTO,
	currentSender?: MyAccountInfoDTO,
	stampImageData = '',
) => {
	if (!formData.data?.data.employee || !currentSender || !currentEmployee) {
		return undefined;
	}
	const {
		data: {
			data: { employeeServices = [] },
		},
	} = formData;

	const eFormData = {
		crfeNm: formData.data?.eformFileNm,
		params: employeeServices.map((item, index) => {
			const data: EDocWithOutSignIssueItem = {
				companyNm: item.centerNm,
				assignTaskNm: item?.dutyCdNm,
				workStartEndDate: item?.contractPeriod,
				issueDescNm: formData.issueReasonEtcDescription || formData.issueReason[0].label,
			};
			if (index === 0) {
				data.nm = currentEmployee.korMemberNm;
				data.birthDt = displayBirthDay(currentEmployee.birthDt);
				data.workStartDate = item?.contractStartDate
					? dayjs(item?.contractStartDate).format('YYYY-MM-DD')
					: '';
			}
			if (index === employeeServices.length - 1) {
				data.year = String(dayjs().get('year'));
				data.month = String(dayjs().get('month') + 1);
				data.day = String(dayjs().get('date'));
				data.corpNm = item.centerNm;
				data.corpStampImageData = stampImageData;
			}

			return data;
		}),
	};

	const result: IssueEDocumentRequest = {
		edocTitle: formData.data.edocNm,
		edocSenderId: currentSender.memberAccountId,
		edocSenderNm: currentSender.userNm,
		edocSenderPhoneNo: '',
		centerId: currentSender.centerId,
		paperTypeCd: formData.data.paperTypeCd,
		edocParamValue: JSON.stringify(eFormData),
		edocReceiverPhoneNo: currentEmployee.mobilePhoneNo,
		edocReceiverId: currentEmployee.employeeId,
		edocReceiverNm: currentEmployee.korMemberNm,
		edocReceiverBirthDt: currentEmployee.birthDt,
		receiverTargetDivCd: 'CMN118.30',
	};

	return result;
};

export const eDocPledgeIssueFormAdapter = (
	formData: PledgeFormTypes,
	currentEmployee: EmployeeDTO,
	currentSender?: MyAccountInfoDTO,
) => {
	if (!formData.data?.data.employee || !currentSender || !currentEmployee) {
		return undefined;
	}

	const { employee } = (formData.data as unknown as EDocPledgeDTO).data;

	const eFormData = {
		crfeNm: formData.data?.eformFileNm,
		params: [
			{
				nm: employee.korMemberNm,
				signNm: '',
				birthDt: displayBirthDay(employee.birthDt),
				workStartDate: dayjs(employee.accidentInsuranceAcqDate).format('YYYY.MM.DD'),
				centerNm: employee.centerNm,
				recipientNm: formData.recipient.label,
				content: formData.pledgeContent,
				year: String(dayjs().get('year')),
				month: String(dayjs().get('month') + 1),
				day: String(dayjs().get('date')),
			} as EDocPledgeIssueItem,
		],
	};

	const result = {
		edocTitle: formData.data.edocNm,
		edocSenderId: currentSender.memberAccountId,
		edocSenderNm: currentSender.userNm,
		edocSenderPhoneNo: '',
		centerId: currentSender.centerId,
		paperTypeCd: formData.data.paperTypeCd,
		edocParamValue: JSON.stringify(eFormData),
		signatoryIds: [employee.employeeId, currentSender.memberAccountId],
		signatoryNms: [employee.korMemberNm || '', currentSender.userNm || ''],
		signatoryPhoneNos: [employee.mobilePhoneNo || '', ''],
		signatoryBirthDts: [employee.birthDt || '', ''],
		receiverTargetDivCd: 'CMN118.30',
	} as IssueEDocumentRequest;

	return result;
};

// 외국인고용보험 확인서
export const eDocForeignConfirmIssueFormAdapter = (
	formData: ForeignConfirmFormTypes,
	currentEmployee: EmployeeDTO,
	currentSender?: MyAccountInfoDTO,
	stampImageData = '',
) => {
	if (!currentSender || !currentEmployee) {
		return undefined;
	}

	const eFormData = {
		crfeNm: formData.data?.eformFileNm,
		params: [
			{
				korNm: formData?.korNm || '',
				signKorNm: '',
				engNm: formData?.engNm || '',
				rsdnNo: displayRdsNumber(formData?.rsdnNo),
				birthDt: displayBirthDay(formData?.birthDt),
				genderNm: formData.gender?.[0]?.value === '남' ? '1.남' : '2.여' || '',
				countryNm: formData?.countryNm || '',
				stayQualificationNm: formData?.stayQualificationNm || '',
				representativeNm: '김태성',
				caringStampImageData: stampImageData,
				date: formData.date ? dayjs(formData.date).format('YYYY.MM.DD') : '',
				subscribeChk: formData.joinType?.[0]?.value === true ? '1' : '0',
				unsubscribeChk: formData.joinType?.[0]?.value === false ? '1' : '0',
			} as EDocForeignConfirmIssueItem,
		],
	};

	const result = {
		edocTitle: formData?.data?.edocNm,
		edocSenderId: currentSender.memberAccountId,
		edocSenderNm: currentSender.userNm,
		edocSenderPhoneNo: '',
		centerId: currentSender.centerId,
		paperTypeCd: formData?.data?.paperTypeCd,
		edocParamValue: JSON.stringify(eFormData),
		signatoryIds: [currentEmployee.employeeId],
		signatoryNms: [currentEmployee.korMemberNm],
		signatoryPhoneNos: [currentEmployee?.mobilePhoneNo || ''],
		signatoryBirthDts: [currentEmployee?.birthDt || ''],
	} as IssueEDocumentRequest;

	return result;
};

// 외국인고용보험 신청서 및 확인서
export const eDocForeignRequestConfirmIssueFormAdapter = (
	formData: ForeignRequestConfirmFormTypes,
	currentEmployee: EmployeeDTO,
	currentSender?: MyAccountInfoDTO,
	stampImageData = '',
) => {
	if (!formData?.data?.data?.center || !currentSender || !currentEmployee) {
		return undefined;
	}

	const centerData = formData.data.data.center;

	const eFormData = {
		crfeNm: formData.data?.eformFileNm,
		params: [
			{
				korNm: formData?.korNm || '',
				signKorNm: '',
				engNm: formData?.engNm || '',
				rsdnNo: displayRdsNumber(formData?.rsdnNo),
				birthDt: displayBirthDay(formData?.birthDt),
				genderNm: formData.gender?.[0]?.value === '남' ? '1.남' : '2.여' || '',
				countryNm: formData?.countryNm || '',
				stayQualificationNm: formData?.stayQualificationNm || '',
				representativeNm: '김태성',
				bizNo: centerData.bizNo || '',
				maleChk: formData.gender?.[0]?.value === '남' ? '1' : '',
				femaleChk: formData.gender?.[0]?.value === '여' ? '1' : '',
				dutySign: formData.dutyNm?.value || '',
				joinDate: formData.joinDate ? dayjs(formData.joinDate).format('YYYY.MM.DD') : '',
				weekPerWorkTime: formData?.weekPerWorkTime ? `${formData?.weekPerWorkTime} 시간` : '',
				dailyWorkerYChk: '',
				dailyWorkerNChk: '',
				monthlyAvgSalaryAmt: formData?.monthlyAvgSalaryAmt
					? Number(formData.monthlyAvgSalaryAmt).toLocaleString()
					: '',
				paySumAmtLastYear: formData?.paySumAmtLastYear
					? Number(formData.paySumAmtLastYear).toLocaleString()
					: '',
				paySumAmtCurrentYear: formData?.paySumAmtCurrentYear
					? Number(formData.paySumAmtCurrentYear).toLocaleString()
					: '',
				year: formData.date ? dayjs(formData.date).format('YYYY') : '',
				month: formData.date ? dayjs(formData.date).format('MM') : '',
				day: formData.date ? dayjs(formData.date).format('DD') : '',
				centerNm: centerData?.centerNm || '',
				centerAddr: `${centerData?.centerBasAddr} ${centerData?.centerDetailAddr || ''}` || '',
				centerPhoneNo: centerData.centerReprPhoneNo || '',
				caringStampImageData: stampImageData,
				date: formData.date ? dayjs(formData.date).format('YYYY.MM.DD') : '',
				subscribeChk: formData.joinType?.[0]?.value === true ? '1' : '0',
				unsubscribeChk: formData.joinType?.[0]?.value === false ? '1' : '0',
			} as EDocForeignRequestConfirmIssueItem,
		],
	};

	const result = {
		edocTitle: formData?.data?.edocNm,
		edocSenderId: currentSender.memberAccountId,
		edocSenderNm: currentSender.userNm,
		edocSenderPhoneNo: '',
		centerId: currentSender.centerId,
		paperTypeCd: formData?.data?.paperTypeCd,
		edocParamValue: JSON.stringify(eFormData),
		signatoryIds: [currentEmployee.employeeId],
		signatoryNms: [currentEmployee.korMemberNm],
		signatoryPhoneNos: [currentEmployee?.mobilePhoneNo || ''],
		signatoryBirthDts: [currentEmployee?.birthDt || ''],
	} as IssueEDocumentRequest;

	return result;
};

// 건강보험취득취소 확인서
export const eDocHealthInsuranceAcqCancelIssueFormAdapter = (
	formData: HealthInsuranceAcqCancelFormTypes,
	currentEmployee: EmployeeBaseInfoDTO,
	currentSender: MyAccountInfoDTO,
	rsdnNo: string,
) => {
	if (!currentSender || !currentEmployee) {
		return undefined;
	}

	const insCancelText =
		formData.insCancelCd?.length > 0
			? formData.insCancelCd?.[0].value === InsCancelCds.근무이력없음
				? '위 사업장에서 근무한 이력이 없어 사업장에서 건강보험 직장 가입자 취득 취소'
				: '위 사업장에서 월 60시간 미만 근무하여 사업장에서 건강보험 직장 가입자 취득 취소'
			: '';

	const eFormData = {
		crfeNm: formData.data?.eformFileNm,
		params: [
			{
				centerNm: formData.data?.data?.center?.centerNm || '',
				centerPhoneNo: formData.data?.data?.center?.centerReprPhoneNo || '',
				bizNo: formData.data?.data.center?.bizNo
					? displayBizNo(formData.data.data.center.bizNo)
					: '',
				healthInsuranceAcqDate: formData.healthInsuranceAcqDate
					? dayjs(formData.healthInsuranceAcqDate).format('YYYY.MM.DD')
					: '',
				year: dayjs(formData.requestDate).format('YYYY'),
				month: dayjs(formData.requestDate).format('MM'),
				day: dayjs(formData.requestDate).format('DD'),
				korNm: currentEmployee.korMemberNm,
				signKorNm: '',
				rsdnNo: rsdnNo ? displayRdsNumber(rsdnNo) : '',
				phoneNo: currentEmployee.mobilePhoneNo
					? displayPhoneNumber(currentEmployee.mobilePhoneNo)
					: '',
				insCancelCd: formData.insCancelCd[0].value,
				insCancelText,
			} as EDocHealthInsuranceAcqCancelIssueItem,
		],
	};

	const result = {
		edocTitle: formData?.data?.edocNm,
		edocSenderId: currentSender.memberAccountId,
		edocSenderNm: currentSender.userNm,
		edocSenderPhoneNo: '',
		centerId: currentSender.centerId,
		paperTypeCd: formData?.data?.paperTypeCd,
		edocParamValue: JSON.stringify(eFormData),
		signatoryIds: [currentEmployee.employeeId],
		signatoryNms: [currentEmployee.korMemberNm],
		signatoryPhoneNos: [currentEmployee?.mobilePhoneNo || ''],
		signatoryBirthDts: [currentEmployee?.birthDt || ''],
	} as IssueEDocumentRequest;

	return result;
};

// 건강보험상실취소 확인서
export const eDocHealthInsuranceLossCancelIssueFormAdapter = (
	formData: HealthInsuranceLossCancelFormTypes,
	currentEmployee: EmployeeBaseInfoDTO,
	currentSender: MyAccountInfoDTO,
	rsdnNo: string,
) => {
	if (!currentSender || !currentEmployee) {
		return undefined;
	}

	const eFormData = {
		crfeNm: formData.data?.eformFileNm,
		params: [
			{
				centerNm: formData.data?.data?.center?.centerNm || '',
				centerPhoneNo: formData.data?.data?.center?.centerReprPhoneNo || '',
				bizNo: formData.data?.data.center?.bizNo
					? displayBizNo(formData.data.data.center.bizNo)
					: '',
				healthInsuranceLossDate: formData.healthInsuranceLossDate
					? dayjs(formData.healthInsuranceLossDate).format('YYYY.MM.DD')
					: '',
				year: dayjs(formData.requestDate).format('YYYY'),
				month: dayjs(formData.requestDate).format('MM'),
				day: dayjs(formData.requestDate).format('DD'),
				korNm: currentEmployee.korMemberNm,
				signKorNm: '',
				rsdnNo: rsdnNo ? displayRdsNumber(rsdnNo) : '',
				phoneNo: currentEmployee.mobilePhoneNo
					? displayPhoneNumber(currentEmployee.mobilePhoneNo)
					: '',
			} as EDocHealthInsuranceLossCancelIssueItem,
		],
	};

	const result = {
		edocTitle: formData?.data?.edocNm,
		edocSenderId: currentSender.memberAccountId,
		edocSenderNm: currentSender.userNm,
		edocSenderPhoneNo: '',
		centerId: currentSender.centerId,
		paperTypeCd: formData?.data?.paperTypeCd,
		edocParamValue: JSON.stringify(eFormData),
		signatoryIds: [currentEmployee.employeeId],
		signatoryNms: [currentEmployee.korMemberNm],
		signatoryPhoneNos: [currentEmployee?.mobilePhoneNo || ''],
		signatoryBirthDts: [currentEmployee?.birthDt || ''],
	} as IssueEDocumentRequest;

	return result;
};

// 단말기 미사용 신청서
export const eDocDeviceUnusedApplicationIssueFormAdapter = (
	formData: DeviceUnusedApplicationFormTypes,
	currentEmployee: EmployeeDTO,
	currentSender?: MyAccountInfoDTO,
) => {
	if (!formData.data?.data.employee || !currentSender || !currentEmployee) {
		return undefined;
	}

	const { employee } = (formData.data as unknown as EDocPledgeDTO).data;

	const eFormData = {
		crfeNm: formData.data?.eformFileNm,
		params: [
			{
				centerNm: formData.data?.data.center?.centerNm || '',
				centerMark: formData.data?.data.center?.centerMark || '',
				employeeNm: currentEmployee.korMemberNm || '',
				signEmployeeNm: employee.korMemberNm || '',
				birthDt: displayBirthDay(currentEmployee.birthDt),
				requestPhoneInfo:
					`전: ${displayPhoneNumber(formData.beforePhoneNo)}\n후: ${displayPhoneNumber(formData.afterPhoneNo)}` ||
					'',
				phoneNo: displayPhoneNumber(currentEmployee.mobilePhoneNo) || '',
				requestReason: formData.requestReason || '',
				requestDt: dayjs(formData.requestDate).format('YYYY.MM.DD') || '',
			} as EDocDeviceUnusedApplicationIssueItem,
		],
	};

	const result = {
		edocTitle: formData?.data?.edocNm,
		edocSenderId: currentSender.memberAccountId,
		edocSenderNm: currentSender.userNm,
		edocSenderPhoneNo: '',
		centerId: currentSender.centerId,
		paperTypeCd: formData?.data?.paperTypeCd,
		edocParamValue: JSON.stringify(eFormData),
		signatoryIds: [currentEmployee.employeeId],
		signatoryNms: [currentEmployee.korMemberNm],
		signatoryPhoneNos: [currentEmployee?.mobilePhoneNo || ''],
		signatoryBirthDts: [currentEmployee?.birthDt || ''],
	} as IssueEDocumentRequest;

	return result;
};

export const eDocOtherBankBookIssueFormAdapter = (
	formData: OtherBankBookFormTypes,
	currentEmployee: EmployeeDTO,
	currentSender?: MyAccountInfoDTO,
) => {
	if (!formData.data?.data.employee || !currentSender || !currentEmployee) {
		return undefined;
	}

	const { employee } = (formData.data as unknown as EDocPledgeDTO).data;

	const eFormData = {
		crfeNm: formData.data?.eformFileNm,
		params: [
			{
				nm: employee.korMemberNm,
				signNm: '',
				birthDt: displayBirthDay(employee.birthDt),
				workStartDate: dayjs(employee.accidentInsuranceAcqDate).format('YYYY.MM.DD'),
				bankNm: formData.bank.label,
				accountNumber: formData.accountNumber,
				accountNm: formData.name,
				accountBirth: displayBirthDay(formData.birthDt),
				accountPhone: displayPhoneNumber(formData.phoneNumber),
				relationshipType: formData.relationEtc || formData.relation.label,
				reason: formData.reason,
				year: String(dayjs().get('year')),
				month: String(dayjs().get('month') + 1),
				day: String(dayjs().get('date')),
			} as EDocOtherBankBookIssueItem,
		],
	};

	const result = {
		edocTitle: formData.data.edocNm,
		edocSenderId: currentSender.memberAccountId,
		edocSenderNm: currentSender.userNm,
		edocSenderPhoneNo: '',
		centerId: currentSender.centerId,
		paperTypeCd: formData.data.paperTypeCd,
		edocParamValue: JSON.stringify(eFormData),
		signatoryIds: [employee.employeeId, currentSender.memberAccountId],
		signatoryNms: [employee.korMemberNm || '', currentSender.userNm || ''],
		signatoryPhoneNos: [employee.mobilePhoneNo || '', ''],
		signatoryBirthDts: [employee.birthDt || '', ''],
		receiverTargetDivCd: 'CMN118.30',
	} as IssueEDocumentRequest;

	return result;
};

export const eDocResignIssueFormAdapter = (
	formData: ResignFormTypes,
	currentEmployee: EmployeeDTO,
	currentSender?: MyAccountInfoDTO,
) => {
	if (!formData.data?.data.employee || !currentSender || !currentEmployee) {
		return undefined;
	}

	const { employee } = (formData.data as unknown as EDocResignDTO).data;

	const eFormData = {
		crfeNm: formData.data?.eformFileNm,
		params: [
			{
				nm: employee.korMemberNm,
				signNm: '',
				centerNm: employee.centerNm,
				birthDt: displayBirthDay(employee.birthDt),
				workStartDate: dayjs(formData.workStartDate).format('YYYY.MM.DD'),
				leaveHopeYear: dayjs(formData.resignDate).format('YYYY'),
				leaveHopeMonth: dayjs(formData.resignDate).format('M'),
				leaveHopeDay: dayjs(formData.resignDate).format('D'),
				assignTaskNm: currentEmployee.dutyNms,
				leaveChk1: formData.resignType.value === 'CMN109.51' ? '1' : '',
				leaveChk2: formData.resignType.value === 'CMN109.52' ? '1' : '',
				leaveComCdId: formData.resignType.value,
				leaveDetailComCdId: formData.resignDetailType.value,
				year: String(dayjs().get('year')),
				month: String(dayjs().get('month') + 1),
				day: String(dayjs().get('date')),
				leaveYear: dayjs(formData.resignDate).format('YYYY'),
				leaveMonth: dayjs(formData.resignDate).format('M'),
				leaveDay: dayjs(formData.resignDate).format('D'),
				leaveReason: formData.reason || '',
			} as EDocResignIssueItem,
		],
	};

	const result = {
		edocTitle: formData.data.edocNm,
		edocSenderId: currentSender.memberAccountId,
		edocSenderNm: currentSender.userNm,
		edocSenderPhoneNo: '',
		centerId: currentSender.centerId,
		paperTypeCd: formData.data.paperTypeCd,
		edocParamValue: JSON.stringify(eFormData),
		signatoryIds: [employee.employeeId, currentSender.memberAccountId],
		signatoryNms: [employee.korMemberNm || '', currentSender.userNm || ''],
		signatoryPhoneNos: [employee.mobilePhoneNo || '', ''],
		signatoryBirthDts: [employee.birthDt || '', ''],
		receiverTargetDivCd: 'CMN118.30',
	} as IssueEDocumentRequest;

	return result;
};
