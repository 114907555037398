import React from 'react';

import CRButton from 'components/base/CRButton';
import DementiaEducationConfirmationDialog from 'components/domain/dialog/DementiaEducationConfirmationDialog';
import FullTimeEmployeeStatutoryDetailDialog from 'components/domain/dialog/FullTimeEmployeeStatutoryDetailDialog';
import useFullTimeEmployeePage from 'lib/hook/full-time-employee/useFullTimeEmployeePage';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { FullTimeEmployeeDetailInfoDTO } from 'types/dto/full-time-employee';

import Colors from '../../../../common/colors';
import * as S from './styles';

interface Props {
	item?: FullTimeEmployeeDetailInfoDTO | null;
}

export default function FullTimeTrainingFormView({ item }: Props): React.ReactElement {
	const hasUpdateDementiaEducation = useHasFunc(['full_time_employee:update_dementia_education']);
	const { showDialog } = useGlobalLayout();
	const { currentEmployee, employeeDetailData } = useFullTimeEmployeePage();
	const isResign = employeeDetailData?.workStateCd === 'CMN068.30';

	const handleClickStatutoryDetail = () => {
		if (!currentEmployee) return;

		showDialog(({ hideDialog }) => (
			<FullTimeEmployeeStatutoryDetailDialog
				fullTimeEmployee={currentEmployee}
				hideDialog={hideDialog}
			/>
		));
	};

	const handleClickDementiaEducationDetail = () => {
		if (!currentEmployee) return;

		showDialog(({ hideDialog }) => (
			<DementiaEducationConfirmationDialog
				fullTimeEmployee={currentEmployee}
				hideDialog={hideDialog}
			/>
		));
	};

	const displayTextColor = (stateNm: string) => {
		if (stateNm === '미수료') return Colors.red;
		if (isResign) return Colors.gray60;
		return Colors.gray10;
	};

	const displayDescriptionTextColor = (stateNm: string) => {
		if (stateNm === '미수료') return Colors.red;
		return Colors.gray60;
	};

	return (
		<S.Container>
			<S.Label>교육 이수 정보</S.Label>
			<S.TableContainer>
				<S.Table $isDisabled={isResign}>
					<S.TableRow>
						<S.TableLabelColumn>법정 의무 교육</S.TableLabelColumn>
						<S.TableValueColumn
							style={{
								color: displayTextColor(item?.legalEduCompleteStateNm || ''),
							}}>
							{item?.legalEduCompleteStateNm || ''}
							<span
								style={{
									color: displayDescriptionTextColor(item?.legalEduCompleteStateNm || ''),
								}}>
								{item?.legalEduCompleteCntNm}
							</span>
							<S.RightButtonContainer>
								{!isResign && (
									<CRButton.Default
										size='xSmall'
										palette='gray'
										type='outlined'
										onClick={handleClickStatutoryDetail}>
										이수 확인
									</CRButton.Default>
								)}
							</S.RightButtonContainer>
						</S.TableValueColumn>
						<S.TableLabelColumn>치매 교육</S.TableLabelColumn>
						<S.TableValueColumn
							style={{
								color: displayTextColor(item?.dementiaEduCompleteStateNm || ''),
							}}>
							{item?.dementiaEduCompleteStateNm || '-'}
							<span
								style={{
									color: displayDescriptionTextColor(item?.dementiaEduCompleteStateNm || ''),
								}}>
								{item?.dementiaEduCompleteCurriculumNm}
							</span>
							<S.RightButtonContainer>
								{!isResign && (
									<CRButton.Default
										disabled={!hasUpdateDementiaEducation}
										size='xSmall'
										palette='gray'
										type='outlined'
										onClick={handleClickDementiaEducationDetail}>
										이수 확인
									</CRButton.Default>
								)}
							</S.RightButtonContainer>
						</S.TableValueColumn>
					</S.TableRow>
				</S.Table>
			</S.TableContainer>
		</S.Container>
	);
}
