import React, { useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import dayjs from 'dayjs';

import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes, useMyAccountInfo } from 'lib/hook/react-query';
import { useEDocIssueInfoBefore } from 'lib/hook/react-query/query/edoc';
import { CMSAutoTransferFormTypes, EDocIssueFormTypes } from 'types/view/eDoc';
import { Recipient, RecipientOwnExpenseFormViewType } from 'types/view/recipient';

import * as S from './styles';

interface Props {
	templateCode: string;
	edocNo?: string;
	formContext: UseFormReturn<CMSAutoTransferFormTypes, any, undefined>;
	confirmMode?: boolean;
	defaultContext?: UseFormReturn<EDocIssueFormTypes, any, undefined>;
	currentRecipient?: Recipient;
	currentRecipientOwnExpense?: RecipientOwnExpenseFormViewType;
}

export default function EDocCMSAutoTrasnferForm({
	templateCode,
	edocNo,
	formContext,
	confirmMode = false,
	defaultContext,
	currentRecipient,
	currentRecipientOwnExpense,
}: Props): React.ReactElement {
	const { data: myAccountInfo } = useMyAccountInfo();

	const { data: cmsAutoTransferForm } = useEDocIssueInfoBefore({
		recipientId: currentRecipient?.recipientId,
		centerId: Number(myAccountInfo?.centerId),
		templateCode,
	});

	const { data: commonCodes } = useCommonCodes(
		{
			comCdGroupNms: ['CMN037'],
		},
		commonCodeAdapter,
	);

	const bankOptions = commonCodes?.CMN037 || [];

	useEffect(() => {
		if (cmsAutoTransferForm) {
			formContext.setValue('data', cmsAutoTransferForm);
		}
	}, [cmsAutoTransferForm]);

	useEffect(() => {
		if (currentRecipientOwnExpense?.payMethod?.value === 'CMN041.10') {
			formContext.setValue('applicantName', currentRecipientOwnExpense?.accountOwner);
			formContext.setValue('accountName', currentRecipientOwnExpense?.accountOwner);
			formContext.setValue('account', currentRecipientOwnExpense?.account);
			formContext.setValue('bank', {
				value: currentRecipientOwnExpense?.bank,
				label: bankOptions.find((item) => item.value === currentRecipientOwnExpense?.bank)?.label,
			});
		}
		formContext.setValue('applicantDate', dayjs().format('YYYY-MM-DD'));
	}, [currentRecipientOwnExpense?.payMethod, bankOptions]);

	return (
		<S.Container>
			<S.SubFormContainer>
				<S.SubFormContainer>
					<S.SubFormHeader>
						<S.SubFormTitle>신청 정보</S.SubFormTitle>
					</S.SubFormHeader>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInputLabel label='신청인' isRequired>
								<CRInput.Default
									disabled={confirmMode}
									placeholder='신청인 입력'
									onChange={onChange}
									value={value}
								/>
							</CRInputLabel>
						)}
						name='applicantName'
						control={formContext.control}
					/>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInputLabel label='신청인 연락처' isRequired>
								<CRInput.Default
									onChange={onChange}
									value={value}
									placeholder='연락처 입력'
									type='number'
									maxLength={11}
								/>
							</CRInputLabel>
						)}
						name='applicantPhoneNo'
						control={formContext.control}
					/>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInputLabel label='신청일자' isRequired>
								<S.DateContainer>
									<CRInput.DatePicker
										value={value ? dayjs(value).toDate() : undefined}
										onChangeValue={onChange}
										placeholder='신청일자 선택'
									/>
								</S.DateContainer>
							</CRInputLabel>
						)}
						name='applicantDate'
						control={formContext.control}
					/>
				</S.SubFormContainer>
				<S.SubFormContainer>
					<S.SubFormHeader>
						<S.SubFormTitle>예금주 정보</S.SubFormTitle>
					</S.SubFormHeader>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInputLabel label='예금주' isRequired>
								<CRInput.Default
									disabled={confirmMode}
									placeholder='예금주 입력'
									onChange={onChange}
									value={value}
								/>
							</CRInputLabel>
						)}
						name='accountName'
						control={formContext.control}
					/>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInputLabel label='예금주 생년월일' isRequired>
								<CRInput.Default
									onChange={onChange}
									value={value}
									placeholder='예시) 19280301'
									type='number'
									maxLength={8}
								/>
							</CRInputLabel>
						)}
						name='accountOwnerBirthday'
						control={formContext.control}
					/>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInputLabel label='예금주 연락처' isRequired>
								<CRInput.Default
									onChange={onChange}
									value={value}
									placeholder='연락처 입력'
									type='number'
									maxLength={11}
								/>
							</CRInputLabel>
						)}
						name='accountOwnerPhoneNo'
						control={formContext.control}
					/>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInputLabel label='은행명' isRequired>
								<CRInput.Selector
									disabled={confirmMode}
									placeholder='은행명 선택'
									currentValue={value}
									items={bankOptions}
									onChangeValue={onChange}
								/>
							</CRInputLabel>
						)}
						name='bank'
						control={formContext.control}
					/>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInputLabel label='계좌번호' isRequired>
								<CRInput.Default
									disabled={confirmMode}
									placeholder='계좌번호 입력'
									type='number'
									onChange={onChange}
									value={value}
								/>
							</CRInputLabel>
						)}
						name='account'
						control={formContext.control}
					/>
				</S.SubFormContainer>
			</S.SubFormContainer>
		</S.Container>
	);
}
