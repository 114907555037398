import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from 'styled-components';

import GlobalStyle from 'common/globalStyles';
import Theme from 'common/theme';
import { StyledToastContainer } from 'components/base/CRToast';
import ErrorBoundary from 'components/ui/ErrorBoundary';
import AuthProvider from 'lib/provider/auth';
import ApiProvider from 'lib/provider/service/Api';
import DialogProvider from 'lib/provider/ui/Dialog';
import PermissionService from 'lib/service/Permission';
import { loadApplication } from 'lib/service/util/loader';

import RootRouter from './router';

const { api, queryClient } = loadApplication();

const autoSignIn = localStorage.getItem('autoSignIn');
const user = autoSignIn
	? !!localStorage.getItem('access_token')
	: !!sessionStorage.getItem('access_token');

function App(): React.ReactElement {
	const [isFuncLoading, setIsFuncLoading] = useState(true);
	useEffect(() => {
		if (user) {
			PermissionService.shared.init(api).then(() => {
				setIsFuncLoading(false);
			});
		} else {
			setIsFuncLoading(false);
		}
	}, [user]);

	if (isFuncLoading) return <div />;

	return (
		<ThemeProvider theme={Theme}>
			<StyledToastContainer />
			<ErrorBoundary>
				<ApiProvider api={api}>
					<QueryClientProvider client={queryClient}>
						<AuthProvider user={user}>
							<ReactQueryDevtools initialIsOpen={false} />
							<DialogProvider>
								<RootRouter />
								<GlobalStyle />
							</DialogProvider>
						</AuthProvider>
					</QueryClientProvider>
				</ApiProvider>
			</ErrorBoundary>
		</ThemeProvider>
	);
}

export default App;
