import styled, { css } from 'styled-components';

export const ToggleBtn = styled.button<{ $isActive: boolean; $isDisabled: boolean }>`
	width: 5.2rem;
	height: 3.2rem;
	border-radius: 1.6rem;
	border: 0.2rem solid ${(props) => props.theme.colors.gray60};
	cursor: ${(props) => (props.$isDisabled ? 'not-allowed' : 'pointer')};
	background-color: ${(props) =>
		!props.$isActive ? props.theme.colors.gray100 : props.theme.colors.gray100};
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.1s ease-in-out;

	${(props) =>
		props.$isActive &&
		css`
			background: ${(props) => props.theme.colors.primary60};
			border-color: transparent;
		`};

	@media (hover: hover) and (pointer: fine) {
		&:hover > div {
			${(props) =>
				!props.$isDisabled &&
				css`
					scale: 1;
					background: ${props.$isActive ? props.theme.colors.gray100 : props.theme.colors.gray10};
					transition: all 0.1s ease-in-out;
					transition-property: left scale;
				`};
		}
	}

	${(props) =>
		props.$isDisabled &&
		css`
			background: ${props.theme.colors.gray90};
			border: 0.2rem solid transparent;
		`};
`;

export const Circle = styled.div<{ $isActive: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(props) => props.theme.colors.gray60};
	width: 2.8rem;
	height: 2.8rem;
	scale: 0.57;
	border-radius: 50%;
	position: absolute;
	left: 0;
	transition: all 0.1s ease-in-out;
	transition-property: left scale;

	transform-origin: center;

	${(props) =>
		props.$isActive &&
		css`
			scale: 0.86;
			left: 2rem;
			transition: left 0.1s ease-in-out;
			background: ${(props) => props.theme.colors.gray100};
		`}
`;

export const Icon = styled.img<{ $isDisabled: boolean }>`
	width: 1.6rem;
	height: 1.6rem;
	filter: ${(props) =>
		props.$isDisabled
			? 'brightness(0) saturate(100%) invert(59%) sepia(0%) saturate(611%) hue-rotate(279deg) brightness(97%) contrast(97%)'
			: `brightness(0) saturate(100%) invert(48%) sepia(77%) saturate(2971%) hue-rotate(322deg)
		brightness(101%) contrast(101%)`};
`;
